@import '../../../theme/colors';

.experienceBox {
  position: relative;

  &-icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 65px;
    height: 65px;
    background: $gray6 no-repeat center center;
    border-radius: 3px;
  }

  &-info {
    padding-left: 95px;
  }

  &-title {
    font-size: 24px;
    margin-bottom: 7px;
  }

  &-smallInfo {
    margin-bottom: 5px;
    color: $gray1;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &-description {
    margin-top: 18px;
    line-height: 1.44;
    text-align: left;
    
  }

  &.--education {
    .experienceBox {
      &-icon {
        background-image: url('./../../../assets/svg/connections/education-icon.svg');
      }
    }
  }
}

.defaultCompanyIcon{
  background-image: url('./../../../assets/images/defaultCompany.png');
  background-size: cover;
  margin-top: 4px;
}
