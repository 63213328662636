.entities__container {
  .entity-card {
    height: auto;
  }
  .entity {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    cursor: pointer;
    width: 100%;
  }
  .entity-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
  }
  .entity__tags {
    span {
      @include text-typography($font-family-cera-regular, 14px);
      color: $color-grey;
    }
  }
  .entity__title {
    span {
      @include text-typography($font-family-cera-regular, 16px);
      color: $color-grey-white;
      margin-bottom: 3px;
    }
  }
  .entity__image-container {
    max-width: 60px;
    width: 60px;
    max-height: 60px;
    height: 60px;
    overflow: hidden;
    object-fit: contain;
    margin-left: 10px;
  }
  .entity__image {
    width: 60px;
    height: 60px;
    border-radius: $dashboard-border-size;
  }
  .entity:hover,
  .entity:active {
    background-color: $color-grey-medium;
    position: relative;
    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 100%;
    }
    &:before {
      background-color: $color-grey-medium;
      left: -20px;
      border-bottom-left-radius: $dashboard-card-border-radius;
      border-top-left-radius: $dashboard-card-border-radius;
    }
    &:after {
      background-color: $color-grey-medium;
      left: 100%;
      border-bottom-right-radius: $dashboard-card-border-radius;
      border-top-right-radius: $dashboard-card-border-radius;
    }
    .entity__image {
      transform: scale(1.08);
      transition: 0.4s ease;
    }
    .entity__author {
      color: $color-white;
    }
  }
}