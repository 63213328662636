.base-modal {
  display: block;
  position: fixed;
  z-index: 1001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(19, 30, 41, 0.9);
  padding: 0 10px;
  overflow-y: auto;

  .base-modal__modal-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .base-modal__modal-content {
    opacity: 1;
    display: flex;
    background-color: $color-white;
    margin: 5% auto;
    max-width: 1060px;
    width: 100%;
    min-height: 280px;
    border-radius: 5px 3px 3px 5px;
  }

  .base-modal__left-area {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    width: 55px;
    background-color: $color-grey-medium;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .base-modal__icon {
    position: absolute;
    width: 22px;
    height: auto;
    bottom: 15px;
    left: 15px;
  }

  .base-modal__right-area {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .base-modal__modal-header {
    position: relative;
    display: block;
  }

  .base-modal__modal-title {
    @include text-typography($font-family-cera-regular, 24px);
    color: $color-grey;
    margin: 0;
    transform: translateY(-7px);
  }

  .base-modal__close {
    position: absolute;
    top: 25px;
    right: 30px;
    color: $color-yellow;
    font-size: 28px;
    cursor: pointer;
    z-index: 1;
    //@include unselectable-element;
  }

  .base-modal__text-body {
    @include text-typography($font-family-cera-regular, 18px);
    line-height: 1.4;
    color: $color-black;
  }

  .form-error {
    border:1px solid $color-red;
  }

  .form-error-message {
    font-family: $font-family-cera-regular;
    font-size: 14px;
    line-height: normal;
    letter-spacing: normal;
    color: $color-red-light;
    position: absolute;
    bottom: 10px;
  }

  //Pentru ca imbricate, Andra B.
  .community-logo__field .form-error-message {
    bottom: -20px;
  }

  .community-form__audience .form-error-message {
    bottom: unset;
    position: unset;
  }

  .base-modal__modal-footer {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 25px 30px;
  }
  .base-modal__modal-footer-description-container {
    flex: 1;
  }

  .base-modal__modal-footer-title {
    @include text-typography($font-family-cera-regular, 18px);
    color: $color-black;
    margin-bottom: 10px;
  }

  .base-modal__modal-footer-description {
    @include text-typography($font-family-cera-regular, 16px);
    opacity: 0.9;
    line-height: 1.38;
    color: $color-black;
  }
}
