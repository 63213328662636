.news__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .news__cards-container {
        display: flex;
        flex-direction: column;
        .card__container {
            flex-direction: row;
        }
    }
    .open-login__footer {
        transform: translateX(0);
    }
}

.news-card {
    margin-bottom: $dashboard-spacing;
    justify-content: space-between;
    flex: 2 1;
    flex-direction: row;
    cursor: pointer;

    &:last-child {
        margin-bottom: 0px;
    }
}

.news-card__header-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .news-card__header {
        margin: -3px 0 5px;
        color: $color-grey;
        line-height: 0.71;
    }

    .news-card__type {
        @include text-typography($font-family-cera-medium);
        color: $color-yellow;
        text-transform: uppercase;
        white-space: nowrap;
    }

    .news-card__date {
        @include text-typography($font-family-cera-regular, 14px);
        color: $color-grey;
    }

    .news-card__tags {
        @include text-typography($font-family-cera-regular, 14px);
        color: $color-grey;
        flex:1;
    }
    .dot_between {
        @include dot-between();
    }

    &.twitter-card {
        flex-direction: column;
        align-items: baseline;
        margin-bottom: 17px;
    }
}

.news-card__title {
    width: 100%;
    flex: 1;

    span {
        @include text-typography($font-family-cera-medium, 21px);
        color: $color-grey-white;
        line-height: 1.52;

        a {
            @include text-typography($font-family-cera-medium, 21px);
            color: $color-yellow;
        }
    }
}

.news-card__content {
    width: 100%;
    flex: 1;
    span {
        @include text-typography($font-family-cera-regular, 16px);
        color: $color-grey;
        line-height: 1.38;

        a {
            @include text-typography($font-family-cera-regular, 16px);
            color: $color-yellow;
        }
    }
}

.news-card-right-section {
    width: 180px;
    min-width: 180px;
    overflow: hidden;
    object-fit: cover;
    height: 107px;
    border-radius: 6px;

    .news-card__image {
        max-width: 180px;
    }

    .news-card__youtube-iframe {
        width: 280px;
        height: 200px;
        margin-top: -50px;
        margin-left: -50px;
        pointer-events: none;
    }
}

.news-card-left-section {
    max-width: 887px;
    position: relative;
    min-width: 400px;
    width: 100%;
    &.news-card-left-section-full {
        max-width: 100%;
    }
}
.news-twitter-card {
    .news-card-left-section {
        max-width: 100%;
    }
}

.card-footer__container {
    @include text-typography($font-family-cera-regular, 14px);
    color: $color-grey;

    .dot-between__footer {
        @include dot-between();
    }
}
.news-card__video-non-clickable {

}