.contact-info {
  padding-bottom: 40px;
  
  &-title {
    @extend %typography-fieldTitle;
    text-transform: uppercase;
    text-decoration: none;
    margin-top: 30px;
    margin-bottom: 35px;
    position: relative;
  }

  &-map-container {
    height: 400px;
  }

  &-fields {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
  }

  &.contact-info-booked {
    position: relative;

    .contact-info-title {
      display: none;
    }

    .contact-info-fields-title {
      font-size: 36px;
    }

    .contact-info-emailOffice {
      display: none;
    }

    .contact-info-fields {
      display: block;
      position: absolute;
      top: 10px;
      right: 10px;
      background: rgba(19, 30, 41, 0.8);
      width: calc(33.5% - 48px);
      height: calc(100% - 64px);
      padding: 24px;
      margin: 0;
    }
  }

  &-label {
    @extend %typography-fieldTitle;

    text-transform: uppercase;
    text-decoration: none;
    margin-top: 24px;
  }

  &-value {
    font-size: 24px;
    margin-top: 10px;
    display: inline-block;
  }
}
