@import '../../../theme/common/variables';

.heroes-booked-content{
  display: flex;
  flex: 1 1;
  justify-content: center;
  flex-direction: column;
}

.heroes-booked-content__no-records{
  text-align: center;
  padding: 20px;
}

.heroes-grid__row{
  display: flex;
  flex-direction: row;
  flex: 1 1;
}

.heroes-card-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
}

.heroes-filters-selector__container{
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 247px;
  border-radius: 3px;
  padding: 16px 15px 15px 10px;
}

.heroes-filters-selector__container
.heroes-filters-selector__picker-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: $color-black;
  width: 247px;
  position: relative;

  .dropdown__selector.filter__dropdown .dropdown-input{
    width: 245px;
    height: 40px;
    border-radius: 3px;
    border: solid 2px $color-grey-medium-alt;
  }

  .filter__dropdown .dropdown-input span{
    //margin: 0 111px 0 0;
    font-family: $font-family-cera-regular;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.64;
    letter-spacing: normal;
    color: $color-grey-white;
  }
}

.hero-booking-list-loader{
  text-align: center;
  height: 200px;
  background-image: url('../../../assets/images/loader.gif');
  background-position: center center;
  background-repeat: no-repeat;
}

.heroes-card-title{
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: $font-family-cera-regular;
  font-size: 16px;
  line-height: 0.63;
  color: $color-grey-light;
}

.heroes-active-row-wrapper{
  width: 100%;
  margin-bottom: 10px;
}

.heroes-active-row{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  cursor: pointer;

  .heroes-button-wrapper .heroes-details-button{
    display: flex;
    opacity: 0;
  }

  &:hover{
    background-color: $color-grey-medium;
    color: $color-white;

    .heroes-details-wrapper .heroes-details-floor{
      color: $color-white;
    }

    .heroes-details-wrapper .heroes-details-date{
      color: $color-white;

      span{
        color: $color-white;
      }
    }

    .heroes-details-wrapper .heroes-details-type{
      color: $color-white;
    }

    .heroes-button-wrapper .heroes-details-button{
      opacity: 1;
    }
  }


}

.heroes-booked-avatar-mask {
  width: 48px;
  height: 48px;
  margin: 0px 15px 0px 0px;
  padding: 14px 13px 18px 14px;
  border-radius: 4px;
  background-color: $color-grey-dark;

  span{
    font-family: $font-family-cera-regular;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: $color-grey;
  }
}

.heroes-details-wrapper{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 20px;
  width: 340px;
  color: $color-grey;
}

.heroes-details-name{
  margin: 8px 10px 3px 0px;
  font-family: $font-family-cera-regular;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.67;
  letter-spacing: normal;
  color: $color-white;

  img{
    width: 18px;
    height: 18px;
    position:relative;
    top: 4px;
    margin: 0 2px;
  }
}

.hero-details-skills {
  margin-left: 5px;
  display: inline-block;
  margin-top: 5px;
}



.heroes-details-floor{
  margin: 10px 105px 7px 0px;
  font-family: $font-family-cera-regular;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.67;
  letter-spacing: normal;
  color: $color-grey;
}

.heroes-details-date{
  font-family: $font-family-cera-regular;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.0;
  letter-spacing: normal;
  color: $color-grey;
  text-transform: capitalize;

  span{
    display: inline-block;
    color: $color-grey;
  }
}

.heroes-details-type {
  margin: 10px 105px 7px 20px;
  font-family: $font-family-cera-regular;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.67;
  letter-spacing: normal;
  color: $color-grey;
}
.heroes-details-working-days span {
  font-family: $font-family-cera-regular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.67;
  letter-spacing: normal;
  color: $color-grey;
}

.heroes-button-wrapper{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 20px;
  align-items: flex-end;
}

.heroes-details-button{
  display: none;

  .heroes-details-button-sign{
    width: 15px;
    height: 18px;
    margin-right: 65px;
    object-fit: contain;

  }

  .heroes-details-button-name{
    margin: 20px 0 19px 20px;
    font-family: $font-family-cera-regular;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.64;
    letter-spacing: normal;
    text-align: center;
    color: $color-yellow;
    position:relative;
    bottom: 4px;
  }
}

@media screen and (max-width:1365px) {
  .heroes-details-date {
    line-height:1.2;
  }

  .heroes-details-type{
    line-height:1;
  }
}