@import '../../../theme/layout';


.rightSidebar-content {
  height: 100%;
}

.booking-sidebar {
  background-color: $yellow1;
  position: relative;
  height: 100%;
}

.booking-sidebar{
  .booking-options{
    &-details{
      padding-bottom: 80px;
      > div {
        margin-bottom: 30px;
      }
    }
    &-footer{
      margin-top: -80px!important;
    }
  }
}
