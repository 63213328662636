@import '../../../theme/colors';


.--show-connections-booking-modal{
    .modal-dialog-content {
         &:before {
        background: $gray6 url('../../../assets/svg/modalDialog/user.svg') no-repeat 50% calc(100% - 15px);
        }
    }
}

.showConnections {
  min-width: 400px;
  
  .modal-close-button{
    background: url('../../../assets/svg/accountProfile/close-icon.svg') no-repeat;
    height: 25px;
    width: 25px;
    float: right;
    margin-right: -10px;
    &:hover{
      cursor: pointer;
    }
  }
  &-description {
    color: $gray1;
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 0;
    margin-top: 30px;
    max-height: 540px;
    min-height: 180px;
    .connectionItem{
      color: $gray1;
      font-size: 14px;
      line-height: 1.4;
      margin: 10px 0;
      display: flex;
      line-height: 36px;

      .connection-image {
        background-position: center;
        background-image: url('./../../../assets/images/defaultAvatarimage.png');
        background-size: cover;
  
        border-radius: 50%;
        display: block;
        height: 36px;
        width: 36px;
        margin-right: 10px;
        margin-bottom: 9px;
        display: inline-block;
  
        &:not(:first-child){
          margin-left: -30px;
        }
      }
    }
  }

  .items-style1 {
    color: $gray1;
  }

  &-warning {
    color: $red2;
    font-size: 18px;
    margin-top: 44px;
    margin-bottom: 19px;
  }

  &-actions {
    text-align: right;
    padding-top: 30px;
    button{
      margin-top: 0px;
    }
  }

  &-blockButton, &-cancelButton {
    text-transform: uppercase;
    width: auto !important;
    display: inline-block !important;
    margin-left: 30px !important;
  }

  .main-button{
    color: $white1;
    font-family: 'CeraPRO-Medium';
  }

  &-blockButton {
    &.secondary-button {
      border: 0 none;
      padding: {
        left: 0;
        right: 0;
      }
      color: $gray1;

      &:focus{
        outline: none;
      }

      &:hover, &:active {
        color: $gray7;
      }
    }
  }
}

.connection__name {
  display: block;
  box-sizing: border-box;
  margin-top: -6px;
  font-size: 14px;
  color: $gray1;
}

.connection__label {
  display: block;
  margin-top: -23px;
  font-size: 11px;
  color: $gray10;
  &.connection__label--active {
    color: $yellow1;
  }
}
