$rowHeight: 186px;
$rowBottomMargin: 60px;
$detailsInfoWidth: 350px;
$margins: 30px;
$imageWidth: 268px;

.office-space {
  .officeTitleRow{
    width: 100%;
    display: block;
    position: relative;
    .office-space-name,
    .office-space-gallery-button{
      display: inline-flex;
      //width: 50%;
      button.main-button{
        margin-top: 0px;
      }
    }
  }
  &-title {
    @extend %officeDetails-titleBorder;
    &:before{
      margin-top: -2px;
    }
  }

  &-info {
    flex: 1 0 0;
    width: $detailsInfoWidth;
    margin-right: 0;
    padding-right: 30px;
  }

  &-name {
    @extend %typography-ceraGR;
    font-family: 'CeraPRO-Medium';
    color: $white1; 
    font-size: 36px;
    margin-bottom: 20px;
  }

  &-gallery {
      overflow: hidden;
      flex: 3 0 0;
      &-button {
        display: block;
        float: right;
        padding-bottom: 14px;
        & > .main-button{
            padding: 0 20px;
            line-height: 40px;
            height: 40px;
        }
      }
      &-clear {
        clear: both;
      }
      &-items {
        display: flex;
        flex-wrap: nowrap;

      }
  }

  &-galleryItem {
    min-width: $imageWidth;
    height: $rowHeight;
    //margin-left: $margins;
    margin-right: 28px;
    display: table-cell;
    position: relative;
    background-size: cover;
    border-radius: 3px;
    font-size: 24px;
    font-weight: normal;

    &:not([data-label='']):after {
      content: attr(data-label);
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: black;
      opacity: .5;
    }
  }

  &-options {
    //max-height: 166px;
    overflow: hidden;
    position: relative;
    max-height: 142px;
    .label{
      padding-right: 15px;

    }
    &.expanded {
      max-height: inherit;
      > .office-space-option.directions {
        .label {
          width: 100%;
          margin-bottom: 5px;


        }
        .value{
          display: block;
          position: relative;
          width: 100%;

          text-align: left;
        }
      }
    }
  }

  &-option {
    @extend %displayFlex-spaceBetween;
    margin-bottom: 5px;

    & > .label {
      @extend %typography-ceraGR;
      //font-size: 14px;
      //color: $gray4;
      //line-height: 20px;
      font-family: 'CeraPRO-Medium';

      font-size: 14px;

      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #7c878e;
      margin-bottom: 2px;

    }

    & > .value {
      font-size: 14px;
      line-height: 20px;
      flex:1;
      text-align: right;

    }

    .available,
    .unavailable {
      display: inline-block;
      width: 14px;
      height: 14px;
      background-repeat: no-repeat;
      background-position: center;
    }

    .available {
      background-image: url(../../../assets/svg/officeDetails/feature-available.svg)
    }

    .unavailable {
      background-image: url(../../../assets/svg/officeDetails/feature-unavailable.svg)
    }
  }

  &-type-desk,
  &-type-meeting,
  &-type-private,
  &-type-event {
    @extend %displayFlex-wrap;

    padding-left: 85px;
    background-repeat: no-repeat;
    //background-position-x: $margins;
    min-height: $rowHeight;
    margin-bottom: 60px;
  }

  &-type-desk {
    background-image: url('../../../assets/svg/officeDetails/desk-office-icon.svg');
  }
  &-type-meeting {
    background-image: url('../../../assets/svg/officeDetails/meeting-room-icon.svg');
  }
  &-type-event {
    background-image: url('../../../assets/svg/officeDetails/event-room-icon.svg');
  }
  &-type-private {
    background-image: url('../../../assets/svg/officeDetails/private-office-icon.svg');
  }

  &-expanded,
  &-collapsed {
    @extend %typography-fieldTitle;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    margin-top: 15px;
    cursor: pointer;
    color: $yellow1;
    text-transform: uppercase;
    text-decoration: none;
    user-select: none;
    position: absolute;

    &:after {
      content: '';
      position: absolute;
      width: 14px;
      height: 14px;
      right:-15px;
      background-repeat: no-repeat;
      background-position: 50%;
      background-image: $iconExpand;
    }
  }

  &-expanded {
    margin-top: 0;

    &:after {
      transform: rotate(180deg);
    }
  }
}
