.job-announcements__wrapper {
  display: flex;
  flex-direction: column;
}

.job-announcement__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 15px 0;
}

.job__info {
  margin-right: 35px;
}

.job__tags {
  @include text-typography;
  color: $color-grey;
  margin-bottom: 5px;
  line-height: 1.43;
}

.job__title {
  span {
    @include text-typography($font-family-cera-regular, 16px);
    color: $color-grey-white;
    line-height: 1.38;
  }
}

.job-announcement__container:hover,
.job-announcement__container:active {
  background-color: $color-grey-medium;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 100%;
  }

  &:before {
    background-color: $color-grey-medium;
    left: -20px;
    border-bottom-left-radius: $dashboard-card-border-radius;
    border-top-left-radius: $dashboard-card-border-radius;
  }

  &:after {
    background-color: $color-grey-medium;
    left: 100%;
    border-bottom-right-radius: $dashboard-card-border-radius;
    border-top-right-radius: $dashboard-card-border-radius;
  }

  .dashboard-button.dashboard-button--secondary {
    border-color: $color-white;
    background-color: $color-white;
    color: $color-black;
  }

  .job__tags,
  .job__title {
    color: $color-white;
  }
}

@media screen and (max-width: 1360px) {
  .job__tags {
    font-size: $font-size-default-small;
  }

  .job__title {
    span {
      font-size: $font-size-title-small;
    }
  }

  .job-announcement__container:hover,
  .job-announcement__container:active {
    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 12px;
      height: 100%;
    }
    &:before {
      background-color: $color-grey-medium;
      left: -12px;
      border-bottom-left-radius: $dashboard-card-border-radius;
      border-top-left-radius: $dashboard-card-border-radius;
    }
    &:after {
      background-color: $color-grey-medium;
      left: 100%;
      border-bottom-right-radius: $dashboard-card-border-radius;
      border-top-right-radius: $dashboard-card-border-radius;
    }
  }
}
