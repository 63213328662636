$height: 50px;

.datepicker {
  position: relative;
  display: inline-block;
  height: $height;
  width: 100%;
  border: solid 1px $gray3;
  border-radius: 3px;
  cursor: pointer;
  background-color: $white1;
  background-image: linear-gradient(to bottom, $white1, $yellow8);

  &:before {
    content: '';
    position: absolute;
    width: 40px;
    height: 50px;
    left: 8px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../../assets/svg/datePicker/calendar-icon.svg');
  }

  &-input {
    @extend %typography-fieldsDescription;

    height: calc(#{$height} - 4px);
    width: calc(100% - 45px);
    margin: 3px 3px 0 45px;
    color: $blue1;
    cursor: pointer;
    border: none;
    outline: 0;
    background-color: transparent;
  }

  &-container {
    position: absolute;
    text-align: center;
    width: 310px;
    padding: 10px;
    z-index: 99;
    top: 49px;
    left: -1px;
    border-radius: 0 3px 3px 3px;
    border: solid 1px $yellow3;
    background-color: $yellow10;
    cursor: default;
    .react-datepicker__day--disabled{
      background-color: #cdd1d4;
      border-right: 1px solid #fff;
    }
    .react-datepicker__day-name{
      visibility:hidden;
      text-align: center;
      padding-left: 4px;
      border-left:1px solid #cdd1d4;
    }
    .react-datepicker__day-name:first-letter{
      visibility:visible;

    }
  }

  &-nav-left,
  &-nav-right {
    display: inline-block;
    width: 18px;
    height: 21px;
    vertical-align: top;
    border: 1px solid $gray3;
    background-repeat: no-repeat;
    background-position: 5px 3px;
    border-right: none;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: $white1;
    background-image: url('../../../assets/svg/datePicker/calendar-nav-left.svg');
  }

  &-nav-right {
    transform: rotate(180deg);
  }

  &-start {
    display: inline-block;
    vertical-align: top;
  }
  &-end {
    display: inline-block;
    vertical-align: top;
  }
}

.infinite-calendar{
  font-size: 14px;
  position: absolute;
  z-index: 99;
  left: 0;
  width: 100%;
  top: 49px;
  border-bottom: 2px solid #cdd1d4;
  width: calc(100% + 2px);
  margin-left: -1px;
  
  .Cal__Day__enabled{
    color: #000;
  }
  .Cal__Day__root{
    font-size: 14px;
  }
  .Cal__Today__root{
    background-color: $gray2 !important;

  }
  .Cal__Day__selection{
    background-color: #f9c64d !important;
    color: #f9c64d !important;
    border-color: #f9c64d !important;
    z-index: 100;

  }
  .Cal__Day__selected{
    color: #000;
    color: #f9c64d !important;

  }
  .Cal__Day__end{
    color: #F9AF45 !important;
    span{
      color: #000;
    }
  }
  .Cal__Weekdays__root{
    background-color: $gray1 !important;
    .Cal__Weekdays__day{
        color: #fff !important;
        font-size: 12px;
        font-weight: bold;
        padding: 5px 0;

    }
  }
  .Cal__Month__label{
    opacity: 0.3;
    span{
      color: #000 !important;
      font-size: 26px !important;
    }
  }
}
.Cal__Day__root.Cal__Day__today:before,
.Cal__Day__root.Cal__Day__enabled.Cal__Day__highlighted:before, .Cal__Day__root.Cal__Day__enabled:active:before, .Cal__Day__root.Cal__Day__enabled:hover:before,
.Cal__Day__root.Cal__Day__selected .Cal__Day__selection{
  border-radius: 0px !important;
}
.datepicker-input {
  font-style: normal;
  font-size: 16px;
}
.datepicker-input[value=""]{
  font-style: italic;
  color: #848e95;
}

.Cal__Day__root.Cal__Day__today {
  position: relative;
  color: transparent!important;
  z-index: 2;
}

.Cal__Day__month{
  display: none;
  position: initial;
  top: 0;
}

.Cal__Day__root.Cal__Day__selected .Cal__Day__selection {
  content: '';
  position: absolute;
  top: 50%;
  margin-left: -50%;
  width: 100%;
  height: 32px;
  margin-top: -16px;
  //margin-left: -16px;
  border-radius: 0%;
  line-height: 36px;
  z-index: 3;
}
.Cal__Day__range.Cal__Day__selected.Cal__Day__start:after, .Cal__Day__range.Cal__Day__selected.Cal__Day__end:after {
  content: '';
  position: absolute;
  top: 50%;
  margin-left: -50%;
  width: 100%;
  height: 32px;
  margin-top: -16px;
  -webkit-box-shadow: inset 36px 36px;
  box-shadow: inset 36px 36px;
}

.Cal__Day__root.Cal__Day__enabled.Cal__Day__highlighted:before, .Cal__Day__root.Cal__Day__enabled:active:before, .Cal__Day__root.Cal__Day__enabled:hover:before{
  content: '';
  position: absolute;
  top: 50%;
  //left: 50%;
  width: 100%;
  height: 32px;
  margin-top: -16px;
  margin-left: -50%;
  //border-radius: 50%;
  background-color: #EFEFEF;
  z-index: -1;
}

.Cal__Day__range.Cal__Day__selected.Cal__Day__end:after {
  left: 0;
  position: relative!important;
}
.Cal__Day__root.Cal__Day__today.Cal__Day__selected.Cal__Day__enabled{
  background-color: #f9c64d !important;
}
.Cal__Day__root.Cal__Day__today.Cal__Day__enabled {
  background-color: #cdd1d4;
  span{
    font-size: 16px;
    color: #ffffff;
    font-weight: bold;

  }
}
.Cal__Day__root.Cal__Day__today.Cal__Day__selected.Cal__Day__enabled.Cal__Day__range.Cal__Day__end {
  .Cal__Day__selection {
    background-color: #f9c64d !important;
    .Cal__Day__day {
      color: white;
    }
  }
}
.Cal__Day__range.Cal__Day__selected.Cal__Day__end .Cal__Day__selection{
  background-color: #f9c64d !important;
}
.Cal__Day__root.Cal__Day__selected .Cal__Day__selection .Cal__Day__day,
.Cal__Day__range.Cal__Day__selected.Cal__Day__start .Cal__Day__selection .Cal__Day__day ,
.Cal__Day__range.Cal__Day__selected.Cal__Day__end .Cal__Day__selection .Cal__Day__day {
  top: 0;
  position: relative;
  vertical-align: middle;
  line-height: 30px;
  display: block;
}
.infinite-calendar .Cal__Weekdays__root {
  background-color: #cdd1d4 !important;
  color: #ffffff;
}