.community-page__post {
  position: relative;
}

.create-new-post-modal.edit-post-modal {
  display: block;
  position: fixed;
  z-index: 1001;
  height: 100%;
  top: 40%;
  left: auto;
  width: auto;

  .new-post-wrapper {
    margin: auto;
  }
}