.office-card__container {
  height: 100%;
}
.office-card__image-container {
  background-size: cover;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-color: #0e161e;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  width: 100%;
  height: 215px;
  position: relative;
}
.office-card__premium {
  position: absolute;
  left: 10px;
  top: 13px;
  premium-partner__icon--full {
    width: 137px;
  }
  .premium-partner__icon--full:hover + .health__stamps-tooltip {
    opacity: 1;
  }
}
.office-card__images-container {
  display: flex;
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 8px;
  background-color: $color-grey-background;
  opacity: 0.8;
  border-radius: 3px;
  cursor: pointer;
}
.office-card__images-count {
  font-family: $font-family-cera-bold;
  font-size: 12px;
  padding-left: 5px;
}
.office-card__stamps {
  position: absolute;
  left: 10px;
  bottom: 10px;
  display: flex;
}
.office-card__info-wrapper {
  background-color: $color-grey-dark;
  padding: 28px;
  height: -webkit-calc(100% - 220px);
  height: -moz-calc(100% - 220px);
  height: calc(100% - 220px);
}
.office-card__info-container {
  margin-bottom: 20px;
}
.office-card__title {
  font-family: $font-family-cera-medium;
  font-size: 16px;
  color: $color-grey-white;
  margin-bottom: 15px;
}
.office-card__address {
  font-family: $font-family-cera-regular;
  font-size: 14px;
  color: $color-grey;
  max-width: 230px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
.office-card__button {
  font-family: $font-family-cera-medium;
  font-size: 14px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  background-color: transparent;
  border: 2px solid $color-yellow;
  border-radius: 3px;
  box-sizing: border-box;
  color: $color-yellow;
  cursor: pointer;
  font-weight: bold;
  height: 40px;
  padding: 11px 0;
  &:hover {
    color: $color-yellow-hover;
    border-color: $color-yellow-hover;
  }
}
.office-card__products-wrapper {
  margin-top: 25px;
  height: 120px;
}
.office-card__products-title, .office-card__tags-title {
  font-family: $font-family-cera-regular;
  font-size: 14px;
  color: $color-grey-white;
}
.office-card__products-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 10px;
  grid-auto-rows: 1fr;
  margin-top: 15px;
}
.office-card__product {
  display: inline;
  .office-card__product-icon {
    display: inline-block;
    width: 26px;
    height: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin-right: 10px;
    vertical-align: middle;
  }
  .desk-icon {
    background-image: url('../../../assets/svg/dashboard/desk-office-icon.svg');
  }
  .privateOffice-icon {
    background-image: url('../../../assets/svg/dashboard/private-office-icon.svg');
  }
  .meetingRoom-icon {
    background-image: url('../../../assets/svg/dashboard/meeting-room-icon.svg');
  }
  .eventRoom-icon {
    background-image: url('../../../assets/svg/dashboard/event-room-icon.svg');
  }
}
.office-card__tags {
  padding-top: 15px;
}
.office-card__tag {
  font-family: $font-family-cera-bold;
  font-size: 14px;
  text-transform: uppercase;
  color: $color-grey;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 6px;
  padding: 7px;
  border: 2px solid #323e47;
  border-radius: 3px;
}
//premium design
.office-card__info-wrapper--premium {
  background-color: $color-yellow;
  div, span {
    color: $color-black;
  }
  .office-card__button {
    background-color: $color-black;
    border-color: $color-black;
    color: $color-yellow;
  }
  .office-card__product {
    .desk-icon {
      background-image: url('../../../assets/svg/blackProducts/desk-office-icon.svg');
    }
    .privateOffice-icon {
      background-image: url('../../../assets/svg/blackProducts/private-office-icon.svg');
    }
    .meetingRoom-icon {
      background-image: url('../../../assets/svg/blackProducts/meeting-room-icon.svg');
    }
    .eventRoom-icon {
      background-image: url('../../../assets/svg/blackProducts/event-room-icon.svg');
    }
  }
}
@media (min-width: 1600px) and (max-width: 1750px) {
  .office-card__address {
    max-width: 195px;
  }
}
@media (min-width: 1280px) and (max-width: 1600px) {
  .office-card__address {
    max-width: 205px;
  }
}
