.no-communities__container {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 30vh;

  img {
    margin-bottom: 20px;
  }

  .no-communities__main-title {
    font-family: $font-family-cera-regular;
    font-size: 24px;
    color: $color-grey-white;
    margin-bottom: 15px;
  }

  .no-communities__sub-title {
    font-family: $font-family-cera-regular;
    font-size: 14px;
    color: $color-grey;
    margin-bottom: 30px;
  }

  .no-communities__btn {
    max-width: 208px;
    margin-left: auto;
    margin-right: auto;
  }
}