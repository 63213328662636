.community-page__post {
  .context-menu {
    border: solid 2px #1f2b35;
    background-color: #1f2b35;
    &:hover, &:active {
      border: solid 2px #7c878e;
      .context-menu-dots {
        color: $color-white;
      }
    }
  }
  .context-menu-dots {
    color: $color-grey;
  }
}

.community-page__post {
   border-radius: 6px;
   background-color: $color-grey-dark;
}
.community-page__post-hero-details {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  padding: 21px 30px;

}
.community-page__post-hero-picture {
  border-radius:30px;
  width:50px;
  height:50px;
  cursor: pointer;
}
.community-page__post-hero-name {
  display:block;
  font-family: $font-family-cera-medium;
  font-size: 16px;
  cursor: pointer;
  letter-spacing: normal;
  color: $color-grey-white;
  font-weight: 600;
}
.community-page__post-hero-headline,
.community-page__post-time {
  display:block;
  padding-top: 5px;
  font-family: $font-family-cera-medium;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: $color-grey;
}
.community-page__post-hero-detail {
  flex: 1 2 ;
  margin-left: 10px;
}
.community-page__post-buttons {
 display: flex;
 align-items: center;
 flex-direction : row;
 border-top:1px solid $color-black;
}
.community-page__post-container { margin-bottom: 19px;}
.community-page__post-container-title {
  margin-left: 30px;
  margin-right: 30px;
  font-family: $font-family-cera-regular;
  font-size: 16px;
  color: $color-grey-white;
  display:block;
  line-height: 1.38;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  .button__text-link { font-size: 16px;}
  span {
    color: $color-grey-white;
    font-size: 16px;
    font-family: $font-family-cera-regular;
  }

}
.community-page__post-like,
.community-page__post-comment
 {
  cursor: pointer;
  padding-left: 10px;
  font-family: $font-family-cera-regular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: $color-grey;
}
.community-page__post-like-container,
.community-page__post-comment-container {
    margin:auto;
    padding: 25px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.community-info-admin__label {
    background-color: $color-grey-white;
    border-radius: 3px;
    color: $color-grey-dark;
    font-size: 12px;
    text-transform: uppercase;
    font-family: $font-family-cera-medium;
    padding: 1px 5px;
    margin-left: 5px;
}
.community-page__posts-uploaded_image {
  max-width:330px;
  height: auto;
  margin-top: 10px;
  margin-left: 30px;
  cursor: pointer;

  .community-page__posts_image {
    width:100%;
    object-fit:contain;
  }
}

.community-page__posts-embedded-media {
  margin-top: 10px;
  margin-left: 30px;
}

.community-page__posts-uploaded_video__container {
  max-width: 330px;
  padding: 10px 15px;
  display:flex;
  align-items:center;
  margin: 10px 30px;

  .community-page__posts-uploaded_video__img { margin-right:20px; }
  .community-page__posts-uploaded_video__details {flex: 1; margin-right:20px; }
  .community-page__posts-uploaded_video__size {
    font-size: 14px;
    color: $color-grey;
    font-family: $font-family-cera-regular;
    padding-top: 5px;
  }
  .community-page__posts-uploaded_video__download-btn {
    width: 43px;
    height: 40px;
    border-radius: 3px;
    background-color: $color-white;
    padding: 11px 12px;
    visibility: hidden;
  }
  &:hover,
  &:active {
    background-color: $color-grey-medium;
    position: relative;
    cursor: pointer;

    &:before,
    &:after {
      content: '';
      position: absolute;
      height: 100%;
    }

  &:before {
    background-color: $color-grey-medium;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }

  &:after {
    background-color: $color-grey-medium;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }

  .community-page__posts-uploaded_video__download-btn { visibility: visible; }
  }
}
.community-page__post-like-orange {
  color: $color-yellow;
  cursor: pointer;
  padding-left: 10px;
  font-family: $font-family-cera-regular;
  font-size: 16px;
}

.community-page__no-more-posts-message {
  font-family: $font-family-cera-regular;
  text-align: center;
  margin-top: 20px;
}
.community-page__load-more-container {
  margin-top: 25px;
}

