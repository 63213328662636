@import '../../../theme/colors';

.rightSidebar {

  background-color: $blue1;

  max-width: 449px;
  height: 100%;
  //min-width: 430px;
  flex: 1;
  
  &.--full-height {
    .rightSidebar {
      &-content {
        height: 100%;
      }
    }
  }
  .active{
       cursor: pointer;
  }
  .non-active{
    cursor: pointer;
  }
}

