//.ww{
//    width: 20px;
//    height: 20px;
//    border-radius: 100px;
//    background-image: linear-gradient(to bottom, #ffffff, #f7f7f7);
//    border: solid 1px #cdd1d4;
//    &:hover{
//        width: 20px;
//        height: 20px;
//        border-radius: 100px;
//        background-image: linear-gradient(to bottom, #ffffff, #f7f7f7);
//        border: solid 1px #ffd87d;
//    }
//    &.disable{
//        width: 20px;
//        height: 20px;
//        border-radius: 100px;
//        background-color: #7c878e;
//    }
//
//}

.booking-sidebar {
    .booking-options-scrollable {
        > div.booking-options-title {
            margin-top: 30px;
        }
    }
    .mention {
        //margin-top: 8px;
        position: relative;
        padding-right: 20px;
    }
    .input-week-day{
        margin-bottom: 10px;
    }
    .booking-options-footer {
        width: 100%;
        position: relative;
        margin-top: -120px;
    }
    .product-second-line{
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fbfbfb;
        display: inline-block!important;
        width: 100%;
        float: inherit!important;
        padding-left: 30px;

    }
    .office-options-product {
        > label {
            margin-bottom: 0px!important;
            line-height: 18px;

            > div {
                padding: 0;
            }
        }
    }
    div.booking-options-title,
    h3.booking-options-title, .booking-payment-title {
        font-size: 14px;
        font-weight: bold;
        color: #131e29;
        text-transform: uppercase;
        margin: 53px 0 12px 0;
        padding: 0 0;
    }
    .booking-options-hours,
    .datepicker {
        //margin-bottom: 20px;
        display: inline-flex;
        width: 100%;
    }

    .booking-options-icons {
        margin: 0 0px;
    }
    .booking-options-icons div{
        font-weight: 500;
    }
    .office-options-product label{
        font-family: "CeraPRO-Medium";;
        font-weight: 500;
    }
    .booking-options-service,
    .booking-options-amenity {
        color: #131e29!important;
        font-weight: 500;
        display: flex;
        .mention {
            color: #131e29!important;
        }

        .entity{
          font-size: 24px;
          color: #131e29!important;
          font-weight: 500;
          width: calc(100% - 90px);
        }

    }
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    margin-bottom: 15px;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    line-height: 20px;
    font-size: 24px;
    display: inline-block;
    color: #ffffff;
    + div {
        display: inline-block;
        float: right;
    }

}
[type="radio"]:not(:checked) + label{
  font-weight: normal;
}
[type="radio"]:checked + label{
    color: #131e29;
    sup {
        color: #131e29;
    }
    > div {
        color: #131e29;
        padding: 0;
    }
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    background-image: linear-gradient(to bottom, #ffffff, #f7f7f7);
    //border: solid 1px #cdd1d4;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background-color: #00ce2d;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
[type="radio"][disabled] + label:before{
  background-image: linear-gradient(to bottom, #77878C, #77878C);
}
[type="radio"][disabled] + label{

    color: #FF5449;
    font-weight: normal;
    sup {
        color: $red1;
    }
    > div {
        color: $red1;
        padding-left: 0
    }
}







///* Base for label styling */
//[type="checkbox"]:not(:checked),
//[type="checkbox"]:checked {
//    position: absolute;
//    left: -9999px;
//}
//[type="checkbox"]:not(:checked) + label,
//[type="checkbox"]:checked + label {
//    position: relative;
//    padding-left: 1.95em;
//    cursor: pointer;
//}
//
///* checkbox aspect */
//[type="checkbox"]:not(:checked) + label:before,
//[type="checkbox"]:checked + label:before {
//    content: 's';
//    position: absolute;
//    left: 0; top: 0;
//    width: 1.25em; height: 1.25em;
//    border: 2px solid #ccc;
//    background: #fff;
//    border-radius: 4px;
//    box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
//}
///* checked mark aspect */
//[type="checkbox"]:not(:checked) + label:after,
//[type="checkbox"]:checked + label:after {
//    content: '✔';
//    position: absolute;
//    top: .2em; left: .275em;
//    font-size: 1.4em;
//    line-height: 0.8;
//    color: #09ad7e;
//    transition: all .2s;
//    font-family: Helvetica, Arial, sans-serif;
//}
///* checked mark aspect changes */
//[type="checkbox"]:not(:checked) + label:after {
//    opacity: 0;
//    transform: scale(0);
//}
//[type="checkbox"]:checked + label:after {
//    opacity: 1;
//    transform: scale(1);
//}
///* disabled checkbox */
//[type="checkbox"]:disabled:not(:checked) + label:before,
//[type="checkbox"]:disabled:checked + label:before {
//    box-shadow: none;
//    border-color: #bbb;
//    background-color: #ddd;
//}
//[type="checkbox"]:disabled:checked + label:after {
//    color: #999;
//}
//[type="checkbox"]:disabled + label {
//    color: #aaa;
//}
///* accessibility */
//[type="checkbox"]:checked:focus + label:before,
//[type="checkbox"]:not(:checked):focus + label:before {
//    border: 2px dotted blue;
//}
//
///* hover style just for information */
//label:hover:before {
//    border: 2px solid #4778d9!important;
//}
//
//
//
//
.styleScroll{
    > div:last-child > div {
    background-color: rgba(124, 135, 142, 0.35) !important;
    }
}
.styleScrollDark{
    > div:last-child > div {
        background-color: rgba(19, 30, 41, 0.35) !important;
    }
}
.Loader{
  text-align: center;
  height: 200px;
  background-image: url('../assets/images/loader.gif');
  background-position: center center;
  background-repeat: no-repeat;
}

.LoaderSmall{
    text-align: center;
    height: 60px;
    width: 100%;
    background-image: url('../assets/images/loader.gif');
    background-position: center center;
    background-repeat: no-repeat;
  }

.LoaderInverse{
  text-align: center;
  height: 200px;
  background-image: url('../assets/images/loader-inverse.gif');
  background-position: center center;
  background-repeat: no-repeat;
}

.LoaderInverseSmall{
  text-align: center;
  height: 60px;
  width: 100%;
  background-image: url('../assets/images/loader-inverse.gif');
  background-position: center center;
  background-repeat: no-repeat;
}

.MessageLoader{
  height: 16px;
  width: 100%;
  background-image: url('../assets/images/loader-messages.gif');
  background-position: center center;
  background-repeat: no-repeat;
}

.location-container{
  overflow-y: hidden;
}

button{
  &:focus{
    outline: none;
  }
}

input{
  &:focus{
    outline: none;
  }
}

.spacer-5{
  height: 5px;
}

.spacer-20{
  height: 20px;
}

.spacer-30{
  height: 30px;
}


input::-ms-clear {
  display: none;
}


.defaultAvatar{
  background-image: url('./../assets/images/defaultAvatarimage.png');
  background-size: cover;
}

.info-tooltip {
    display: inline-block;
    margin-left: 10px;
    font-size: 10px;

    position: absolute;
    font-size: 10px;
    bottom: 0px;

    .info-button {
        background: url('./../assets/svg/info-circle-normal.svg');
        height: 14px;
        width: 14px;
        display: inline-block;
        background-repeat: no-repeat;
        background-size: 14px 14px !important;
        &:hover{
            @extend .info-button;
            background: url('./../assets/svg/info-circle-active.svg');
        }
    }
}

.__react_component_tooltip.show {
    opacity: 1;
    background-color: #333f48;
    padding: 12px 12px;
    &.place-top {
        margin-top: -5px;
        &:after {
            border-top-color: #333f48;
        }
    }
}

.tooltip-content{
  font-size: 12px;
  .tooltip-item{
      line-height: 1;
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;

      &:last-child{
          margin-bottom: 0px;
      }

      .price-info-title {
          font-family: 'CeraPRO-Medium';
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: normal;
          text-align: left;
          color: #a0abb2;
          margin-right: 10px;
      }

      .price-info-value {
          font-family: 'CeraPRO-Medium';
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: normal;
          text-align: left;
          color: #ffffff;
          text-align: right;
      }

  }
}
