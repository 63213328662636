.office-amenities {

  &-title {
    @extend %officeDetails-titleBorder;
  }

  &-list {
    @extend %displayFlex-wrap;
    .amenity-available::after, .amenity-unavailable::after {
      top: 6px!important;
    }
  }

  .amenity-available,
  .amenity-unavailable {
    @extend %officeDetails-items;
    width: calc(100% / 3 - 30px) !important;
    padding-right: 40px;
  }

  .amenity-available:after {
    background-image: url(../../../assets/svg/officeDetails/feature-available.svg)
  }

  .amenity-unavailable:after {
    background-image: url(../../../assets/svg/officeDetails/feature-unavailable.svg)
  }

  .amenity-unavailable {
    color: $gray8;
  }
}


