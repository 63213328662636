@import '../../../theme/buttons';
@import '../../../theme/formElements';

& {
  height: 100%;
  width: 100%;
  .directChat-contacts {

    &-contactsWrapper {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      height: 100%;
      padding-bottom: 30px;
      width: 100%;
    }

    &-headerWrapper {
      display: flex;
      padding: 43px 0 9px;
      align-items: center;
      justify-content: space-between;
      h1{
        font-family: 'CeraPRO-Light';
        font-style: normal;
      }
      h1::before {
        margin-top: 4px;
        padding-right: 13px!important;
      }
    }

    &-pageTitle {
      align-items: center;
      color: $gray1;
      display: flex;
      font-size: 24px;
      line-height: 40px;
      height: 40px;

      &::before {
        color: $gray6;
        font-size: 21px;
        padding-right: 14px;
      }
    }

    &-newConversation {
      @extend %quickCtaFullButton-40;
      border: 0px solid transparent;
      margin-top: 4px;
      margin-bottom: 4px;

      &:hover{
        border: 0px solid transparent;
        margin-top: 4px;
        margin-bottom: 4px;
      }
      &.icon-directChat{
        border: 2px solid #f0b323;
        margin-top: 2px;
        margin-bottom: 2px;
        &:hover{
          border: 2px solid #ffc824;
          margin-top: 2px;
          margin-bottom: 2px;
        }

      }
      &::before {
        position: absolute;
      }
    }

    &-filter {
      background: $gray6;
      display: flex;
      padding: 30px;
      position: relative;
      &:after {
        content: '';
        display: block;
        width: 18px;
        height: 16px;
        background: url('../../../assets/svg/header/arrow.svg') no-repeat 50% 50%;
        position: absolute;
        right: 50px;
        top: 48px;
      }
      &Contacts, &Connections {
        @extend %input-base;

        box-sizing: border-box;
        color: $gray1;
        display: block;
        flex-grow: 1;
        justify-content: flex-start;
        padding-left: 54px;
        min-width: 100%;

        &:focus {
          width: 100%;
        }

      }

      &Icon {
        color: $gray1;
        font-size: 25px;
        left: 50px;
        position: absolute;
        top: 42px;
        z-index: 2;
      }
    }

    &-conversationsWrapper {
      background: $gray6;
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      & > div {
        flex-grow: 1;
      }
    }

    &-conversations, &-connections {
      height: 100%;
    }
    &-conversation, &-connection {
      cursor: pointer;
      display: flex;
      padding: 30px;
      align-items: center;
      border-bottom: dashed 1px #4f575c;
      &.active{
        border-bottom: dashed 1px #fff;
        &.newMessages {
        .directChat-contacts-conversationPreviewText {
          color: $gray1;
          }
        }
      }

      &Avatar {
        align-items: center;
        border-radius: 50%;
        display: flex;
        height: 50px;
        margin-right: 10px;
        min-width: 50px;
        overflow: hidden;
        width: 50px;
      }

      &Image {
        max-height: 50px;
        max-width: 50px;
      }

      &Details {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        max-width: calc(100% - 60px);
      }

      &Poster {
        color: $white1;
        //display: flex;
        font-size: 16px;
        //justify-content: space-between;
        line-height: 1.5;
        margin-bottom: 4px;
        &Name{
          display: inline-block;
        }
        &LastName {
          color: $white1;
          display: inline;
          font-size: 16px;
          font-weight: 500;

          line-height: 1.5;
          text-transform: uppercase;
        }
      }

      &Online {
        background-color: $green1;
        border-radius: 50%;
        display: inline-block;
        margin-bottom: 2px;
        margin-left: 5px;
        height: 6px;
        width: 6px;
      }

      &Ago {
        color: $gray1;
        font-size: 16px;

        span {
          font-size: 16px;
          font-weight: 500;
        }
      }

      &Preview {
        //display: flex;
        //justify-content: space-between;
        align-items: center;
        &Text {
          color: $gray1;
          font-size: 14px;
          line-height: 1.29;
          padding-right: 13px;
          &.notMuted {
            //padding-right: 33px;
            //position: absolute;
            //width: 70%;
            //text-overflow: ellipsis;
            //overflow: hidden;
            //height: 1.2em;
            //white-space: nowrap;
            width: 100%;
            padding-right: 0px;
            //max-height: 36px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: pre-wrap;
            word-wrap: break-word;
            word-break: break-word;
            font-family: 'CeraPRO-Medium';
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.29;
            letter-spacing: normal;
            text-align: left;
            //color: #7c878e;
            position: relative;
            &::after {
              float: right;
              position: absolute;
              top: 18px;
              z-index: 150;
              padding-left: 2px;
              //background: #fff;
              right: 0px;
              text-align: right;
              text-align: right;
            }
          }
        }
      }

      &Muted {
        color: $yellow1;
        font-size: 20px;
      }

      &Delete {
        @extend %quickCtaFullButton-40;

        position: absolute;
        right: 30px;
        display: none;
      }

      &:hover {
        background: $blue1;
        .directChat-contacts-conversationPreviewText::after{
          background: $blue1!important;
        }
        .directChat-contacts-conversationDelete {
          display: block;
        }
      }

      .directChat-contacts-conversationDelete {
          &.icon-delete{
            &::before{
              position: absolute;
              top: 8px;
              right: 8px;
            }
          }
      }

      .directChat-contacts-conversationAgo{
        //display: inline-block;
        right: 0;
        float: right;
        position: relative;
        display: inline-block;
        span{
          color: $gray1;
        }
      }

      &.active {
        background: $white1;
        .directChat-contacts-conversationPreviewText::after{
          background: $white1 !important;
        }
        &:hover{
          .directChat-contacts-conversationPreviewText::after{
            background: #f2f2f2!important;
          }
        }
        .directChat-contacts-conversationPosterName,
        .directChat-contacts-conversationPosterLastName {
          color: $blue1;
        }

        &:hover {
          background: $white2;
        }
      }

      .directChat-contacts-conversationPosterName{
        display: inline-block;
      }

      &.newMessages {
        .directChat-contacts-conversationPreviewText {
          width: 100%;
          padding-right: 0px;
          max-height: 36px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: pre-wrap;
          word-wrap: break-word;
          font-family: 'CeraPRO-Light';
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.29;
          letter-spacing: normal;
          text-align: left;
          color: $yellow1;
          position: relative;
          &::after {
            content: "\02026";
            float: right;
            position: absolute;
            top: 18px;
            z-index: 150;
            padding-left: 2px;
            background: rgba(51,63,72,1);
            right: 0px;
            text-align: right;
            text-align: right;
          }

        }
      }

      &.muted {
        .directChat-contacts-conversationPosterName,
        .directChat-contacts-conversationPosterLastName {
          color: $gray1;
        }
      }
    }

    &-informationWrapper {
      align-items: center;
      display: flex;
      height: 100%;
      padding: 0 50px;
    }

    &-information {
      text-align: center;
      padding-bottom: 60px;
    }

    //&-connection {
    //  padding: 10px 30px;
    //}
  }
}
