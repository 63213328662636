@import '../../../theme/colors';

.modal-dialog-left {
  z-index: 100;
  background-color: rgba(19, 30, 41, 0.9) !important;
  position: fixed;
  left: 129px;
  top: 17.28%;
  width: 100%;
  outline: none;

  .modal-dialog-content {
    width: 68.4%;
    border-radius: 3px;
    padding: 30px 30px 30px 77px;
    background-color: $gray6;
    position: relative;
    outline: none;
  }
}
