.news__load-more-container {
  display: flex;
  justify-content: center;
  padding: $dashboard-spacing;
  min-height: 100px;

  .news__load_more {
    max-width: 500px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
