.dashboard__tags {
  .dashboard__tag {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 6px;
    padding: 7px;
    @include default-border($dashboard-border-size, $color-grey-medium-alt, 3px);
    text-transform: uppercase;
    font-family: $font-family-cera-bold;
    font-size: $font-size-default;
    color: $color-grey;
    &:last-child {
      margin-right: 0;
    }
  }
  .tags__more {
    display: inline-block;
    margin-left: 10px;
    text-transform: uppercase;
    font-family: $font-family-cera-bold;
    font-size: $font-size-default;
    color: $color-grey;
  }
}

@media (max-width: 1360px) {
  .dashboard__tags {
    .dashboard__tag {
      padding: 4px;
      margin-right: 6px;
      font-size: $font-size-default-small;
    }
    .tags__more {
      font-size: $font-size-default-small;
    }
  }
}
