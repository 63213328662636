@import '../../../theme/colors';

.selectBox {
  font-size: 14px;
  color: $gray1;
  display: inline-block;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  max-width: 100%;

  &.--fluid {
    width: 100%;
  }

  &-label {
    color: inherit;
    font-size: 14px;

    &:hover, &.--triggered, &.--selected {
      color: $white1;
    }

    &:after {
      display: inline-block;
      content: '';
      border: {
        right: 2px solid $gray1;
        bottom: 2px solid $gray1;
      }

      width: 6px;
      height: 6px;
      margin-left: 3px;
      transform: rotate(45deg) translateY(-4px);
    }
  }

  .dropdown-header{
      font-size: 12px;
  }

  &-options {
    position: absolute;
    background-color: white;
    border-radius: 3px;

    min-width: 230px;
    max-width: 400px;
    height: 100%;

  }

  &-optionsContainer {
    z-index: 10;

    &:before {
      position: absolute;
      content: '';
      left: 40px;
      top: 4px;
      border: 10px solid transparent;
      border-bottom-color: $white1;
    }
  }

  &-option {
    font-size: 18px;
    color: $blue1;
    padding: 14px 8px 14px 15px;
    white-space: nowrap;
    border-bottom: 1px solid $gray3;
    overflow: hidden;
    word-wrap: break-word;
    white-space: pre-wrap;

    &:last-of-type {
      border-bottom: 0 none;
    }

    &:hover {
      background: linear-gradient(to bottom, $white1, $gray16);
    }

    &:active {
      background: linear-gradient(to bottom, $white1, $gray11);
    }

    &:focus {
      background: linear-gradient(to bottom, $white1, $gray11);
    }

    &.--selected {
      background-color: $green3;
      color: $green2;
      font-weight: bold;
    }
  }

  &.--absolute {
    position: absolute;
  }

  &.--selected {
    .selectBox {
      &-label {
        text-transform: uppercase;
        color: $white1;
      }
    }
  }

  &.--classic {
    background-image: linear-gradient(to bottom, $white1, $yellow8);
    border-radius: 3px;

    &.--triggered {
      border: {
        bottom-left-radius: 0;
        bottom-right-radius: 0;
      }
    }

    &.--selected {
      .selectBox {
        &-label {
          font-style: normal;
          color: $blue1;
          text-transform: none;
        }
      }
    }

    .selectBox {
      &-content {
        height: 50px;
        border: solid 1px #cdd1d4;
        padding-right: 36px;
        border-radius: 3px;
      }

      &-labelContainer {
        box-sizing: border-box;
        width: 100%;
      }

      &-label {
        font-size: 16px;
        font-style: italic;
        color: $gray5;
        padding: 18px 20px;
        white-space: nowrap;
        overflow: hidden;

        &:after {
          position: absolute;
          right: 22px;
          top: 23px;
        }
      }

      &-options {
        border-top: 1px solid $green4;
        top: 0;
        left: 0;
        min-width: 100%;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  &.--multiple {
    .selectBox {
      &-option {
        line-height: 20px;

        &:before {
          display: inline-block;
          content: '';

          vertical-align: top;
          width: 20px;
          height: 20px;
          border-radius: 3px;
          background-image: linear-gradient(to bottom, $white1, $yellow8);
          border: solid 1px $gray3;
          box-sizing: border-box;
          margin-right: 8px;
        }

        &:hover, &:active {
          background: #fff;

          &:before {
            background: rgba($green3, .5);
          }
        }

        &.--selected {
          color: $blue1;
          font-weight: bold;
          background-color: transparent;

          &:before {
            background: url('./../../../assets/svg/check.svg') no-repeat center center $green3;
          }
        }
      }
    }
  }

  &.--with-error{
    border: solid 1px $red1;
  }
}
