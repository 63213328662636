@import '../../../../theme/colors';
@import '../../../../theme/layout';

//$imageHeight: 320px;
$imageHeight: calc(21.56vw / 1.30);
.officeBox {
  display: block;
  .upper-stamps {
    position: absolute;
    display: flex;
    flex-direction: row;
    margin-top: 5px;
  }
  .comingUp-label{
    font-family: 'CeraPRO-Medium';
    font-size: 13px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #131e29;
    border-radius: 0 47px 47px 0;
    background-color: #f0b323;
    display: inline-block;
    padding: 4px 10px 4px 10px;
    margin-top: 10px;
    position: relative;
  }
  &-mainLink {
    text-decoration: none;
  }

  &-image {
    background-size: contain;
    background-position: top left;
    background-repeat: no-repeat;
    background-color: $gray12;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    //height: $imageHeight;
    //min-height: 310px;
    width: 100%;
  }

  &-info {
    padding: 27px 28px;
    background-color: $gray12;
    box-sizing: border-box;
    position: relative;

    @media screen and (max-width: $largeDevices) {
      padding-top: 10px;
    }
  }

  &-title {
    margin-bottom: 22px;
    line-height: 12px;
    font-size: 18px;
    font-weight: normal;
    font-family: CeraPRO-Regular;

    span{
      &.count{
        color: $gray1;
        font-size: 22px;
      }
    }

    @media screen and (max-width: $largeDevices) {
      margin-bottom: 0;
    }
  }
  &-address {
    font-size: 14px;
    color:$gray1;
    font-family: CeraPRO-Regular;
    padding: 5px 0 10px;
  }
  &-infoSmall {
    font-size: 14px;
    color: $gray1;

    @media screen and (max-width: $largeDevices) {
      text-align: left;
    }
  }
  &-infoSmall-date {
    font-family: CeraPro-Medium;
    font-size: 14px;
    line-height: 15px;
    color: #ffffff;
    display: inline-flex;
    &::before{
      content: '';
      width: 18px;
      height: 14px;
      margin-right: 10px;
      display: inline-block;
      background-repeat: no-repeat;
      background-image: url('../../../../assets/svg/calendarIcon.svg');
    }

    @media screen and (max-width: $largeDevices) {
      text-align: left;
    }
  }
  &-infoSmall-productName {
    line-height: 15px;
    font-family: CeraPRO-Regular;
    font-size: 14px;
    color: $gray1;
    padding: 5px 25px;
  }
  &-invitationSend {
    span {
      font-size: 14px;
      font-family: CeraPRO-Regular;
      line-height: 9px;
      color: #ffffff;
    }
    &::before{
      content: '\e903';
      margin-right: 10px;
      display: inline-block;
      font-family: 'glyphify';
      font-size:14px;
      color: $yellow1;
    }
  }
  &-infoHighlighted {
    color: $white1;
    font-size: inherit;
    margin-left: 5px;
  }

  &-location {
    background: url('../../../../assets/svg/map/bookmark-link.svg') no-repeat -10px 6px $yellow1;
    background-size: 70px auto;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
    border-radius: 50%;

    position: absolute;
    top: -25px;
    right: 30px;
    width: 50px;
    height: 50px;

    &:hover {
      background-color: $yellow2;
    }

    &:active {
      transform: translateY(-2px);
    }
  }

  &-connections {
    margin-top:29px;
    font-size: 14px;

    span {
      // cursor: pointer;
      font-size: inherit;

      &:hover {
        //text-decoration: underline;
      }
    }
  }

  &-vertical {
    .officeBox {
      &-image {
        height: 123px;
        min-height: 123px;
      }

      &-title {
        @media screen and (max-width: $largeDevices) {
          width: 100%;
        }
      }

      &-info {
        background-color: transparent;
        padding: {
          left: 0;
          right: 0;
        }
      }
    }
  }

  &.--fullView {
    .officeBox {
      &-image {
        //height: 320px;
        height: calc(21.56vw / 1.30);
        min-height: 310px;
      }

      &-info {
        background-color: $gray12;
        padding: {
          left: 28px;
          right: 28px;
        }
      }

      &-title {
        width: 100%;
      }

      &-infoTitle {
        display: block;
        text-transform: uppercase;
        font-weight: bold;
        margin: {
          bottom: 0;
          top: 24px;
        }
        color: $gray1;
        font-size: 14px;
      }

      &-infoData {
        font-size: 24px;

        & > div{
          font-size: 22px;
          margin-top: 3px;
          a{
            &:hover{
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
