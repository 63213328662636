.payment-content{
  display: flex;
  flex-direction: row;
  padding: 30px;
}
.payment-content__left-area {
  flex: 2 1;
  flex-basis: 20px;
}
.payment-content__right-area {
  flex: 1 1;
  &.add-payment-account-details {
    margin-top: 163px;
  }
}
.booking-details__title,
.profile-details__title,
.account-details__title {
  font-family: $font-family-cera-medium;
  font-size: 14px;
  line-height: 0.64;
  letter-spacing: normal;
  color: #131e29;
  font-weight: 500;
}
.booking-details, .profile-details, .account-details {
  background: $color-yellow;
  padding: 30px;
  border-radius: 3px;
  margin-bottom: 10px;
}
.account-details-content {
  display: flex;
  flex-direction: row;
  margin-top: 25px;
}
.account-details__icon{
  object-fit: contain;
  background: #131e29 ;
  border-radius: 3px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  &:before{
    color: $color-yellow;
  }
}
.account-details__section{
  display: flex;
  flex-direction: column;
  .account-details__hero-name {
    font-family: $font-family-cera-medium;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    line-height: 25px;
    letter-spacing: normal;
    color: #131e29;
  }
  .account-details__email {
    font-family: $font-family-cera-regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.64;
    letter-spacing: normal;
    color: #131e29;
    padding-top:10px;
  }
}
.profile-details__description-container, .profile-details__grid {
  display: flex;
  flex-direction: column;
}
.profile-details__image-container, .booking-details__image-container {
  width: 36px;
  height: 36px;
  background: #131e29;
  object-fit: contain;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-top: 5px;
  .profile-details__image, .booking-details__image {
    width: 20px;
    height: 20px;
  }
}
.profile-details__headline, .booking-details-content{
  display: flex;
  margin-top: 20px;
}
.profile-details__description-container, .booking-details__info {
  display: flex;
  flex-direction: column;
  flex: 2 1;
  .profile-details__job-description, .booking-details__location-title{
    font-family: $font-family-cera-medium;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #131e29;
  }
  .profile-details__company, .booking-details__product-name {
    font-family: $font-family-cera-medium;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #131e29;
    padding-top: 5px;
  }
  .profile-details__experience {
    font-family: $font-family-cera-regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #131e29;
    padding-top: 5px;
  }
}
.profile-details__row {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  .profile-details__row-title {
    font-family: $font-family-cera-medium;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.69;
    letter-spacing: normal;
    color: #131e29;
    padding-bottom: 5px;
  }
  .profile-details__row-description {
    font-family: $font-family-cera-regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #131e29;
  }
}
.booking-details__price {
  font-family: $font-family-cera-medium;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.69;
  letter-spacing: normal;
  text-align: right;
  color: #131e29;
}
.booking-details__info-row {
  display: flex;
  margin-top: 20px;
  .booking-details__section {
    display: flex;
    flex-direction: column;
    flex: 2 1 ;
  }
  .booking-details__section-title {
    font-family: $font-family-cera-medium;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.69;
    letter-spacing: normal;
    color: #131e29;
  }
  .booking-details__amenities-list {
    font-family: $font-family-cera-regular;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #131e29;
    width: 250px;
    padding-top: 5px;
  }
}
.payment-profile__content {
  background: #f0b323;
  padding: 30px;
  border-radius: 3px;
  margin-bottom: 10px;
  .payment-profile-details {
    display: flex;
    flex-direction: column;

    .profile-details__row-title {
      margin-top: 20px;
      font-family: $font-family-cera-medium;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 15px;
      letter-spacing: normal;
      color: #131e29;
    }

    .profile-details__row-description {
      font-family: $font-family-cera-regular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 20px;
      letter-spacing: normal;
      color: #131e29;
    }
  }

  .account-details__hero-name {
    font-family: $font-family-cera-medium;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 25px;
    letter-spacing: normal;
    color: #131e29;
  }
  .profile-details__headline {
    align-items: center;
  }
  .profile-details__image {

    border-radius: 3px;
    background-color: #131e29;
  }
}