$font-path: "../assets/fonts" !default;

@font-face {
	font-family: 'glyphify';
	src: url('#{$font-path}/glyphify.eot?rp7hu9');
	src: url('#{$font-path}/glyphify.eot?rp7hu9#iefix') format('embedded-opentype'),
		url('#{$font-path}/glyphify.ttf?rp7hu9') format('truetype'),
		url('#{$font-path}/glyphify.woff?rp7hu9') format('woff');
		//url('#{$font-path}/glyphify.svg?rp7hu9#glyphify') format('svg');
	font-weight: normal;
	font-style: normal;
}

@import '_ceraPro';