@import 'colors';
%mainButton {
  $buttonHeight: 50px;
  background-color: $yellow1;
  box-sizing: border-box;
  border-radius: 3px;
  color: $blue1;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  height: $buttonHeight;
  line-height: $buttonHeight;
  margin-bottom: 2px;
  padding: 0 30px;
  user-select: none;

  &:hover {
    background-color: $yellow2;
  }

  &:active {
    margin-bottom: 0;
  }

  &:disabled, &.disabled {
    background-color: $gray1;
    color: $gray2;
    cursor: default;

    &:hover {
      background-color: $gray1;
      color: $gray2;
    }
    &:active {
      margin-bottom: 0;
      //margin-top: 0;
    }
  }
}

%mainButton-leftSide {
  @extend %mainButton;
  $buttonHeight: 40px;
  height: $buttonHeight;
  line-height: $buttonHeight;
  padding: 0 20px;
}

%secondaryButton {
  $buttonHeight: 50px;
  background-color: transparent;
  border: 2px solid $yellow1;
  border-radius: 3px;
  box-sizing: border-box;
  color: $yellow1;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  height: $buttonHeight;
  line-height: $buttonHeight - 4;
  margin-bottom: 2px;
  padding: 0 28px;
  user-select: none;

  &:hover {
    border-color: $yellow2;
    color: $yellow2;
  }

  &:active {
  }

  &:disabled, &.disabled {
    border-color: $gray2;
    color: $gray2;
    cursor: default;

    &:hover {
      border-color: $gray2;
      color: $gray2;
    }
    &:active {
      margin-bottom: 0;
      //margin-top: 0;
    }
  }
}

%secondaryButton-leftSide {
  @extend %secondaryButton;
  $buttonHeight: 40px;
  height: $buttonHeight;
  line-height: $buttonHeight - 4;
  padding: 0 18px;
}

%tagButton {
  background-color: transparent;
  border-radius: 3px;
  border: solid 1px $gray3;
  box-sizing: border-box;
  color: $gray4;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  padding: 9px 29px 9px 9px;
  position: relative;
  user-select: none;

  &::after {
    background: url('../assets/x.svg') no-repeat 0 0;
    color: red;
    background-size: 11px 11px;
    content: " ";
    display: block;
    height: 11px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 11px;
  }

  &:hover, &:active {
    border-color: $gray4;
  }

  &:disabled, &.disabled {
    background-color: $gray1;
    border-color: $gray1;
    color: $gray2;
    cursor: default;

    &:hover, &:active {
      background-color: $gray1;
      border-color: $gray1;
      color: $gray2;
    }
  }
}

%editButton {
  background: none;
  color: $gray1;
  cursor: pointer;
  margin: 2px 0;

  &:hover, &:active, &.active {
    color: $white2;
  }

  &:active {
    //margin: 4px 0 0;
  }

  &:disabled, &.disabled {
    color: $gray6;

    &:active {
      margin: 2px 0;
    }
  }
}

%quickCtaButton {
  align-content: center;
  background: none;
  border: 2px solid $yellow1;
  border-radius: 50%;
  box-sizing: border-box;
  color: $yellow1;
  cursor: pointer;
  display: flex;
  font-size: 28px;
  height: 50px;
  justify-content: center;
  width: 50px;
  margin-top: 2px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;

  &:hover {
    border: 2px solid $yellow2;
    color: $yellow2;
  }

  &:active {
    //margin-top: 4px;
    margin-bottom: 0;
  }

  &.active, &:active {
    background: $yellow1;
    color: $blue1;

    &:hover {
      background: $yellow2;
    }
  }
}

%quickCtaFullButton {
  @extend %quickCtaButton;
  background-color: $yellow1;
  color: $blue1;

  &:hover, &.active, &:active {
    background-color: $yellow2;
    color: $blue1;
  }
}

%quickCtaFullButton-40 {
  @extend %quickCtaFullButton;
  font-size: 24px;
  height: 40px;
  width: 40px;
}

%slider {
  @extend %quickCtaFullButton;

  background: $gray6;
  border-color: $gray6;
  color: $blue1;
  font-size: 32px;
  height: 60px;
  width: 60px;


  &:hover, &:active, &.active {
    background: $gray7;
    border-color: $gray7;
  }

  &.active, &:active {
    &:hover {
      background: $gray7;
    }
  }
}

%textLink {
  background: transparent;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin: 2px 0;
  padding: 5px;
  text-decoration: none;
  text-transform: uppercase;
}

%editLink {
  @extend %textLink;

  color: $yellow1;

  &:hover, &:active, &.active {
    color: $yellow2;
  }

  &:active {
    margin: 4px 0 0;
  }

  &:disabled, &.disabled {
    color: $gray6;

    &:active {
      margin: 2px 0;
    }
  }
}

%cancelLink {
  @extend %textLink;
  color: $gray1;

  &:hover, &:active, &.active {
    color: $white2;
  }

  &:active {
    margin: 4px 0 0;
  }

  &:disabled, &.disabled {
    color: $gray6;

    &:active {
      margin: 2px 0;
    }
  }
}

%deleteLink {
  @extend %textLink;
  color: $red2;

  &:hover, &:active, &.active {
    color: $red3;
  }

  &:active {
    margin: 4px 0 0;
  }

  &:disabled, &.disabled {
    color: $gray6;

    &:active {
      margin: 2px 0;
    }
  }
}

%simpleButton {
  color: $gray1;
  cursor: pointer;
  font-size: 26px;
  margin: 2px 0;

  &:hover, &:active, &.active {
    color: $yellow1;
  }

  &:active {
    margin: 4px 0 0;
  }
}
.main-button {
  @extend %mainButton;
  &:focus{
    outline: none;
  }
}

.main-button-leftSide {
  @extend %mainButton-leftSide;
  &:focus{
    outline: none;
  }
}

.secondary-button {
  @extend %secondaryButton;
  font-family: 'CeraPRO-Medium';
  margin-top: 2px;
  &:focus{
    outline: none;
  }
}

.secondary-button-leftSide {
  @extend %secondaryButton-leftSide;
  margin-top: 2px;
  &:focus{
    outline: none;
  }
}

.tag {
  @extend %tagButton;
}