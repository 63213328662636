@import '../../../theme/colors';
@import '../../../theme/layout';

.not-booked-location {
  padding: 30px;

  .not-booked-title{
    text-transform: uppercase;
    font-family: 'CeraPRO-Medium';
    text-decoration: none;
    margin-top: 30px;
    margin-bottom: 35px;
    padding-top: 30px;
    position: relative;
    color: #7c878e;
    font-size: 14px;
    font-weight: bold;
  }

  .not-booked-office-space-item{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;

    .not-booked-office-space-item-label{
      font-size: 24px;
      font-weight: 300;
    }

    .not-booked-office-space-item-value{
      font-size: 24px;
      font-weight: 300;
    }
  }

  p {
    color: $gray1;
    font-size: 14px;

    span {
      color: $red2;
      font-size: 14px;
    }
  }

  button {
    width: 100%;

    &.main-button {
      margin: 30px 0;
      @media only screen and (max-width : $largeDevices) {
        font-size: 11px;
        line-height: 49px;
      }
    }

    &.send-mail {
      margin-bottom: 0;
      text-transform: uppercase;
      position: relative;

      &:before {
          content: '';
          display: block;
          background: url('../../../assets/svg/events/email-icon.svg') no-repeat 50% 50%;
          background-size: contain;
          width: 22px;
          height: 15px;
          position: absolute;
          left: 20px;
          top: 17px;

          @media only screen and (max-width : $largeDevices) {
            left: 16px;
            top: 16px;
          }
      }
    }
  }

  .bookedOffice-checkout{
    padding-top: 0px;

    .extended-btn{
      margin: 0px 0px 0px 20px;
    }

    .check-btn{
      margin-top: 0px;
      color: #131E29;
      background-color: #f0b323;
      white-space: nowrap;
      &:hover{
        background-color: #ffc824;
        color: #131E29;
      }
    }
  }

  .bookedOffice-checkin{
    padding-top: 0px;
    padding-bottom: 20px;
    .check-btn{
      margin-top: 0px;
      color: #131E29;
      background-color: #f0b323;

      &:hover{
        background-color: #ffc824;
        color: #131E29;
      }
    }
  }

  .book-parking-button {
    margin: auto;
  }

  .bookedOffice__extra-details-container {
    margin-top: 10px;
    border-top: 1px solid #7c878e;
  }
  .bookedOffice__extra-details-text, .bookedOffice__extra-details-button {
    margin-top: 20px;
  }
  .bookedOffice__extra-details-button:focus {
    color: #f0b323;
  }

  @media (min-width: 320px) and (max-width: 1279px){
    .bookedOffice-checkout {
      padding-top: 50px;
    }

    .bookedOffice-checkin{
      padding-top: 50px;
    }
  }
}
