.community-loader-posts__container,
.community-loader__container {
  @include default-border(2px, $color-grey-medium, 3px);
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 180px;
  max-width: 27%;
  margin: 10% auto;

  .community-loader__text {
    font-family: $font-family-cera-regular;
    font-size: 20px;
    color: $color-grey;
    margin-bottom: 30px;
  }

  .community-loader__image {
    height: 18px;
    width: auto;
  }
}
.community-loader-posts__container { border:none; }
