.community-modal__sectioned {
  .base-modal__modal-content {
    width: 640px;
    position: relative;
  }

  .base-modal__left-area {
    width: 52px;
  }

  .base-modal__left-area-icon {
    color: $color-yellow;
    margin-left: 15px;
    margin-bottom: 16px;
    width: 20px;
    height: 13px;
  }

  .base-modal__close {
    position: absolute;
    color: $color-grey;
  }

  .base-modal__modal-header {
    padding:30px 30px 0 30px;
  }

  .base-modal__modal-header-title {
    font-family: $font-family-cera-regular;
    font-size: 24px;
    color: $color-grey;
  }
}