.map-filter__container-dropdown {
  display: flex;
  .map-filter__dropdown {
    border-radius: 3px;
    background-color: #1f2b35;
    height: 73px;
    margin-right: 5px;
    min-width: 260px;
    &:hover {
      background-color: #333f48;
      .dropdown-field-title {
        color:#FFFFFF;
      }
    }
    &:nth-last-child(2) {
      min-width: 160px;
      width: 100%;
      margin-right: 0px;
      border-bottom-right-radius: unset;
      border-top-right-radius: unset;
    }
    &.map-filter__floor {
      min-width: 180px;
      max-width: 180px;
    }
    &.map-filter__type {
      min-width: 200px;
      max-width: 200px;
    }
    .Cal__Day__root.Cal__Day__today:before {
       background-color: transparent !important;
       box-shadow: inset 0 0 0 0px transparent;
       color: white;
    }
    .Cal__Day__root.Cal__Day__today {
      span {
       font-size: 18px;
       color: #d9dfe3 !important;
      }
    }
  }

  .dropdown-input-field {
    line-height: 40px;
    color: #f0b323;
    user-select: none;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 20px 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .dropdown-field-title {
      font-family: CeraPRO-Bold;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: 14px;
      letter-spacing: normal;
      color: #7c878e;
      text-transform: uppercase;
      text-overflow: ellipsis;
      overflow: hidden;
      min-width: 135px;
      //width: 160px;
      height: 20px;
    }
    .dropdown-field-value {
      font-family: CeraPRO-Regular;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.69;
      letter-spacing: normal;
      color: #ffffff;
      display: block;
      padding-top: 5px;
      //min-width: 135px;
      min-width: 100px;
      width: 100%;
      height: 20px;
    }
    &:after {
      content: '';
      position: absolute;
      right: 20px;
      width: 14px;
      height: 60px;
      transition: transform .2s ease-in-out;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../../assets/svg/dropdown-arrow__down-grey.svg');
    }
    .completed.dropdown-field-value {
      color: #f0b323;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    }
  }
  .container {
    display: none;
    box-sizing: border-box;
    width: 100%;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px #131e29 !important;
    border-top: none;
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
    position: absolute;
    z-index: 9;

    input[type=checkbox] {
      display: none;
    }
    input[type="checkbox"] ~ .list-label:before {
      content: " ";
      background-color: #0f171f;
      display: inline-block;
      margin-left: 10px;
      margin-right: 10px;
      padding-bottom: 5px;
      color: #0f171f;
      min-width: 22px;
      width: 22px;
      height: 22px;
      visibility: visible;
      border: 1px solid #323e47;
      padding-left: 3px;
      border-radius: 5px;
      vertical-align: middle;
    }

    input[type="checkbox"] ~ .list-label.checked:before {
      border: 1px solid #d9dfe3;
      content: "\2714";
      color: #fff;
    }
    input[type="checkbox"] ~ .list-label.disabled:before {
      border: 1px solid #323E47;
      content: "\2714";
      color: #323E47;
      cursor: not-allowed;
      pointer-events: none;
    }
    .list {
      padding: 20px 15px;
      cursor:pointer;
      border-bottom: 2px solid #131e29;
      color: #7c878e;
      display: flex;
      align-items: center;
    }
    .filterIcon {
      padding-left: 5px;
      vertical-align: middle;
    }
    .list-label {
      font-family: CeraPRO-Regular;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      line-height: 20px;
      letter-spacing: normal;
      color: #7c878e;
      display: inline-flex;
    }
    .map-filter__capacity .list-label { padding-left: 20px; }
    .list-label.checked {
      color: #fff;
    }
    .disabled {
      cursor: not-allowed;
      pointer-events: none;
    }
  }
  .datepicker .dropdown-input-field::after {
    width: 20px;
    right: 10px;
    height: 60px;
    background-image:  url('../../assets/svg/datePicker/calendar-icon-grey.svg');
  }
  .datepicker .dropdown-input-field.hasDateSelected::after {
    background-image: unset ;
  }
  .dropdown-field-value.hasDateSelected { color: #F0B323;  }
  .infinite-calendar .Cal__Weekdays__root {
    background-color: #1f2b35 !important;
    .Cal__Weekdays__day {
      font-family: CeraPRO-Regular;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      text-align: center;
      color: #7c878e !important;
      background-color: #1f2b35;
    }
  }
  .infinite-calendar {
    top:unset;
    border-bottom: unset;
  }
  .datepicker {
    border: unset;
    &::before {
      background-image: unset;
    }
  }
  .Cal__Month__row.Cal__Month__partial:first-child li:first-child {
    box-shadow: 0px -0.05em 0 #E9E9E9, inset 0.05em 0 0 #E9E9E9;
  }
  .Cal__Month__row:first-child li {
    -moz-box-shadow: 0 -0.05em  0 #E9E9E9;
    -webkit-box-shadow: 0 -0.05em  0 #E9E9E9;
    -ms-box-shadow:0 -0.05em  0 #E9E9E9;
    -o-box-shadow :0 -0.05em  0 #E9E9E9;
    box-shadow: 0 -0.05em  0 #E9E9E9;
  }
  .Cal__Month__row:nth-child(2) {
    -moz-box-shadow: 0 -0.05em 0 #E9E9E9;
    -webkit-box-shadow: 0 -0.05em  0 #E9E9E9;
    -ms-box-shadow:0 -0.05em  0 #E9E9E9;
    -o-box-shadow :0 -0.05em  0 #E9E9E9;
    box-shadow: 0 -0.05em  0 #E9E9E9;
  }
  .Cal__Container__listWrapper {
    .Cal__MonthList__root,
    .Cal__Day__root.Cal__Day__disabled,
    .Cal__Day__root.Cal__Day__enabled {
      background-color: #0f171f !important;
      font-family: CeraPRO-Regular;
      font-size: 18px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #d9dfe3 !important;
    }
    .Cal__Day__selection {
      color: #131e29;
    }
  }
  .Cal__Day__root.Cal__Day__today.Cal__Day__selected.Cal__Day__enabled {
    border-radius: 50% !important;
  }
  .Cal__Day__root.Cal__Day__selected.Cal__Day__enabled.Cal__Day__betweenRange {
    border-radius: 0% !important;
    .Cal__Day__selection {
      border-radius: 0% !important;
    }

  }
  .Cal__MonthList__root::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.6);
    background-color: #121d28;
  }

  .Cal__MonthList__root::-webkit-scrollbar
  {
    width: 10px;
    background-color: #121d28;
  }

  .Cal__MonthList__root::-webkit-scrollbar-thumb
  {
    background-color: #121d28 ;
    background-image: -webkit-linear-gradient(90deg,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 1) 25%,
            transparent 100%,
            rgba(0, 0, 0, 1) 75%,
            transparent)
  }
  .Cal__Day__range.Cal__Day__selected.Cal__Day__start .Cal__Day__selection {
    border-top-left-radius: 50%!important;
    border-bottom-left-radius: 50%!important;
    border-top-right-radius: 0%!important;
    border-bottom-right-radius: 0%!important;
  }
  .Cal__Day__range.Cal__Day__selected.Cal__Day__end .Cal__Day__selection {
    border-top-right-radius: 50%!important;
    border-bottom-right-radius: 50%!important;
    border-top-left-radius: 0%!important;
    border-bottom-left-radius: 0%!important;
    span {
     color: #ffffff;
    }
  }

  .Cal__Day__root.Cal__Day__enabled.Cal__Day__highlighted:before, .Cal__Day__root.Cal__Day__enabled:active:before, .Cal__Day__root.Cal__Day__enabled:hover:before {
    border-radius: 50% !important;
  }
  .Cal__Day__range.Cal__Day__selected.Cal__Day__start:after, .Cal__Day__range.Cal__Day__selected.Cal__Day__end:after {
    box-shadow: unset;
  }
  .Cal__Day__root.Cal__Day__today:before {
    border-radius: 50% !important;
    background-color: #F0B323;
    box-shadow: unset;
    content: '';
    position: absolute;
    top: 50%;
    margin-left: -50%;
    width: 100%;
    height: 32px;
    margin-top: -16px;
    line-height: 36px;
  }
  .Cal__Day__root.Cal__Day__today:before, .Cal__Day__root.Cal__Day__enabled.Cal__Day__highlighted:before, .Cal__Day__root.Cal__Day__enabled:active:before, .Cal__Day__root.Cal__Day__enabled:hover:before, .Cal__Day__root.Cal__Day__selected .Cal__Day__selection {
    border-radius: 50% !important;
  }
  .Cal__Day__today {
    .Cal__Day__selection {
      border-radius: 50% !important;
    }
  }
  > *:last-child {
    display: flex;
    flex: auto;
    .white-label__search {
      width: 100%;
    }
  }

}
.map-filter__capacity {
  display: flex;
  flex-direction: column;
}
.map-filter__input-number {
  display: flex;
  padding: 10px 20px;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  input[type=text] {
    width: 35px;
    margin: 0 10px;
    height: 20px;
    display: block;
    background-color: #0f171f;
    border: none;
    text-align: center;
  }
}
.decrease-number {
  display: block;
  cursor: pointer;
  width: 20px;
  height: 20px;
  background: url('../../assets/svg/minus-circle-white.svg') no-repeat 50% 50%;
  &--disabled {
    background: url('../../assets/svg/minus-circle.svg') no-repeat 50% 50%;
  }
}
.increase-number {
  display: block;
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-image: url('../../assets/svg/plus-circle.svg');
}
.expanded.dropdown__selector.map-filter__dropdown {
  background-color: #0f171f;
  border: unset;
  .container {
    height: auto;
  }
}
.search-filter {
  display: flex;
  border-radius: 3px;
  background-color: #1f2b35;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  min-width: 115px;
  max-width: 200px;
  padding-right: 10px;
  border-bottom-left-radius: unset;
  border-top-left-radius: unset;
  .button-reset {
    background-color: #1f2b35;
    font-family: CeraPro-Bold;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: normal;
    text-align: center;
    color: #f0b323;
    width: fit-content;
    margin: auto 0;
  }
  .disabled.button-apply {
    pointer-events: none;
    opacity: 0.3;
  }
  .button-apply {
    border-radius: 3px;
    background-color: #f0b323;
    margin:0;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 52px;
    height: 52px;
  }
}

@media (max-width: 1920px) {
  .filters {
    margin-bottom: 30px;
  }
}
@media (max-width: 1600px) {
  .map-filter__container-dropdown .map-filter__dropdown {
    min-width: 170px;
  }
  .map-filter__container-dropdown .infinite-calendar {
    width: calc(100% + 60px);
  }
  .dropdown__selector.map-filter__dropdown.datepicker {
    min-width: 230px;
  }
  .dropdown__selector .map-filter__dropdown .datepicker {
    min-width: 230px;
  }
  .search-filter {
    padding-right: 10px;
  }
  .map-filter__container-dropdown .dropdown-input-field .dropdown-field-title {
    width: calc(100% + 1px);
  }
  .datepicker .dropdown-input-field::after {
    background-image: unset;
  }
  .map-filter__container-dropdown  .dropdown-input-field:after {
    height: 60px;
    right: 10px;
  }
}
@media(max-width:1500px) {
  .map-filter__container-dropdown {
    .dropdown-input-field {
      padding: 20px 10px 10px;
    }
    .map-filter__dropdown.map-filter__floor {
      max-width: 160px;
      min-width: 160px;
    }
    .map-filter__dropdown.map-filter__type {
      max-width: 170px;
      min-width: 170px;
    }
  }
}
 @media(max-width:1340px) {
  .map-filter__container-dropdown {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 60px;
    column-gap: 10px;
    row-gap: 1em;
  }
   .search-filter {
     padding: 20px;
     height: 73px;
   }
   .map-filter__container-dropdown .infinite-calendar {
     width: calc(100% + 1px);
   }

}
@media (min-width: 1341px) and (max-width: 1600px) {
  .map-filter__container-dropdown .infinite-calendar {
    width: calc(100% + 1px);
  }
}
@media (min-width: 1341px) and (max-width: 1420px) {
  .dropdown__selector.map-filter__dropdown.filter__date--no-show {
    display: none;
  }
}