.payment-profile__container {
  .account-types__profiles {
    display: flex;
    flex-direction: column;
  }
  .text-field__content{
    margin-top: 15px;
  }
  .select-account-type {
    margin-bottom: 20px;
  }
  .payment-method_cc {
    margin-bottom: 0;
  }
  .account-type-buttons {
    margin-top: 10px;
  }
  .account-type-buttons--margin {
    margin-top: 16px;
  }
  .payment-info-label {
    margin-bottom: 10px;
  }
  .card-errors-container {
    color: $color-red;
    padding-top: 5px;
  }
}
.account-type__title {
  font-family: $font-family-cera-medium;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 8px;
  letter-spacing: normal;
  margin-top: 30px;
  color: #7c878e;
  text-transform: uppercase;
}
.payment-profile__button {
  font-family: $font-family-cera-bold;
  font-size: 14px;
  border-radius: 3px;
  background-color: $color-yellow;
  color: $color-black;
  text-transform: uppercase;
  padding: 15px 20px;
  cursor: pointer;
  text-align: center;
  max-width: 200px;
  &--disabled {
    background-color: $color-grey-medium;
    cursor: not-allowed;
  }
  &.secondary {
    background-color: unset;
    color: $color-yellow;
    padding: 15px 23px 15px 0;
  }
}

.card-checkbox
{
  margin-top: 30px;
  & > label {
    white-space: nowrap;
    margin-bottom: 20px;
    width: 100%;

    input[type=checkbox] {
      position: absolute;
      opacity: 0;

      & + span {
        position: relative;
        cursor: pointer;
        padding-left: 28px;
        padding-top: 2px;
        display: flex;
        font-family: $font-family-cera-regular;
        font-size: 16px;
        color: $color-grey-light;
      }

      & + span:before, & + span:after{
        display: block;
        content: '';
        width: 20px;
        height: 20px;
        border-radius: 3px;
        background-image: linear-gradient(to bottom, $white1, $yellow8);
        border: solid 1px $gray3;
        box-sizing: border-box;
        position: absolute;
        z-index: 6;
        left: 0;
        top: 0;
      }

      & + span:after {
        z-index: 1;
      }

      &:hover + span:before {
        background: rgba($green3, .5);
      }

      &:checked + span:before {
        background: url('./../../../assets/svg/check.svg') no-repeat center center #d3ffdd;
        border: solid 1px #93e4a5;
      }

      &:checked + span.disabled:before {
        background: url('./../../../assets/svg/check-gray.svg') no-repeat center center #7A878D;
        border: solid 1px #7A878D;
      }

      & + span.disabled {
        //color: #7A878D;
        color: $white1;
        &:before{
          background: #7A878D;
          border: solid 1px #7A878D;
        }
      }
    }
  }

  & > span {
    user-select: none;
    margin-left: 10px;
  }

  input[type=checkbox]:checked ~ span {
    color: $white1;
    font-weight: 300;
  }
}
.payment-profile__loader{
  height: 40px;
  background-image: url('../../../assets/images/loader.gif');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 200px;
  margin: 20px auto;
}