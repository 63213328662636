@import '../../../theme/colors';
@import '../../../theme/layout';
.profileBox-image.imageSuggestion{
  width: 120px;
  height: 120px;
  min-width: 120px;
  background-size: cover;

}

.profileBox {
  position: relative;
  overflow: hidden;
  .sameRow{
    white-space: normal;
    line-height: 1.5;
    span.profileBox-infoSmall {
      white-space: pre-line;
    }
  }

  &-info > div.profileBox-infoSmall > span.profileBox-infoSmall{
    display: inline!important;
    line-height: 1.5;
    white-space: pre-wrap;
  }

  &-container {
    display: flex;
    text-decoration: none;
  }

  &-image {
    width: 90px;
    min-width: 90px;
    height: 90px;
    border-radius: 3px;

    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &-info {
    padding: {
      left: 27px;
      right: 0px;

    }
    > .profileBox-infoSmall {
      line-height: 1.5;

    }
    @media screen and (max-width: $largeDevices) {
      padding: 0 15px;
    }

    .active-subscription{
      font-size: 24px;
      line-height: 32px;
      color: $green1;
    }

    .spending-limit{
      margin-top: 30px;
      .label{
        color: $gray2;
        font-size: 13px;
        font-weight: bold;
        line-height: 20px;
      }
      .values{
        line-height: 30px;
        .spent{
          font-size: 24px;
        }
        color: $gray2;
        font-size: 24px;
      }
    }
  }

  &-name {
    vertical-align: top;
    margin: 0px 0 5px;
    &.items-style1 {
      font-weight: 500;

      @media screen and (max-width: $largeDevices) {
        font-size: 18px;
      }
    }
  }

  &-firstName, &-lastName {
    font-size: 24px;
    font-family: 'CeraPRO-Medium';
    font-weight: 400;
  }

  &-lastName {
    font-weight: 500;
    text-transform: uppercase;
  }

  &-infoSmall {
    margin-bottom: 8px;
    color: $gray1;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &-match {
    text-transform: uppercase;
    margin-top: 5px;
    font-size: 14px;
    color: $yellow9;

    &.--red {
      color: $red1;
    }

    &.--green {
      color: $green1;
    }
  }

  &-invitation-sent {
    margin-top: 5px;
    font-size: 16px;
    color: $yellow9;

    &.--red {
      color: $red1;
    }

    &.--green {
      color: $green1;
    }
  }

  &-link {
    text-decoration: none;
  }

  &-grade {
    font-size: 12px;
    color: $gray1;
    vertical-align: text-top;
    transform: translateY(10px);
    display: inline-block;

    &:before {
      content: '•';
      margin: 0 5px;
    }

    @media screen and (max-width: $largeDevices) {
      transform: translateY(5px);
    }
  }

  &-action {
    width: 50px;
    height: 50px;
    background: url('./../../../assets/svg/connections/messageIcon.svg') no-repeat center center $yellow1;
    border-radius: 50%;
    box-shadow: 0 5px 15px 0 $black5;
    position: absolute;
    right: 35px;
    top: 50%;
    transform: translateY(-25px);
    display: none;

    &:hover {
      background-color: $yellow2;
      cursor: pointer;
    }

    &:active {
      transform: translateY(-27px);
    }

    @media screen and (max-width: $largeDevices) {
      right: 10px;
    }
  }

  &-addConnection {
    background: url('./../../../assets/svg/connections/add-connection.svg') no-repeat center center $yellow1;
  }

  &:hover {
    .profileBox-action {
      display: block;
    }
  }

  &.--wide {
    text-align: center;
    .profileBox {
      &-container {
        display: block;
      }

      &-image {
        margin: 55px auto 0;
        border-radius: 50%;
      }

      &-name {
        font-size: 36px;
        text-align: center;
        margin-top: 16px;
      }

      &-infoSmall, &-match {
        text-align: center;
      }

      &-match {
        margin-top: 25px;
      }

      &-action {
        box-sizing: border-box;
        height: 40px;
        position: static;
        width: auto;
        display: inline-block;
        margin: 17px auto 0;
        border-radius: 4px;
        background-position: 20px center;
        background-size: auto 26px;
        text-transform: uppercase;
        padding: {
          left: 64px;
          right: 20px;
        }
        transform: translateY(0);

        &:hover {
          background-color: $yellow2;
        }

        &:active {
          transform: translateY(-2px);
        }

        &:after {
          content: attr(title);
          line-height: 40px;
          font-size: 14px;
          color: $blue1;
          vertical-align: middle;
          font-weight: bold;
        }
      }
    }
  }

 &.--mapInfo {
    overflow: visible;
    .profileBox {
      &-info {
        width: 196px;
        box-sizing: border-box;
        padding: {
          left: 19px;
          right: 19px;
        }
      }

      &-image {
        width: 118px;
        height: 110px;
      }

      &-name {
        margin-top: 18px;
        margin-bottom: 4px;
      }

      &-name, &-lastName {
        font-size: 14px;
      }

      &-infoSmall, &-match {
        font-size: 12px;
        margin-bottom: 0;
      }

      &-match {
        margin-top: 11px;
      }

      &-action {
        display: block;
        position: absolute;
        right: 20px;
        bottom: -20px;
        top: auto;

        width: 40px;
        height: 40px;

        background-size: 28px auto;
        transform: none;
        cursor: pointer;

        &:active {
          transform: translateY(-2px);
        }
      }
    }
  }

  &.--vertical {
    .profileBox {
      //&-container {
      //  @media screen and (max-width: $connectionsBreakpoint) {
      //    display: block;
      //    text-align: center;
      //  }
      //}

      &-image {
        border-radius: 50%;

        //@media screen and (max-width: $connectionsBreakpoint) {
        //  display: block;
        //  text-align: center;
        //  margin: {
        //    left: auto;
        //    right: auto;
        //  }
        //}
      }

      //&-info {
      //  @media screen and (max-width: $connectionsBreakpoint) {
      //    padding: 0;
      //  }
      //}
      //
      //&-name {
      //  @media screen and (max-width: $connectionsBreakpoint) {
      //    font-size: 18px;
      //  }
      //}

      //&-match {
      //  @media screen and (max-width: $connectionsBreakpoint) {
      //    margin-top: 0;
      //  }
      //}

      //&-grade {
      //  @media screen and (max-width: $connectionsBreakpoint) {
      //    transform: translateY(5px);
      //  }
      //}
    }
  }
}
