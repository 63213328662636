@import 'modalDialogCenter';
@import 'modalDialogLeft';
@import '../../../theme/colors';

  .ReactModal__Overlay {
    z-index: 100;
    background-color: rgba(19, 30, 41, 0.9) !important;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }

.modal-dialog {
  .close {
    display: block;
    background: url('../../../assets/svg/modalDialog/close-icon.svg') no-repeat 50% 50%;
    height: 11px;
    width: 11px;
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
  }
}

.edit-overlay-loading{
  display: inline-block;
  position: absolute;
  width: calc(100% - 47px);
  height: 100%;
  background-color: #131f29;
  opacity: 0.9;
  z-index: 100;
  top: 0;
  background-image: url('./../../../assets/images/loader.gif');
  background-position: center center;
  background-repeat: no-repeat;
}

.new-post-loading{
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #131f29;
  opacity: 0.9;
  z-index: 100;
  top: 0;
  left: 0;
  background-image: url('./../../../assets/images/loader.gif');
  background-position: center center;
  background-repeat: no-repeat;
}

.modal-dialog-content{
  .main-button{
    color: $white1;
    font-family: 'CeraPRO-Medium';
  }  
}

.incomplete-profile-modal{
  .modal-dialog-content{
    padding: 0 20px 20px 20px;
    &:before{
      display: none;
    }
    .dialog-center-confirmation{
      h3{
        padding-top: 50px;
        padding-bottom: 20px;
      }
      .dialog-center-confirmation-body{
        padding-bottom: 50px;
      }
    }
  }
}