.vertical-selector__container {
  background-color: #1f2b35;
  display: flex;
  flex-direction: row;
  height: 40px;
  align-self: center;
  flex-grow: 1;
  margin-right: 30px;
  width: 100%;
}

.vertical-selector__label-container {
  box-sizing: border-box;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 30px;
  width: 40%;
}

.vertical-selector__label {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  font-family: 'CeraPRO-Bold';
  color: #7c878e;
  text-transform: uppercase;
}

.vertical-selector__picker-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #121d28;
  position: relative;
  width: 60%;
}

.vertical-selector__picker {
  border:0;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #f0b323;
  outline: none;
  cursor: pointer;
  background-color: #121d28;
  padding-left: 20px;
  font-family: 'CeraPRO-Regular';
  width: 100%;

  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.vertical-selector__picker-arrow {
  position: absolute;
  right: 20px;
  top: 17px;
  cursor: pointer;
}

@media screen and (max-width:1580px) {
  .vertical-selector__label-container {
    width: 40%;
  }
}

@media screen and (max-width:1360px) {
  .vertical-selector__label-container {
    padding-left: 22px;

  }
}