.community-audience__heroes-list {
  display: inline-block;
  margin-top: 10px;
}
.community-modals .base-modal__modal-content .base-modal__right-area .base-modal__modal-body div .community-audience__heroes-more {
  color: $color-yellow-light;
  cursor: pointer;
}
.community-audience__hero-item, .community-audience__heroes-more {
  font-family: $font-family-cera-medium;
  font-size: $font-size-default;
  display: inline-flex;
  float: left;
  padding: 7px 12px;
  margin: 0 10px 10px 0;
}
.community-audience__hero-item {
  border: 1px solid $color-grey;
  border-radius: 2px;
  text-transform: uppercase;
  color: $color-grey;
  img {
    height: 14px;
    width: 14px;
    margin-left: 7px;
  }
}
.community-audience__remove-hero {
  margin-left: 5px;
  cursor: pointer;
}
.community-audience__heroes-more {
  padding-left: 0px;
}