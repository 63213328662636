.dashboard-button {
  outline: 0;
  border: 0;
  padding: 0;
  background: none;
  height: $dashboard-button-height;
  font-size: $font-size-default;
  font-family: $font-family-cera-regular;
  letter-spacing: normal;
  color: $color-yellow;
  transition: transform 0.08s;
  &:hover {
    color: $color-yellow-light;
  }
  &:active {
    transform: translateY(2px);
  }
  &.dashboard-button--border {
    font-family: $font-family-cera-bold;
    text-transform: uppercase;
    @include default-border(2px, $color-yellow, $dashboard-button-border-radius);
  }
  &:hover.dashboard-button--border {
    border-color: $color-yellow-light;
  }
  &.dashboard-button--secondary {
    font-family: $font-family-cera-bold;
    text-transform: uppercase;
    color: $color-grey-white;
    padding: 0 14px;
    margin-top: 0;
    width: auto;
    @include default-border($dashboard-border-size, $color-grey-medium-alt, $dashboard-button-border-radius);
  }
}

@media (max-width: 1360px) {
  .dashboard-button {
    height: $dashboard-button-height-small;
    font-size: $font-size-default-small;
    &.dashboard-button--secondary {
      width: 58px;
      height: 38px;
      padding: 10px;
      font-size: $font-size-default-small;
    }
  }
  
}
