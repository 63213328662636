.infocard__button {
  margin-bottom: 30px;
}
.infocard__button-disabled {
  background-color: $color-grey-medium;
  border: 2px solid $color-grey-medium;
  cursor: not-allowed !important;
}

.infocard__generic {
  margin-bottom: 25px;
}

.infocard__already-applied {
  @include text-typography($font-family-cera-medium, 21px);
  line-height: 1.33;
  color:$color-grey-white;
  margin-bottom: 33px;
}

.generic__title {
  @include text-typography($font-family-cera-regular, 14px);
  line-height: 0.71;
  color: $color-grey-white;
  margin-bottom: 10px;
}

.tags__title {
  @include text-typography($font-family-cera-regular, 14px);
  line-height: 0.71;
  color: $color-grey-white;
  margin-bottom: 20px;
}

.generic__data {
  @include text-typography($font-family-cera-medium, 16px);
  line-height: 1.38;
  color: $color-grey-white;
}

.tags__wrapper {
  margin-bottom: 20px;
}

.data__container:last-of-type {
  margin-bottom: -20px;
}