@import '../../../theme/colors';

.showVoucherBalance.showConnections {
    min-width: 300px;
}
.showVoucherBalance-message {
    margin-top: 40px;
    margin-bottom: 10px;
}
.showVoucherBalance-description {
    color: $gray1;
    display: inline;
    font-size: 16px;
    line-height: 1.4;
}
.showVoucherBalance-price {
    color: $yellow1;
    display: inline;
    font-size: 15px;
    font-weight: bold;
}