.community-page__back-to-button {
  margin-bottom: 15px;
}

.community-header__image {
  width: 120px;
  height: 120px;
  object-fit: contain;
}
.community-header {
  display: flex;
}
.community-header__title {
  font-family: $font-family-cera-regular;
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: $color-grey-white;
}
.community-header__subtitle {
  font-family: $font-family-cera-regular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: $color-grey;
}
.community-header__details {
  padding: 30px 0 30px 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  span {
    font-family: $font-family-cera-regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: $color-grey;
  }
}
.community_header-description {
  margin-top: 15px;
  .community-card__svg {
    vertical-align: middle;
    margin-right: 6px;
  }
}

.dot-between__footer {
  padding-right: 5px;
  padding-left: 5px;
}

.community-start-conversation {
  display:flex;
  flex-direction: row;
  align-content: center;
  margin-top: 17px;
}
.community-start-conversation__text {
  width: 70%;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  background-color: $color-grey-medium;
  margin-right: 2px;
  display: flex;
  cursor: pointer;
  .community-card__svg {
    padding: 19px 10px 19px 30px;
    vertical-align: middle;
  }
  span {
    font-family: $font-family-cera-medium;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: $color-grey;
    padding: 25px 0;
    position: relative;
    width: 100%;
    &:after {
      content: '';
      width: 100%;
      position: absolute;
      display: flex;
      margin-top: 10px;
      border: 1px solid $color-grey;
      opacity: 0.3;

    }
  }
}
.community-start-conversation__video,
.community-start-conversation__photo,
.community-start-conversation__file {
  width: 10%;
  background-color: $color-grey-medium;
  margin-right: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  .community-card__svg {
    padding-right: 0;
    margin: auto;
  }
}
.community-start-conversation__file {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}

.community-post__comments-container {
  border-top:1px solid #131e29;
  padding:15px 0px;
}

