.button-yellow {
  @include text-typography($font-family-cera-bold);
  border-radius: 3px;
  background-color: $color-yellow;
  height: 50px;
  padding: 15px;
  color: $color-black;
  border: solid 2px $color-yellow;
  text-transform: uppercase;
  width: 100%;
}

.button__text-link {
  @include text-typography($font-family-cera-regular);
  color: $color-yellow;
  background-color: unset;
  padding: 0 0 0 5px;
  width: auto;
  height: auto;
  display: inline-block;
  margin-top: 0;
}

.button__text-link:hover,
.button__text-link:active {
  color: $color-yellow-light;
}

// entity-button is a yellow link button
// entity-button used with entity-button--border is a yellow bordered button
// entity-button used with entity-button--yellow-button--border is a yellow button

.entity-button {
  outline: 0;
  border: 0;
  padding: 0;
  background: none;
  height: $entity-button-height;
  font-size: $font-size-default;
  font-family: $font-family-cera-regular;
  letter-spacing: normal;
  color: $color-yellow;
  transition: transform 0.08s;
  &:hover {
    color: $color-yellow-light;
  }
  &:active {
    transform: translateY(2px);
  }
  &.entity-button--border {
    font-family: $font-family-cera-bold;
    text-transform: uppercase;
    @include default-border(2px, $color-yellow, $entity-button-border-radius);
  }
  &:hover.entity-button--border {
    border-color: $color-yellow-light;
  }
  &.entity-button--secondary {
    font-family: $font-family-cera-bold;
    text-transform: uppercase;
    color: $color-grey-white;
    padding: 0 14px;
    margin-top: 0;
    width: auto;
    @include default-border($entity-border-size, $color-grey-medium-alt, $entity-button-border-radius);
  }

  &.entity-button--yellow {
    @include text-typography($font-family-cera-bold);
    border-radius: 3px;
    background-color: $color-yellow;
    height: 50px;
    padding: 15px;
    color: $color-black;
    border: solid 2px $color-yellow;
    text-transform: uppercase;
    width: 100%;
    &:hover {
      border-color: $color-yellow-hover;
      background-color: $color-yellow-hover;
    }
    &:active {
      transform: translateY(2px);
    }
  }
}

@media (max-width: 1360px) {
  .entity-button {
    height: $entity-button-height-small;
    font-size: $font-size-default-small;
    &.entity-button--secondary {
      width: 58px;
      height: 38px;
      padding: 10px;
      font-size: $font-size-default-small;
    }
  }

}

.navigation__back-to-button {
  @include text-typography($font-family-cera-medium, 14px);
  color: #7c878e;
  background-color: unset;
  width: auto;
  text-transform: uppercase;
  padding: 0;

  &::before {
    content: '';
    position: relative;
    display: inline-block;
    width: 14px;
    height: 14px;
    top: 3px;
    left: 0;
    background-repeat: no-repeat;
    background-image: url('../../assets/svg/myBookings/angle-left.svg');
  }
}

.navigation__back-to-button--margin-left {
  margin-left: 30px;
}