.sso-errors {
  max-width: 550px;

  .bookedOffice-popup-body {
    font-family: CeraPRO-Regular;
    line-height: normal;
    letter-spacing: normal;
    color: #7c878e;
    display: block;
  }

  .modal-close-button{
    background: url('../../../assets/svg/accountProfile/close-icon.svg') no-repeat;
    height: 25px;
    width: 25px;
    float: right;
    margin-right: -10px;
    &:hover{
      cursor: pointer;
    }
  }

  .main-button {
    width: 100px;
  }
  .actions {
    text-align: center;
  }
}