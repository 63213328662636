@import '../../../theme/colors';
@import '../../../theme/layout';

.connections {
  display: flex;
  height: 100%;
  position: relative;

  &-navigation {
    cursor: pointer;
    line-height: 14px;
    color: #7c878e !important;
    text-transform: uppercase;
    text-decoration: none;
    padding-left: 14px;
    font-size: 14px;
    font-weight: bold;
    position: relative;
    top: 40px;
    &:before {
      content: '';
      position: absolute;
      width: 14px;
      height: 14px;
      top: 0;
      left: 2px;
      background-repeat: no-repeat;
      background-image: url('./../../../assets/svg/myBookings/angle-left.svg'); 
    }
  }

  &-container {
    flex: 3;
  }
  &-recommended{
    background-color: $yellow1;
    flex: 3;
    .pageHeader-titleText{
      color:$blue1 !important;
    }
  }
  &-mainContent {
    padding: 0 29px;
    height: 100%;
    .profileBox-name.items-style1 {
      margin: 3px 0 1px;
    }
  }

  &-items {
    display: flex;
    flex-wrap: wrap;

    &.--map-view {
      height: calc(100% - 110px);
    }
  }

  &-item {
    width: 25%;
    min-width: 385px;
    padding-right: 35px;
    box-sizing: border-box;
    margin-bottom: 58px;

    &.--vertical {
      width: 100%;
      margin-bottom: 25px;
      padding-right: 0;

      .profileBox-action {
        right: 0;
      }

      @media screen and (max-width: $connectionsBreakpoint) {
        margin-bottom: 55px;
      }
    }

    &.--force-empty {
      .profileBox-infoSmall {
        min-height: 16px;
      }
    }

    &.--recommended{
      width: 25%;
      .profileBox-firstName{
        color:$blue1 !important;
      }
      .profileBox-lastName{
        color:$blue1 !important;
      }
      .profileBox-addConnection{
        background: url('./../../../assets/svg/connections/add-connection-suggestions.svg') no-repeat center center $blue1;
      }
    }

    @media screen and (max-width: $connectionsBreakpoint) {
      width: 50%;
      margin-bottom: 20px;
    }
  }

  &-sidebar {
    padding: 0 30px;
    margin-top: 60px;

    @media screen and (max-width: $largeDevices) {
      padding: 0 15px;
    }
    .connections-sidebarTitle {
      //margin-bottom: 28px;
      font-weight: bold;}
  }

  &-sidebarSection {
    margin-top: 48px;

    .connections-sidebarTitle {
      margin-bottom: 25px;
      font-weight: bold;

      @media screen and (max-width: $largeDevices) {
        margin-bottom: 10px;
      }

      &.--secondary {
        //@media screen and (max-width: $connectionsBreakpoint) {
        //  text-align: center;
        //}
      }
    }

    @media screen and (max-width: $largeDevices) {
      margin-top: 20px;
    }
  }

  &-inviteButton {
    width: 100%;
    box-sizing: border-box;
    text-transform: uppercase;
    background: url('./../../../assets/svg/connections/add-connection.svg') no-repeat  $yellow1;
    background-position: 30px center;
    padding-left: 50px;
  }
  &-connectButton {
    background-color:$blue1 !important;
    color:$yellow1 !important;
    width: auto;
    box-sizing: border-box;
    text-transform: uppercase;

    padding-left: 50px;
  }
  &-sidebarTitle {
    font-size: 14px;
    color: $gray1;
    text-transform: uppercase;
    line-height: 21px;
  }

  &-newConnections {
    float: right;
    display: inline-block;

    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'CeraPRO-Medium';

    padding: 4px 10px;
    line-height: 1;
    border-radius: 10px;

    color: $blue1;
    background-color: $yellow2;

    cursor: pointer;

    &:hover {
      background-color: $yellow1;
    }

    &:active {
      transform: translateY(-2px);
    }

    @media screen and (max-width: $largeDevices) {
      margin-top: 5px;
    }
  }

  &-no {
    font-size: 36px;
    margin-bottom: 28px;

    @media screen and (max-width: $largeDevices) {
      font-size: 24px;
    }
  }

  &-filterbar {
    margin-bottom: 24px;
  }
  .fullWidth{
    width: 100%;
    text-align: center;
  }

  .loadMore{
    border-radius: 3px;
    background-color: #131e29;
    display: inline-block;
    position: relative;
    margin: 0 auto;
    width: initial;
    padding: 0 15px;
    font-family: 'CeraPRO-Medium';
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #f0b323;
    cursor: pointer;
    margin-bottom: 55px;
    min-height: 40px;
  }
}

.deselectAll{
  display: block;
  padding: 20px 0 0px 0;
  &:hover{
    cursor: pointer;
  }
}
.connections-connectButton.main-button {
  margin-top: 13px;
  font-family: 'CeraPRO-Medium';
}
.connections-inviteButton.main-button {
  font-family: 'CeraPRO-Medium';
  font-weight: normal;
}

.pageHeader-itemsDisplay-loadMoreButton {
  max-width: 500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.connections-goTopButton {
  background-color: #131e29;
  position: relative;
  margin: 0 auto;
  width: 50px;
  height: 50px;
  font-family: 'CeraPRO-Medium';
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 55px;
  min-height: 40px;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  line-height: 50px;
  padding: 3px 17px;
  user-select: none;
  transform: rotate(-90deg);
  i {
    color: #f0b323;
  }
}

.pageHeader-itemsDisplay-goTopButton {
  position: absolute;
  top: 0;
  left: 0;
  text-align: left;
  flex: 1;
  vertical-align: middle;
  line-height: 36px;
}

.pageHeader-itemsDisplay-bottomButtons {
  position: relative;
}

.pageHeader-itemsDisplay {
  display: flex;
  justify-content: flex-end;

  .deselect-button {
    color: #131e29 !important;
    background: #f0b323 !important;
    border: 2px solid #131e29;
    margin-right: 10px;
  }

  .deselect-button.disabled {
    cursor: not-allowed;
    background-color: rgba(255, 255, 255, 0.3) !important;
    border: 0;
    color: #f0b323 !important;
  }
  .connect-button {
    line-height: 54px;
  }
}