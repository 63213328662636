%input-base {
    align-content: center;
    background-image: linear-gradient(to top, $gray6, $gray7);
    border: 1px solid transparent;
    border-top-color: $gray7;
    border-bottom-color: $gray6;
    border-radius: 3px;
    box-sizing: border-box;
    color: $gray8;
    font-size: 16px;
    font-weight: 500;
    height: 50px;
    line-height: 1.56;
    outline: none;
    padding: 10px 20px;
    resize: none;

    &.active, &:focus {
        background-image: linear-gradient(to top, $yellow4, $yellow5);
        border: 1px solid $yellow12;
        color: $blue1;
        font-size: 18px;
        font-weight: normal;
    }
}

%comment-input {
    @extend %input-base;

    display: flex;
    flex-grow: 1;
}

%rating {
    @extend %input-base;

    unicode-bidi: bidi-override;
    direction: rtl;

    &:hover {
        background-image: linear-gradient(to top, $yellow4, $yellow5);
        border: 1px solid $yellow12;
        color: $blue1;
        font-size: 18px;
        font-weight: normal;
    }

    .star {
        color: $blue1;
        cursor: pointer;
        font-size: 19px;
        height: 100%;

        &:hover, &.active {
            color: $yellow1;

            & ~ * {
                color: $yellow1;
            }
        }
    }
}

%modal-input {
    border-radius: 3px;
    background-image: linear-gradient(to top, $white1, $yellow8);
    border: 1px solid $gray3;
    color: $gray1;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    resize: none;

    &:hover, &:focus {
        background-image: linear-gradient(to top, $yellow4, $yellow5);
        border: 1px solid $yellow12;
    }
}

.label-optional {
    font-family: 'CeraPRO-Regular';
    font-size: 14px;
    font-style: italic;
    text-transform: capitalize;
    color: #aab4bb;
}
