.dashboard-loader__container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  .dashboard-loader__image {
    height: 18px;
    width: auto;
  }
}

.dashboard-loader__container--with-border {
  @include default-border($dashboard-border-size, $color-grey-medium-alt, 3px);
  min-height: $loader-min-height;
  margin-bottom: $dashboard-spacing;
}

.dashboard-loader-content {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  &.dashboard-loader-content--hidden {
    display: none;
    opacity: 0;
  }
}
