@import '../../../theme/colors';

.search {
  width: 100%;
  position: relative;
  height: 40px;

  &-disabled {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    top: 0;
    left: 0;
  }

  &.search-typed {
    &:before {
      background: url('../../../assets/svg/header/icon-search-yellow.svg') no-repeat 50% 50%;
    }

    .search-input {
      border: 1px solid $yellow3;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background-image: linear-gradient(to top, $yellow4, $yellow5);
      padding: 0 54px;
      color: $blue1;

      pointer-events: none;
    }

    .search-results {
      display: block;
      height: 200px;
    }
  }

  &:hover {
    //.search-input {
    //  border: 1px solid $yellow3;
    //  padding: 0 54px;
    //}
    //
    //&:before {
    //  background: url('../../../assets/svg/header/icon-search-yellow.svg') no-repeat 50% 50%;
    //}
  }

  &:before {
    content: '';
    display: block;
    width: 25px;
    height: 25px;
    background: url('../../../assets/svg/header/icon-search.svg') no-repeat 50% 50%;
    position: absolute;
    left: 20px;
    top: 9px;
  }

  //&:after {
  //  content: '';
  //  display: block;
  //  width: 22px;
  //  height: 16px;
  //  background: url('../../../assets/svg/header/arrow.svg') no-repeat 50% 50%;
  //  position: absolute;
  //  right: 22px;
  //  top: 17px;
  //}

  .search-input {
    border-radius: 3px;
    background-image: linear-gradient(to top, $gray6, $gray7);
    background-color: transparent;
    width: 100%;
    height: 40px;
    border: none;
    font-size: 16px;
    line-height: 16px;
    color: $gray8;
    padding: 1px 20px 0 55px;
    text-overflow: ellipsis;

    &::-ms-clear {
      display: none;
    }

    &::placeholder {
      color: $gray8;
    }

    &:focus {
      outline: none;
    }
  }

  .search-results {
    background-color: $white1;
    border: 1px solid $yellow3;
    border-top:0px solid white ;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    display: none;
    left: 0;
    height: auto;
    width: 100%;
    z-index: 10;
    overflow: hidden;

    li {
      background-color: $white1;
      border-bottom: solid 1px $gray3;
      color: $blue1;
      line-height: 28px;
      font-size: 18px;
      padding: 8px 19px 13px 19px;

      &.selectedResult{
        background-color: $gray3;
      }

      &:hover{
        background-color: $gray3;
      }

      &:last-of-type {
        //border: none;
      }
    }
  }
}

@media screen and (max-width:1580px) {
  .search {
    max-width: 600px;
  }
}

@media screen and (max-width:1460px) {
  .search {
    max-width: 404px;
  }
}