.dashboard-media__container {
  display: flex;
  flex-direction: row;
  height: auto;
  color: $color-black;

  .attachment__input {
    display: none;
  }
  .dashboard-loader-content {
    flex: 2 1;
  }
  .dashboard-media__main {
    flex: 2 1;
    margin-right: 30px;

    .dashboard-loader__container{
      margin-top: 200px;
    }
    .dashboard-media__background {
      background-color: $color-white-grey;
    }

    .dashboard-media__wrapper {
      padding: $dashboard-spacing;
      max-width: 980px;

      .media-header {
          @include text-typography($font-family-cera-medium);
          color: $color-black;
          text-transform: uppercase;
          white-space: nowrap;

        .media-header__previous {
          @include text-typography($font-family-cera-bold, 14px);
          color: $color-black;
        }

        .dot_between {
          @include dot-between();
        }

        .media-header__type {
          @include text-typography($font-family-cera-bold, 14px);
          color: $color-grey;
          flex:1;
        }
      }

      .media__title {
        @include text-typography($font-family-cera-medium, 30px);
        line-height: 1.47;
        color: $color-black;
        margin-bottom: 15px;
      }

      .media__date {
        @include text-typography($font-family-cera-regular, 14px);
        font-size: 14px;
        line-height: 0.71;
        color: $color-grey;
        margin-bottom: 35px;
      }

      .media__content {
        @include text-typography($font-family-cera-medium, 16px);
        color: $color-black;

        .dashboard-loader__container{
          margin-bottom: 100px;
          min-height: 574px;
        }

        .media__content-description {
          color: $color-black;
          @include text-typography($font-family-cera-medium, 16px);
          line-height: 1.38;
          margin-bottom: 55px;
        }

        .media__content-generic {
          color: $color-black;
          margin-bottom: 55px;

          h2 {
            @include text-typography($font-family-cera-regular, 21px);
            line-height: 0.67;
            color: $color-black;
            margin-bottom: 23px;
          }

          .media__content-generic-data {
            @include text-typography($font-family-cera-regular, 16px);
            line-height: 1.38;
            color: $color-black;
          }

          .media__content-video-wrapper {
            width: 625px;
            overflow: hidden;
            object-fit: cover;
            height: 470px;
          }

          .media__content-generic-video {
            height: 580px;
            width: 725px;
            margin-top: -55px;
            margin-left: -50px;
          }
          img {
            width: 100%;
            max-width: 625px;
          }
        }

        /*end media content*/
      }

      .media__attachment {
        max-width: 625px;
        width: 100%;
        background-color: $color-white;
        border-radius: $dashboard-card-border-radius;
        margin-bottom: 30px;
        padding: $dashboard-spacing;

        .media__attachment-container {
          margin-bottom: 34px;

          .attachment-title {
            @include text-typography($font-family-cera-regular, 21px);
            line-height: 0.71;
            color: $color-black;
            margin-bottom: 20px;
          }

          .attachment-header {
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            margin-bottom: 24px;
          }

          a {
            @include text-typography($font-family-cera-medium, 16px);
            line-height: 0.64;
            color: $color-black;
          }

          .attachment-count {
            @include text-typography($font-family-cera-regular, 16px);
            font-weight: 900;
            line-height: 0.63;
            color: $color-black;
          }

          .attachment-download {
            @include text-typography($font-family-cera-bold, 14px);
            line-height: 0.64;
            color: $color-yellow;
          }
        }
        /*end media attachment container*/

        .media__link-container {
          max-width: 565px;
          flex: 1;

          h4 {
            @include text-typography($font-family-cera-regular, 21px);
            line-height: 0.71;
            color: $color-black;
            margin-bottom: 15px;
          }

          a {
            @include text-typography($font-family-cera-medium, 16px);
            line-height: 0.75;
            color: $color-black;
            text-decoration: underline;
            flex: 1;
            width: 100%;
            max-width: 565px;
          }

          .link-container {
            flex: 1;
            width: 100%;

            * {
              color: $color-black;
              @include text-typography($font-family-cera-medium, 16px);
              flex: 1;
            }
          }
        }

        h4 {
          @include text-typography($font-family-cera-medium, 21px);
          line-height: 0.71;
          color: $color-black;
        }
      }
      /*end media attachment*/
      .media__form {
        max-width: 625px;
        width: 100%;
        background-color: $color-white;
        border-radius: $dashboard-card-border-radius;
        margin-bottom: 30px;
        padding: $dashboard-spacing;
        color: $color-black;
      }
      .media__footer {
        display: flex;
        flex-direction: row-reverse;
        max-width: 625px;

        .footer__recommend-text {
          @include text-typography($font-family-cera-regular, 14px);
          line-height: 0.71;
          color: $color-grey;
          margin-left: 21px;
        }
        .footer__recommend-no,
        .footer__recommend-yes {
          @include text-typography($font-family-cera-medium, 14px);
          line-height: 0.71;
          color: $color-grey;
          margin-left: 21px;
          cursor: pointer;
        }
        .footer__recommend-selected {
          color: $color-grey-dark;
        }
        .disabled {
          cursor: initial;
        }
      }

      .media-form__title {
        @include text-typography($font-family-cera-regular, 21px);
        line-height: 1;
        color: $color-black;
        margin-bottom: 35px;

        span {
          @include text-typography($font-family-cera-medium, 21px);
          line-height: 1;
          color: $color-black;
        }
      }

      .media-form__upload {
        display: flex;
        flex-direction: row;

        button {
          max-width: 150px;
          margin: 0;
        }

        .media-form__no-file {
          @include text-typography($font-family-cera-regular, 14px);
          line-height: 0.71;
          color: $color-black;
          margin-top: 13px;
          margin-left: 20px;
        }

        .media-form__right {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
        .media-form__left {
          width: 44px;
        }

        .media-form__cv-title {
          margin-left: 3px;
        }

        .media-form_selected-file {
          flex: 1;
          span {
            @include text-typography($font-family-cera-medium, 16px);
            line-height: 1;
            color: $color-black;
          }
        }

        .media-form__buttons {
          display: flex;
          flex-direction: row;

          .media-form__re-upload-btn {
            border: none;
            padding: 0px;
            width: 82px;
            height: 30px;
          }

          .media-form__delete-btn {
            border: none;
            padding: 0px;
            width: 82px;
            height: 30px;
            color: $color-red-light;
          }
        }
      }

      .media-form__upload-text {
        @include text-typography($font-family-cera-regular, 14px);
        line-height: 0.71;
        color: $color-black;
        margin-bottom: 15px;

        span {
          @include text-typography($font-family-cera-regular, 14px);
          line-height: 0.71;
          color: $color-grey-light;
        }
      }

      .media-form__motivation-wrapper {
        margin-bottom: 36px;
        margin-top: 43px;
        .media-form__motivation {
          @include text-typography($font-family-cera-medium, 16px);
          width: 100%;
          height: 130px;
          border: solid 1px $color-grey-light2;
          border-radius: 3px;
          padding: 10px 20px;
          font-style: normal;
          line-height: 1.5;
          resize: none;
          color: $color-black;
          background-image: linear-gradient(to top, $color-white 80%, $color-white-light-grey 98%);
          caret-color: $color-red;

          &:focus {
            @include text-typography($font-family-cera-medium, 16px);
            font-style: normal;
            width: 100%;
            outline: none;
            background-image: linear-gradient(to top, $color-grey3 1%, $color-yellow-light2 100%);
            border: solid 1px $color-orange;
            border-radius: 3px;
            line-height: 1.5;
            color: $color-black;
          }
          &.form-error {
            background-image: initial;
            border: solid 1px $color-red-light;
            background-color: $color-pink;
          }
        }
      }

      .media-form__video-wrapper{
        margin-bottom: 41px;

        .media-form__video {
          @include text-typography($font-family-cera-medium, 16px);
          width: 100%;
          border: solid 1px $color-grey-light2;
          border-radius: 3px;
          padding: 9px 20px 9px 20px;
          font-style: normal;
          line-height: 1.5;
          color: $color-black;
          background-image: linear-gradient(to top, $color-white 80%, $color-white-light-grey 98%);
          caret-color: $color-red;

          &:focus {
            @include text-typography($font-family-cera-medium, 16px);
            font-style: normal;
            width: 100%;
            outline: none;
            background-image: linear-gradient(to top, $color-grey3 1%, $color-yellow-light2 100%);
            border: solid 1px $color-orange;
            border-radius: 3px;
            line-height: 1.5;
            color: $color-black;
          }

          &.form-error {
            background-image: initial;
            border: solid 1px $color-red-light;
            background-color: $color-pink;
          }
        }
      }

      .media-form__italic-placeholder::-webkit-input-placeholder {
        @include text-typography($font-family-cera-regular, 16px);
        color: $color-grey2;
        font-style:italic;
        line-height: 1.5;
      }

      .media-form__italic-placeholder::-moz-placeholder {
        @include text-typography($font-family-cera-regular, 16px);
        color: $color-grey2;
        font-style:italic;
        line-height: 1.5;
      }
      .media-form__italic-placeholder::placeholder {
        @include text-typography($font-family-cera-regular, 16px);
        color: $color-grey2;
        font-style:italic;
        line-height: 1.5;
      }

      .apply-button-disabled {
        opacity: 0.18;
        cursor: not-allowed;
      }

      .form-error-message {
        @include text-typography($font-family-cera-regular, 10px);
        color: $color-red-light;
        margin-top: 10px;
        //display: none;
      }


    /*end dashboard wrapper*/
    }
  }

  .dashboard-media__sidebar {
    flex: 1 1;

    .dashboard-media__sidebar-wrapper {
      padding: 30px 30px 0 0;

      .media-dashboard__card {
        @include default-border($dashboard-border-size, $color-grey-medium);
        padding: $dashboard-spacing;
        margin-bottom: $dashboard-spacing;

        &.apply-card {
          background-color: $color-grey-dark;
          @include default-border($dashboard-border-size, $color-grey-dark);
        }
      }
      .card__new-company {
        margin-bottom: $dashboard-spacing;
        .new-company__description {
          margin-bottom: 0;
        }
      }

    }
  }
  /*end dashboard media main*/
}

.attachment-link {
  position: relative;
  width: 100%;
  max-width: 162px;
}

.attachment-pdf {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
  border-radius: $dashboard-card-border-radius;
}

.attachment-overlay {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.attachment-link:hover .attachment-pdf {
  opacity: 0.3;
}

.attachment-link:hover .attachment-overlay {
  opacity: 1;
}

.attachment-down-arrow {
  background-color: $color-yellow;
  font-size: 16px;
  padding: 9px 12px;
  border-radius: 3px;
}

.media-form__success-title {
  background-color: $color-yellow;
  max-width: 625px;
  width: 100%;
  border-radius: $dashboard-card-border-radius;
  margin-bottom: $dashboard-spacing;
  padding: $dashboard-spacing;

  h4 {
    @include text-typography($font-family-cera-medium, 21px);
    line-height: 1;
    color: $color-black;
    margin-bottom: 22px;
  }

  .media-form__success-text {
    @include text-typography($font-family-cera-regular, 14px);
    line-height: 1.5;
    color: $color-black;

    span {
      @include text-typography($font-family-cera-medium, 14px);
      color: $color-black;
      line-height: 1.5;
    }
  }
}