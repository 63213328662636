.dropdown__selector {
  width: 100%;
  border-radius: 3px;
  height: 40px;
  background-image: none;
  background-color: $color-black;

  .dropdown-input {
    @include text-typography($font-family-cera-medium, 16px);
    line-height: 40px;
    color: $color-yellow;
    user-select: none;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 20px;
    padding-right: 40px;

    &:after {
      content: '';
      position: absolute;
      right: 20px;
      width: 14px;
      height: 40px;
      transition: transform .2s ease-in-out;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../../../../assets/svg/dropdown-arrow__down-yellow.svg');
    }
  }

  .container {
    display: none;
    box-sizing: border-box;
    height: 162px;
    width: 100%;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2);
    border: solid 2px $color-grey;
    border-top: none;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    position: absolute;
    z-index: 9;

    .option {
      @include text-typography($font-family-cera-medium, 16px);
      height: auto;
      cursor: pointer;
      border-bottom: solid 1px $color-grey-border;
      background-color: $color-black-light;
      font-weight: 500;
      line-height: initial;
      padding: 12px 12px 12px 18px;
      color: $color-grey-white;

      &:hover {
        color: $color-yellow;
        background-color: $color-black;
      }
    }

  }

  .expanded .container {
    display: block;
    padding: 0;
    border: solid 2px $color-grey;
    background-color: $color-black-light;
    height: 160px;
  }

}

.expanded .dropdown-input {
  border: solid 2px $color-grey;
  height: 40px;
  line-height: 36px;
  padding-left: 18px;
  padding-right: 18px;
}

.expanded.dropdown__selector .dropdown-input:after {
  height: 36px;
}

.expanded.dropdown__selector .container {
  display: block;
  background: $color-black-light;
  padding: 0;
}

.expanded.dropdown__selector.filter__dropdown .dropdown-input {
  border: solid 2px $color-grey;
  height: 40px;
  line-height: 36px;
  padding-left: 20px;
  padding-right: 20px;
  span {
    color: $color-grey-white;
  }
}

.dropdown__selector.filter__dropdown .dropdown-input:after {
  background-image: url('../../../../assets/svg/dropdown-arrow__down-grey.svg');
  right: 16px;
}

.expanded.dropdown__selector.filter__dropdown .dropdown-input:after {
  height: 40px;
  right: 16px;
}

.dropdown__selector.filter__dropdown {
  .dropdown-input {
    border: 2px solid $color-grey-medium-alt;
    color:  $color-grey-white;
    @include text-typography($font-family-cera-bold, 14px);
    line-height: 36px;

    span {
      color: $color-grey;
      @include text-typography($font-family-cera-bold, 14px);
      text-transform: uppercase;
    }
  }
}

.vertical__selector {
  .dropdown-input:hover {
    color: $color-yellow-light;
  }
}

.filter__dropdown {
  .dropdown-input:hover {
    border: 2px solid $color-grey;
    & span {
      color: $color-grey-white;
    }
  }
}