@import '../../../theme/colors';

.infoBox{
  overflow: visible !important;
  > img{
    display: none;
  }

  .company-info-pin {
    h2 {
      text-align: center;
      line-height: 50px;
    }
    .markerInfo-title {
      text-align: center;
    }
  }
}

.markerInfo {
  background-color: $blue1;
  position: relative;
  display: block;
  box-shadow: 0 2px 22px 0 $black3;
  &-data {
    width: 300px;
    height: auto;
    padding: 19px;
    box-sizing: border-box;
  }

  &-image {

  }

  &-image-review {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    margin: 15px 0 15px 15px;
  }

  &-title {
    font-family: 'CeraPRO-Regular';
    font-size: 18px;
    line-height: 1;
    margin-bottom: 9px;
  }

  &-info {
    line-height: 1;
    font-size: 12px;
    margin-bottom: 6px;
    color: $gray1;
    span {
      line-height: 1;
      font-size: 12px;
      margin-bottom: 6px;
      color: $yellow1;
    }
  }

  &-close {
    position: absolute;
    cursor: pointer;
    right: 6px;
    top: 0px;
    color: $gray2;

    &:hover {
      color: $white1;
    }
  }

  &--coming-soon {
    display: flex;
    width: 300px;
    .markerInfo-data {
      width: 190px;
      float: left;
      height: 90px;
      padding: 19px;
    }
    .markerInfo-title {
      display: flex;
      font-size: 18px;
    }
  }

  &-link {
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: -20px;
    right: 20px;
    background: url('../../../assets/svg/map/bookmark-link.svg') no-repeat -9px 4px $yellow1;
    border-radius: 50%;
    box-shadow: 0 2px 22px 4px $black4;
    cursor: pointer;

    &:active {
      transform: translateY(-2px);
    }

    &:hover {
      background-color: $yellow2;
    }
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    border: 5px solid transparent;
    border-top-color: $blue1;
    left: 50%;
    bottom: -10px;
    transform: translateX(-5px);
  }
}
