@import '../../../theme/common/variables';
.--homePage .officesGrid-container {
  margin-bottom: 30px;
}
.officesGrid-gridItems {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 22px;
}
.officesGrid-item {
  height: 100%;
}
.officesGrid__categories-container {
  display: flex;
  margin-bottom: 30px;
}
.officesGrid__category {
  font-family: $font-family-cera-bold;
  font-size: 14px;
  text-transform: uppercase;
  color: $color-grey;
  padding-bottom: 10px;
  margin-right: 30px;
  cursor: pointer;
  &--selected {
    color: $color-yellow;
    border-bottom: 3px solid $color-yellow;
  }
}
.officesGrid__filter-container {
  display: flex;
  max-width: 23%;
  margin-bottom: 30px;
  .dropdown__selector .container {
    height: 90px;
  }
  .dropdown-input {
    text-transform: uppercase;
  }
}
.officesGrid__loadMore-button {
  display: flex;
  justify-content: flex-start;
  font-family: $font-family-cera-bold;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  color: $color-yellow;
  margin-bottom: 60px;
}
@media only screen and (max-width : 1600px) {
  .officesGrid-gridItems {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 22px;
  }
  .officesGrid__filter-container {
    max-width: 31%;
  }
}