$thumbSize: 60px;
$iconFacebook: url('../../../assets/svg/officeDetails/facebook.svg');
$iconTwitter: url('../../../assets/svg/officeDetails/twitter.svg');
$iconLinkedin: url('../../../assets/svg/officeDetails/linkedin.svg');
$iconReply: url('../../../assets/svg/officeDetails/reply.svg');
$iconExpand: url('../../../assets/svg/officeDetails/expand-angle.svg');

%shareIcons {
    width: 17px;
    height: 17px;
    display: inline-block;
    margin-left: 4px;
    vertical-align: middle;
}

.office-reviews {
display: none;
  &-title {
    @extend %typography-fieldTitle;

    margin: 0;
    padding-top: 27px;

    text-transform: uppercase;
    text-decoration: none;
    position: relative;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    margin-top: 27px;
  }

  &-user {
    display: flex;
    justify-content: space-between;

    & .thumb {
      width: $thumbSize;
      height: $thumbSize;
      display: inline-block;
      background-size: contain;
      border-radius: 3px;
    }

    .details { 
      margin-left: 20px;
    }

    .details-name {
      @extend %typography-pageTitles;

      margin-top: 5px;
    }

    .details-company {
      @extend %typography-fieldsDescription;

      margin-top: 5px;
    }
  }

  &-info {

    .label {
      @extend %typography-fieldsDescription;
    }

    .share {

      &-facebook {
        @extend %shareIcons;
        background-image: $iconFacebook;
      }

      &-twitter {
        @extend %shareIcons;
        background-image: $iconTwitter;
      }

      &-linkedin {
        @extend %shareIcons;
        background-image: $iconLinkedin;
      }
    }

    .rating {
      margin-left: 20px;
    }
  }

  &-reply {
    @extend %typography-fieldTitle;

    cursor: pointer;
    color: $yellow1;
    text-transform: uppercase;
    text-decoration: none;
    user-select: none;
    position: relative;
    display: inline-block;
    padding-left: 30px;

    &:before {
      content: '';
      position: absolute;
      width: 16px;
      height: 14px;
      left: 10px;
      background-repeat: no-repeat;
      background-position: 50%;
      background-image: $iconReply;
    }
  }

  &-expanded,
  &-collapsed {
    @extend %typography-fieldTitle;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    cursor: pointer;
    color: $yellow1;
    text-transform: uppercase;
    text-decoration: none;
    user-select: none;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 14px;
      height: 14px;
      background-repeat: no-repeat;
      background-position: 50%;
      background-image: $iconExpand;
    }
  }

  &-expanded:after {
    transform: rotate(180deg);
  }

  &-content {
    display: inline-block;
    margin-top: 15px;
  }
}
