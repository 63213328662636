@import '../../../theme/colors';
@import '../../../theme/typography';

.dropdown {
  height: 50px;
  position: relative;
  background-image: linear-gradient(to bottom, $white1, $yellow8);

  .input {
    @extend %typography-ceraGR;
    font-size: 16px;
    color: $gray5;
    font-style: italic;
    line-height: 50px;
    padding-left: 30px;
    position: relative;
    user-select: none;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:after {
      content: '';
      position: absolute;
      right: 20px;
      width: 14px;
      height: 50px;
      transition: transform .2s ease-in-out;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../../../assets/svg/dropdown/angle-down-icon.svg');
    }
  }

  .container {
    display: none;
    box-sizing: border-box;
    height: 150px;
    width: 100%;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px $gray3;
    border-top: solid 1px $green3;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    position: absolute;
    z-index: 9;

    .option {
      height: 50px;
      line-height: 50px;
      padding-left: 30px;
      font-size: 16px;
      color: $blue1;
      background-color: $white1;
      cursor: pointer;
      border-bottom: solid 1px $gray3;
    }
  }

  &.expanded {
    .container {
      display: block;
    }

    .input {
      color: $blue1;

      &:after {
        transform: rotate(180deg);
      }
    }
  }

}