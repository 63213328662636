.manage-community__tabs {
  display: flex;
  padding: 10px 30px 0px 30px;
}
.manage-community__tab {
  font-family: $font-family-cera-bold;
  font-size: $font-size-default;
  background-color: $color-white;
  color: $color-grey;
  width: auto;
  padding: 10px 0px;
  margin: 10px 30px 0px 0px;
  &--active {
    color: $color-black;
    border-bottom: 3px solid $color-yellow;
    padding-bottom: 7px;
  }
}
.manage-community__grey-container {
  background-color: $color-white-light-grey;
  padding: 10px 10px 0px 10px;
  border-top: 1px solid $color-grey-light2;
  .community-form__search-wrapper {
    padding: 20px;
    &::before {
      top: 28px;
      left: 36px;
    }
  }
}
.manage-community__heroes {
  height: 500px;
}
.manage-community__label {
  font-family: $font-family-cera-medium;
  font-size: $font-size-default;
  font-weight: 500;
  color: $color-grey;
  width: auto;
  padding: 10px 0px 10px 20px;
  &--margin-top{
    margin-top: 20px;
  }
  &--regular {
    font-family: $font-family-cera-regular;
  }
}
.manage-community__actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.manage-community__actions--hidden {
  display: none;
  .context-menu {
    background-color: $color-white;
    border: solid 2px $color-yellow;
    padding: 2px 0 0 4px;
    margin: 0px 20px;
    &:hover, &:active {
      border: solid 2px $color-yellow-light;
      .context-menu-dots {
        color: $color-yellow-light;
      }
    }
    .context-menu-dots {
      color: $color-yellow;
    }
  }
}
.manage-community__context-menu--top {
  .context-menu-items {
    margin-top: 0;
    top: -160px;
    &::before, &::after {
      bottom: 0%;
      top: 100%;
    }
    &::before {
      border-width: 12px 12px 0 10px;
    }
    &::after {
      border-width: 10px 10px 0 10px;
    }
  }
}
.manage-community__message-button {
  background-color: unset;
  @include default-border($entity-border-size, $color-grey-medium, $entity-button-border-radius);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-top: 0;
  border: 2px solid $color-yellow;
  color: yellow;
  &:hover {
    border: 2px solid $color-yellow-light;
  }
  .manage-community__message-icon {
    display: block;
  }
  .manage-community__message-icon--hover {
    display: none;
  }
}
.manage-community__cancel-invitation-button {
  font-family: $font-family-cera-bold;
  font-size: $font-size-default;
  color: $color-yellow;
  background-color: unset;
  padding: 0 18px;
  margin: 0;
  max-width: 150px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  &:hover {
    color: $color-yellow-light;
  }
}
.manage-community__reinvite-button {
  margin: 0;
  padding: 0 10px;
}
.manage-community__just-sent-button {
  font-family: $font-family-cera-bold;
  font-size: $font-size-default;
  color: $color-green-active;
  background-color: unset;
  padding: 0 18px;
  margin: 0;
  max-width: 150px;
  min-width: 124px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
.manage-community__hero-info {
  @include text-typography($font-family-cera-regular);
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px 20px;
  &:hover {
    background-color: $color-white;
    cursor: pointer;
    .manage-community__actions--hidden {
      display: flex;
    }
  }
}
.manage-community__profile-picture-container {
  width: 60px;
  min-width: 60px;
  height: 60px;
  overflow: hidden;
  object-fit: contain;
  border-radius:50%;
}
.manage-community__profile-picture {
  width: 100%;
}
.manage-community__name {
  font-size: $font-size-title;
  font-family: $font-family-cera-medium;
  color: $color-black;
  line-height: 30px;
}
.manage-community__headline {
  flex: 1;
  line-height: 13px;
}
.manage-community__headline span {
  font-family: $font-family-cera-medium;
  font-size: $font-size-default;
  color: $color-grey;
}
.manage-community__details {
  width: 100%;
  flex: 1;
  flex-direction: column;
  display: flex;
  margin-left: 20px;
}

.manage-community__hero-info--guest {
  cursor: none;
  .manage-community__headline {
    display: none;
  }
  .manage-community__name {
    padding-top: 15px;
    max-width: 230px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.manage-community__unblock-button {
  margin: 0;
  padding: 0 15px;
}
.manage-community__loading-message {
  font-family: $font-family-cera-regular;
  font-size: $font-size-default;
  color: $color-grey;
  padding: 10px 20px;
}