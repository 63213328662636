.booking-options {
  height: 100%;
  overflow: hidden;
  .booking-options-content {
    background: $yellow1;
    position: relative;
    z-index: 1;
  }
  .office-options-product{
    margin-top: 20px;

    label{
      font-size: 20px;
      line-height: 1!important;
      &:before,
      &:after{
        margin-top: 4px;
      }
    }
    .product-children {
      width: 97.5%;
      margin-top: 10px;
      margin-bottom: 20px;
      padding-left: 20px;
      border-left: 2px solid #ffffff;
    }
  }
    .booking-options-icons{
        justify-content: normal;
        > div{
            width: 25%;
        }
      &.booking-options-icons__whitelabel {
        > div{
          width: 33%;
        }
      }
    }
    .datepicker-container{
        .react-datepicker{
            width: calc(90% - 20px);
            .react-datepicker__month-container {
                width: 49%;
            }
        }
    }
  .datepicker-container {
    width: 100%;
    padding: 10px !important;

    .react-datepicker__month-container {
      //width: calc(50% - 5px);
      .react-datepicker__header{
        //width: 103%;

      }
    }
  }

  &-title {
    @extend %addBooking-title;
    &.desiredHours{

      margin-top: 33px !important;

    }
  }

  &-content {
    @extend %addBooking-content;
  }

  &-scrollable {
    padding: 27px 30px 0;

  }

  &-footer {
    @extend %addBooking-footer;

  }

  &-footer-price {
    line-height: 75px;
    white-space: nowrap;
    display: flex;
    width: 100%;
    justify-content: space-between;

    .footer-price-left {
      width: 70%;
    }

    .footer-price-right {
      font-weight: 500;
      width: 30%;
      text-align: right;
    }
  }

  &-icons {
    height: 115px;
    margin: 0 14px;
    display: flex;
    justify-content: space-between;
  }

  &-details {
    height: 100%;
    padding-bottom: 150px;
  }

  &-finish {
    @extend %addBooking-finishButton;
    font-family: 'CeraPRO-Bold';
    font-size: 14px;
    font-weight: bold;
  }

  .icon-desk,
  .icon-private,
  .icon-event,
  .icon-parking,
  .icon-meeting {
    display: inline-block;
    position: relative;
    font-size: 14px;
    width: 70px;
    height: 20px;
    padding: 85px 10px 0 10px;
    text-align: center;
    vertical-align: top;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 70px;
      top: 0;
      left: 0;
      right: 0;
      z-index: 2;
      background-repeat: no-repeat;
      background-position: center;
    }

    &.selected {
      color: $blue1;
      &:before {
        content: '';
        width: 100%;
        height: 70px;
        top: 0;
        left: 0;
        right: 0;
        position: absolute;
        z-index: 1;
        background-color: $blue1;

        border-radius: 4px;
      }
    }
  }

  .icon-desk:after {
    background-image: url('../../../assets/svg/addBookingSidebar/desk-office-icon.svg');
  }

  .icon-private:after {
    background-image: url('../../../assets/svg/addBookingSidebar/private-office-icon.svg');
  }

  .icon-event:after {
    background-image: url('../../../assets/svg/addBookingSidebar/event-room-icon.svg');
  }

  .icon-meeting:after {
    background-image: url('../../../assets/svg/addBookingSidebar/meeting-room-icon.svg');
  }

  .icon-parking:after {
    background-image: url('../../../assets/svg/addBookingSidebar/parking_icon.svg');
  }

  &-hours {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    & > * {
      width: 180px;

      &:first-child {
        margin-right: 20px;
      }
    }
  }

  &-amenity,
  &-service {
    font-size: 24px;
    position: relative;


    .mention {
      font-size: 14px;
      margin-bottom: 22px;

      //height: 14px;
    }
  }

  &-amenity.free {
    color: $white1;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 14px;
      height: 14px;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url(../../../assets/svg/officeDetails/feature-available.svg);
    }

    .item-label{

    }
  }

  &-service {
    color: $blue2;

    .mention {
      color: $gray18;
    }

    .align-left {
      position: absolute;
      right: 0;
    }
  }
  .booking-options-details {
    position: relative;
    > div {
      margin-bottom: 10px;
    }
    .loader {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: $yellow1;
      background-image: url('../../../assets/images/loader-inverse.gif');
      background-position: center 100px;
      background-repeat: no-repeat;
      z-index: 100;
      opacity: 0.7;
    }
  }
  .booking-options-hours{

    width: 100%;
    .dropdown{
      margin: 0;
      width: calc(50% - 10px);
      float: left;
      .input{
        font-style:normal;
        color: #131e29;
        font-size: 16px;
      }
    }
  }

}

@media screen and (max-width: 1600px)  {
  .booking-options{
    .booking-options-hours{
      width: 100%;
      .dropdown{
        margin: 0;
        //width: 100%;
        display: block;
        float: inherit;
      }
    }
  }

  .booking-options{

     .datepicker-container .react-datepicker{
      width: 80%;
      text-align: center;
      .react-datepicker__month-container{
        float: inherit;
          width: 100%;
      }
      .react-datepicker__month-container{
        margin-left: 0px;
        &:last-child{
          margin-top: 10px;
        }
      }
    }
  }

}

.error-box{
  background-color: #FF384D;
  padding: 10px;
  margin-top: 30px;

  .product-error{
      font-size: 14px;
      line-height: 20px;
      text-align: center;
  }

}

.week-days-not-selectable, .hours-not-selectable{
  font-size: 24px;
    color: #131e29;
    margin-top: 20px;
  line-height: 1.17;
 .booking-options-title{
    margin-bottom: 6px!important;
  }
}
.week-days-not-selectable {
  padding-bottom: 17px;

}

.dropdown.expanded .container {
    display: block;
    background: #fff;
    padding: 0;
}

.amenities-services{
  .booking-options-title {
    margin-bottom: 25px!important;
  }
  .booking-options-service{
    margin-bottom: 10px;
  }
  .booking-options-list{
    .booking-options-amenity, .booking-options-service{
      font-size: 24px;
      color: #04284b;
      .mention{
        color: #04284b;
      }

    }
  }
}

.dropdown .container {
  border-top: solid 1px #cdd1d4;
}


.pdf-link__container {
  margin-top: 18px;
  padding-top: 12px;
  .pdf-link__link {
    color: $red2;
    font-weight: 600;
    &:hover {
      color: $red3;
    }
  }
}
