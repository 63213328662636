.booking-invite-members__wrapper {
  border-radius: 3px;
  background-color: #0f171f;
  padding: 30px;
  margin-top: 25px;
}
.booking-invite-member__description {
  display: flex;
  flex-direction: column;
}
.booking-invite-members__title {
  font-family: $font-family-cera-medium;
  font-size: 24px;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.booking-invite-members__subtitle {
  font-family: $font-family-cera-regular;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #7c878e;
}
.booking-invite-members__selected {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  align-items: center;
  width: 100%;
  img {
    width: 36px;
    height: 36px;
    object-fit: contain;
    border-radius: 50%;
  }
  .booking-invite-members__image-section {
    align-items: center;
    display: flex;
    width: 33%;
  }
  .hero-name {
    font-family: $font-family-cera-regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.81;
    letter-spacing: normal;
    color: #ffffff;

  }
  .hero-email, .hero-company-name {
    font-family: $font-family-cera-regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height:15px;
    letter-spacing: normal;
    color: #7c878e;
  }
  .hero-email {
    width: 35%;
  }
  .hero-company-name {
    width: 25%;
  }
  .booking-invite-member__button-section {
    display: flex;
    flex-direction: row;
    width: 12%;
    justify-content: flex-end;
  }
}

.hero-results__details {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  justify-content: center;
  align-content: center;
  width: 100%;
}
.hero-results__headline {
  font-family: $font-family-cera-regular;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7c878e;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hero-results__name {
  font-family: $font-family-cera-medium;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #131e29;

}
.hero-results__profilePicture {
  width: 36px;
  height: 36px;
  object-fit: contain;
  border-radius: 50%;
}
.booking-invite-members__search {
  margin-top: 30px;
  input {
    width: 350px;
    height: 50px;
    border-radius: 3px;
    border: solid 1px #fed87d;
    background-image: linear-gradient(to top, #fffdfa, #fffbf3);
    font-family: $font-family-cera-regular;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #131e29;
    padding: 20px;
  }
  .search-results {
    max-width: 350px;
    border-radius: 3px;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px #cdd1d4;
    background-color: #ffffff;
    display: none;
    position: absolute;
    width: 100%;
    z-index: 1;
    li {
      border-bottom: solid 1px #cdd1d4;
      padding: 10px 20px;
      &:hover {
        background-color: #fffbf3;
      }
    }
  }
  li.selectedResult {
    background-color: #cdd1d4;
  }
  .liSearching {
    display: flex;
    padding: 8px 19px 9px 19px;
    color: #131e29;
    img {
      height: 28px; marginLeft: 30px;
    }
  }
}
.booking-invite-members__search.search-typed  .search-results.hasSearch  {
  display: block;
  height: 186px;
}
.add-new__hero-results__profilePicture {
  background-color: $color-yellow;
  border-radius: 50%;
  width: 40px;
  height: 35px;
  object-fit: none;
}
.heroResult {
  display: flex;
  line-height: 40px;
  cursor: pointer;
  .search-hero-initials{
    font-size: 18px;
    margin: 0;
    width: 48px;
  }
}
.booking-invite-members__add-new-member {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  input {
    height: 50px;
    border-radius: 3px;
    border: solid 1px #cdd1d4;
    background-image: linear-gradient(to top, #ffffff, #f6f6f6);
    padding: 0 20px;
    font-family: $font-family-cera-regular;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 12px;
    letter-spacing: normal;
    color: #131e29;
    width: 25%;
    &::placeholder {
      font-family: $font-family-cera-regular;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: italic;
      letter-spacing: normal;
      color: #848e95;
      padding: 5px 0;
    }
  }
  .booking-invite-member__button-section {
    display: flex;
    align-items: center;
  }
}
.hero-invite-button,
.button-resend-invitation,
.button-delete-invitation,
.button-status-invitation
{
  font-family: $font-family-cera-bold;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 9px;
  letter-spacing: normal;
  text-align: center;
  color: $color-yellow;
  text-transform: uppercase;
  background-color: #0f171f;
  padding: 0 5px;
  margin: 0;
}
.button-delete-invitation {
  color:#ff4a4a;
  cursor: pointer;
}
.button-status-invitation
{
  color:#ffffff;
  &.--declined:before {
    content: 'x';
    width: 8.9px;
    height: 8.9px;
    color: #ff2b2b;
    padding-right: 5px;
  }
  &.--confirmed:before {
    content: "\2714";
    color: #00ce2d;
    width: 13px;
    height: 8.9px;
    padding-right: 5px;
  }

  .booking-invite-member__button-section{
    display: flex;
    justify-content: flex-end;
  }
}
.button-resend-invitation {
  cursor: pointer;
}
.meeting-attendees-delete-modal {
  padding-top: 10%;
  .base-modal__modal-content {
    max-width:580px;
    width: 100%;
  }
  .base-modal__modal-header {
    position: relative;
    display: block;
    margin: 0 12px;
  }
  .base-modal__close {
    position: absolute;
    top: 0;
    right: 0;
    color: #a0abb2;
  }
  .base-modal__right-area {
    padding: 25px 18px 30px 30px;
  }
  .base-modal__modal-header-title {
    width: 80%;
  }
  .base-modal__modal-description {
    font-family: $font-family-cera-regular;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: normal;
    color: #131e29;
    margin: 50px 12px 85px;
  }
  .meeting-attendee-modal__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: relative;
  }
  .meeting-attendee-modal__delete-button {
    font-family: $font-family-cera-bold;
    font-size: 14px;
    line-height: normal;
    letter-spacing: normal;
    color: #7c878e;
    background-color: unset;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding: 0 18px;
    margin: 0;
    max-width: 150px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    justify-content: center;
    cursor: pointer;
  }
  .meeting-attendee-modal__cancel-button {
    font-family: $font-family-cera-bold;
    font-size: 14px;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    margin: 0 12px;
  }
  .base-modal__left-area-icon {
    color: #f0b323;
    margin-left: 15px;
    margin-bottom: 16px;
    width: 20px;
    height: 13px;
  }
}
.disabledButton:active {
  pointer-events: none;

}
.disabledButton {
  cursor: not-allowed;
  &:hover {
    opacity: 0.5;
  }
}