@import '../../../theme/colors';
@import '../../../theme/layout';

.centerSection {
    margin-top: calc((100vh - 270px) / 2);
}

a:focus, a:hover {
    color: #ffffff;
    text-decoration: none;
}
.body-landing {
    font-family: "CeraPRO-Medium";
    line-height: 1 !important;
    color: #7c878e;
    background-color: #131e29;
    min-height: 100vh;
    span {
        color: #7c878e;
        &.white{
            color:#ffffff;
        }
    }
}
.positionCenter{
    margin: 0 auto;
}
.w-h2 {
    font-size: 24px;
    line-height: 1.42;
}

.w-p {
    margin-bottom: 35px;
    display: block;
}

.w-p span {
    font-size: 16px;
    line-height: 1.5;

}
.w-h1{
        font-size: 54px;
        line-height: 1.3;
    }
.white {
    color: #ffffff ;
}

.icon-holder {
    display: block;
    width: 100%;
}

.logo {
    width: 200px;
    height: 52px;
    //margin: 0 auto;
    margin-bottom: 40px;
    img {
        max-width: 100%;
        max-height: 100%;
    }
    > img:last-child {
        display: none;
    }
    .conditionalLogo[src="#"] {
        display: none;
        & + img {
            display: block;
        }
    }
}
.workero-logo {
    display: none;
    width: 150px;
    height: 40px;
    background-image: url("../../../assets/images/hero-invitation/workero.png");
    background-size: contain;
    margin: 0 auto;
    margin-top: 5px;
    margin-bottom: 20px;
    background-color: #131e29;
    display: block;
}

.copyright,
.service {
    display: none;
    &.active {
        display: block!important;
    }
}

.service.active + .icon-holder .workero-logo {
    display: block;
}

.copyright.active + .icon-holder .workero-logo {
    display: none;
}

.fly-icon {
    width: 100px;
    height: 89px;
    background-image: url("../../../assets/images/hero-invitation/fly-icon.png");
    background-size: contain;
}

.balance-icon {
    width: 100px;
    height: 89px;
    background-image: url("../../../assets/images/hero-invitation/balance-icon.png");
    background-size: contain;
}

.hearth-icon {
    width: 100px;
    height: 89px;
    background-image: url("../../../assets/images/hero-invitation/hearth-icon.png");
    background-size: contain;
}

.imgBackground {
    width: 100%;
    background-image: url("../../../assets/images/hero-invitation/map.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
}

.btn-linkedin {
    height: 50px;
    width: 100%;
    max-width: 410px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 3px;
    background-color: #0e76a8;
    border: 0px solid transparent;
    font-family: "CeraPRO-Medium";
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    line-height: 50px;
    padding-left: 25px;
    padding-right: 25px;
    cursor: pointer;
    &:hover {
        opacity: 0.9;
        box-shadow: #0e161e;
    }
    &:hover.btn-second {
        opacity: 0.9;
        box-shadow: #0e161e;
    }
}

.btn-linkedin .img-icon {
    width: 28px;
    height: 28px;
    background-image: url("../../../assets/images/hero-invitation/linkedin.png");
    background-size: contain;
    float: left;
    margin-top: 13px;
}

.btn-linkedin.btn-first {

    display: block;
    //margin: 0 auto;
}

.btn-linkedin span {
    font-size: 16px;
    font-weight: bold;
}

.yellowBg {
    background-color: #F0B323;
    padding-top: 140px;
    padding-bottom: 130px;
}

.yellowBg .w-h1 {
    margin-bottom: 100px;
    display: block;;
}

.btn-linkedin.btn-second {
    margin-top: 100px;
}

.title-holder span {
    font-size: 36px;
    line-height: 1.22;
    font-weight: 500;
    color: #ffffff;
    display: block;
    margin-top: 40px;
    margin-bottom: 30px;
}

.description-holder span {
    font-size: 18px;
    line-height: 1.56;
    text-align: left;
    color: #131e29;
}

.list-inline-dot {
    display: inline-block;
}

.list-inline-dot a:last-child,
.list-inline-dot a:first-child {
    display: inline-block;
    float: left;
    margin-right: 15px;
    padding-left: 15px;
}

.intro span {
    font-size: 16px;
    line-height: 2;
    text-align: center;
    color: #a0abb2;
}

.list-inline-dot a {
    display: inline-block;
    float: left;
    margin-right: 25px;
    color: #ffffff;
    font-size: 16px;
    line-height: 2;
    &:hover {
        color: #e7e7e7;
    }
}

.footer {
    position: fixed;
    margin-bottom: 55px;
    bottom:0;
    .intro {
        font-size: 1.15em;
    }
}

.styleBackground {
    background-image: url("../../../assets/images/hero-invitation/background-image.png");
    background-size: 100% 2px;
    background-repeat-x: no-repeat;
    background-repeat-y: repeat;
}

@media (min-width: 768px) {
    .linkedin-login {
        .white.yellow {
            margin-bottom: 0px;
            display: inline-block;
        }
        .custom30 {
            padding-right: 20px !important;
            padding-left: 20px !important;
        }

    }
}

@media (max-width: 767px) {
    .imgBackground {
        width: 100%;
        background-image: none;
    }
    .w-h1 {
                font-size: 36px;
                line-height: 1.22;
            }
            .w-h1.yellow{
                padding-bottom: 15px;
                display: block;
            }
    .yellowBg .w-h1{
        font-size: 27px;
        line-height: 1.26;
    }
    .yellowBg .w-h1{
                margin-bottom: 0px;
            }
    .btn-linkedin {
        height: 50px;
        line-height: 50px;
    }
    .btn-linkedin span {
        font-size: 14px;
        font-weight: bold;
    }
    .btn-linkedin .img-icon {
        margin-top: 13px;
    }
    .w-h2 {
        font-size: 24px;
        line-height: 1.42;
    }
    .w-h2.yellow {
        padding-bottom: 15px;
        display: block;
    }
    .w-p span {
        font-size: 14px;
        line-height: 1.5;
    }
    .intro span {
        font-size: 14px;
        line-height: 1.5;
    }
    .list-inline-dot a {
        font-size: 14px;
        line-height: 1.5;
    }
    .custom60 {
        padding-right: 40px !important;
        padding-left: 40px !important;
    }
    .yellowBg {
        background-image: url("../../../assets/images/hero-invitation/small-resolution.png");
        background-size: 100% 2px;
        background-repeat-x: no-repeat;
        background-repeat-y: repeat;
    }

    .fly-icon,
    .hearth-icon,
    .balance-icon {
        width: 49px;
        height: 44px;
        margin-top: 50px;
    }
    .yellowBg {
        background-color: #F0B323;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .yellowBg .w-h2 {
        margin-bottom: 0px;
    }
    .btn-linkedin.btn-first {

    }

    .white.yellow {
        color: #F0B323;
    }
    .title-holder span {
        margin-top: 20px;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.33;
        margin-bottom: 10px;
    }
    .description-holder span {
        font-size: 10px;
        line-height: 0.8 !important;
    }
    .yellowBg .w-h2 {
        font-size: 27px;
        line-height: 1.26;
    }
    .body-landing span .yellow {
                color: #F0B323;
            }

}
    .bulletDot {
        margin-right: 0px !important;
        &:before {
            content: "\2022";
            font-size: 15px;

        }
    }




//Invitation
.invitation{
    .logo{
        margin-top: 125px;
        margin-bottom: 145px;
    }
    .positionStatic {
        position: relative!important;
        display: block;
    }

    .styleBackground{
        margin-bottom: 120px;
    }
    .btn-linkedin.btn-first{
        margin-bottom: 200px;
    }
    .imgBackground{
        background-size: calc(46vw);
    }
}

@media (max-width: 767px) {
    .invitation{
        .white.yellow {
            color: #F0B323!important;
        }
        .logo {
            margin-top: 40px;
            margin-bottom: 45px;
        }
        .btn-linkedin.btn-first{
            margin-bottom: 70px;
        }

        .styleBackground{
            margin-bottom: 45px;
        }

        .imgBackground{
            background-size: calc(46vw);
        }
    }
}
@media (max-width: 370px) {
    .linkedin-login .btn-linkedin {
        height: inherit;
        line-height: 24px;
    }
}
@media (max-width: 345px) {
    .btn-linkedin.btn-first{
        height: inherit;
        display: inline-block;
        line-height: 1;
        padding-top: 15px;
        padding-bottom: 15px;
        span{
            line-height: 28px;
            vertical-align: middle;
            display: grid;
        }
        .img-icon {
            margin-top: 0px!important;
        }
    }
}
@media (max-height: 700px) {
    .centerSection {
        margin-top: calc((50vh - 0px) / 2);
    }
    .footer {
        position: relative;
        margin-bottom: 55px;
        bottom: 0;
        margin-top: 55px;
    }
}