.community-form__search-wrapper {
  width: 100%;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 25px;
    height: 25px;
    background: url('../../../../assets/svg/header/icon-search.svg') no-repeat 50% 50%;
    position: absolute;
    left: 12px;
    top: 8px;
    color: $color-black;
  }
}

.community-form__search-input {
  width: 100%;
  height: 40px;
  border: solid 1px #cdd1d4;
  border-radius: 3px;
  background-image: linear-gradient(to top, $color-white, $color-white-light-grey);
  padding-left: 50px;
  padding-right: 10px;
  @include text-typography($font-family-cera-medium, 16px);
  color: $color-black;

  &::placeholder {
    @include text-typography($font-family-cera-regular, 16px);
    font-style: italic;
    color: $color-grey2;
    line-height: 37px;
  }

  &::-webkit-input-placeholder {
    @include text-typography($font-family-cera-regular, 16px);
    font-style: italic;
    color: $color-grey2;
    line-height: unset;
  }

  &::-ms-input-placeholder {
    @include text-typography($font-family-cera-regular, 16px);
    font-style: italic;
    color: $color-grey2;
  }
}

.community-form__email-suggestion-box {
  background-color: $color-yellow-light2;
  border:1px solid $color-yellow;
  border-radius: 2px;
  padding: 23px 10px 25px 70px;
  @include text-typography($font-family-cera-medium, 16px);
  color: #131e29;
  line-height: 12px;
  background-image: url('../../../../assets/svg/header/orange-email-icon.svg');
  background-repeat: no-repeat;
  background-position: 20px 10px;
  cursor: pointer;
  position: absolute;
  width: 100%;
  z-index: 1;
}