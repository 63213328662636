.profile-loader__container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  .dashboard-loader__image {
    height: 18px;
    width: auto;
  }
  &.whitelabel-profile {
    margin: 100px auto;
  }
}

.profile-loader-content {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  &.profile-loader-content--hidden {
    display: none;
    opacity: 0;
  }
}
