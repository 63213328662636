.hero-details-sidebar {
  background-color: #27333c;
}

.colleague-details-wrapper{
  max-width: none;
  margin-left: 5px;

  .colleague-name{
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.67;
    letter-spacing: normal;
    color: #ffffff;
  }

  .colleague-position{
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.69;
    letter-spacing: normal;
    color: #7c878e;
  }
}

.heroes-active-row:hover .heroes-details-wrapper .heroes-details-floor{
  color: #7c878e;
}

.active-row-colleague{
  margin-top: 20px;
}

.active-row-colleague:hover{
  margin-top: 20px;
  background-color: #131E29;
}

.colleague-booking-section{
  padding-top: 60px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  color: #7c878e !important;
  text-transform: uppercase;
  text-decoration: none;
  padding-left: 14px;
  position: relative;
}

.coleague-sidebar{
  padding-top: 20px;
  padding-right: 20px;
  margin-right: 20px;

  .colleague-sidebar-section{
    width: 100%;
    margin: 40px 30px 0px 30px;

    .sidebar-headline{
      width: 95px;
      height: 10px;
      margin: 0 113px 6px 0;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 10px;
      letter-spacing: normal;
      color: #d9dfe3;
    }

    .sidebar-detail{
      margin: 6px 0 0;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      color: #d9dfe3;
    }

    .sidebar-border-content{
      display: inline-flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: normal;
      align-content: stretch;
      margin: 15px 5px 5px 0px;
      padding: 11px 7px 10px 9px;
      font-size: 14px;
      font-family: "CeraPRO-Bold";
      text-transform: uppercase;
      color: #7c878e;
      border: solid 2px #323e47;
      border-radius: 3px;
      line-height: 0.64;
    }
  }
}

.colleague-booking-details{
  border-top: dotted 2px #4f575c;
  padding-top: 20px;
}

.colleague-booking-details:nth-child(2){
  border-top: none;
}

.colleague-booking-title{
  font-family: CeraPRO-Regular;
  font-size: 24px;
  color: #ffffff;
  padding-bottom: 5px;
  line-height: 1;
}

.colleague-booking-date{
  font-family: CeraPRO-Regular;
  font-size: 18px;
  line-height: 12px;
  padding: 0px 0px 13px 0px;
  text-transform: capitalize;
}
.colleague-booking-type, .colleague-booking-type span {
  font-family: "CeraPRO-Bold";
  margin-top: 0;
  font-size: 14px;
  line-height: 13px;
  color: #7c878e;
  text-transform: uppercase;
  padding: 0px 0px 20px 0px;

  &.working-days span {
    color: #fff;
  }

  .colleague-booking-status{
    margin-left: 5px;
    padding: 3px 10px;
    border-radius: 15px;
    background-color: #f0b323;
    font-size: 13px;
    color: #131e29;
    line-height: 9px;
    text-transform: none;
    font-family: "CeraPRO-Bold";
  }
}

.colleague-booking-book-next {
  margin-bottom: 20px;
  button{
    width: 237px;
    font-family: "CeraPRO-Bold";
    text-transform: uppercase;
    border: 2px solid #f0b323;
    border-radius: 2px;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    color: #f0b323;
    border-radius: 3px;
    background-color: #0f171f;
    margin: 5px 0px 5px 0px;
    padding: 16px 20px 16px 20px;
  }
}

.office-card__info-wrapper{
  padding: 27px;
}

.colleague-card__image-container{
  text-align: center;
  height: 201px;
  background-image: url('./../../../assets/images/loader.gif');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 33%;

  img{
    height: 100%;
    width: 100%;
    border-radius: 3px;
  }
}

.accordion__icon {
  cursor: pointer;
  margin-left: 5px;
  transition: transform 0.3s ease;
}

.rotate {
  transform: rotate(180deg);
}

.colleague-container{
  margin: 20px 10px 20px 10px;
  width: 100%;
  justify-content: center;
}

.hero-bookings-container{
  display: inline-block;
  vertical-align: top;
  min-width: 307px;
  max-width: 312px;
  margin-right: 0.8em;
  margin-bottom: 30px;
}

.hero-bookings-wrap{
  padding: 27px 28px;
  background-color: #0f171f;
  min-height: 400px;
}

.heroResult {
  &.selected, &:hover{
    background-color: #131e29;
  }
}
.no-bookings {
  text-align: center;
  left: 50%;
  top: 50%;
  position: absolute;
  width: 55%;
  transform: translate(-50%, -50%);
  &-title {
    font-size: 36px;
    font-weight: 300;
    margin: 10px 0 30px 0;
    display: inline-block;
  }
}