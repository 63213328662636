@import '../../../../theme/colors';
@import '../../../../theme/typography';
@import '../../../../theme/layout';

.myBookings {
  .officesGrid {
      padding: 0 0px 60px;
      box-sizing: border-box;
      overflow: hidden;
      position: relative;
      .officesGrid-topSection{
        .officesGrid-title{
          position: relative;
          z-index: 5;
        }
      }
      &-gridItems {
        display: flex;
        z-index: 5;
        position: relative;
        transition: transform 200ms;
      }

      &-slideLeft, &-slideRight {
        position: absolute;
        top: 0;
        right: 0px;
        width: 30px;
        height: 100%;
        background: url('./../../../../assets/svg/officeBox/arrow.svg') no-repeat center center $yellow1;
        cursor: pointer;
        z-index: 10;

        &.disabled {
          display: none;
        }
      }

      &-slideLeft {
        right: auto;
        left: 0;
        transform: scale(-1);
      }

      &-title {
        @extend %typography-tabItem;

        display: inline-block;
        text-transform: uppercase;
        margin-bottom: 27px;
        width: 50%;
        color: $gray13;
      }

      &-topInfo {
        @extend %typography-tabItem;

        text-align: right;
        width: 50%;
        display: inline-block;
        color: $white1;
      }

      &-item {
        flex: 1;
        margin: 0 15px;

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }

      &-principal {
        background-color: $yellow1;
        width: calc(100% + 45px);
        transform: translate(-30px, 0px);
        .officesGrid-container{
          padding: 0 29px;
        }
        .officesGrid-topSection{
          display: contents;
        }
        &.--homePage {
          margin-bottom: 45px;
          padding-top: 45px;


        }
      }

      &-secondary {
        background-color: $blue1;
        padding-bottom: 0;

        .officesGrid-slideLeft, .officesGrid-slideRight {
          background-color: $blue1;
        }

        .officesGrid-title {
          color: $gray1;
        }
        .officesGrid-totalItems {
          display: none;
        }
      }

      &-cutPadding {
        padding-top: 7px;
        padding-left: 30px;
        //padding-right: 30px;
        width: calc(100% + 47px);
        margin-left: -30px;


      }

      &-totalItems {
        background-color: $yellow1;
        color: $blue1;
        height: 22px;
        border-radius: 11px;
        padding: 2px 9px 3px;
        font-size: 13px;
        margin-left: 8px;
      }

      &-vertical {
        padding: {
          left: 0;
          right: 0;
        };

        @media screen and (max-width: $largeDevices) {
          padding-top: 20px;
        }

        background-color: transparent;

        .officesGrid {

          &-title, &-topInfo {
            width: 100%;
            text-align: left;
          }

          &-title {
            margin-bottom: 6px;
          }

          &-topInfo {
            font-size: 36px;
            margin-bottom: 23px;

            @media screen and (max-width: $largeDevices) {
              font-size: 18px;
            }
          }

          &-gridItems {
            flex-direction: column;
          }

          &-item {
            margin: 0;
            background-color: transparent;

          }
        }
      }

      &.--fullView {
        max-width: 100%;
        padding-top: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        //justify-content: space-evenly;
        .officesGrid {

          &-gridItems {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            @media screen and (max-width: $connectionsBreakpoint) {
              flex-wrap: wrap;
            }
          }

          &-topSection {
            display: none;
          }

          &-item {
            min-width: calc(25% - 22.5px);
            max-width: calc(25% - 22.5px);
            flex-grow: 1;
            padding: 15px 0;
            box-sizing: border-box;

            @media screen and (max-width: $connectionsBreakpoint) {
              //width: 33.33%;
              min-width: calc(33.33% - 22.5px);
              max-width: calc(33.33% - 22.5px);
            }
          }
        }
      }

      &.--homePage {
        padding-bottom: 45px;
        //margin-bottom: 45px;
        //padding-top: 50px;

      }
    }

}