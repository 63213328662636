.booking-payment-options{
  height: 100%;
  background-color: #F0B323;
}
.booking-payment-options__content {
  padding: 30px;
  height: 100%;
  display: flex;
  .booking-payment-options-scrollable {
    display: flex;
    flex-direction: column;
  }
  .back-button {
    font-family: CeraPRO-Bold;
    font-size: 14px;
    line-height: 10px;
    color: #131e29;
    text-transform: uppercase;
    cursor: pointer;
    &:before {
      content: "";
      position: relative;
      display: inline-block;
      width: 14px;
      height: 14px;
      top: 2px;
      left: 0;
      background-repeat: no-repeat;
      background-image: url('../../../assets/svg/addBookingSidebar/angle-left.svg');
    }
  }
  .booking-payment-options__title {
    padding-top: 30px;
    font-family: CeraPRO-Regular;
    font-size: 14px;
    line-height: 10px;
    color: #131e29;
  }
  .booking-payment-options__section {
    padding-top: 28px;
  }
  .booking-payment-options__footer {
    padding: 40px 0;
    display: flex;
    flex-direction: column;
   .title {
     font-family: CeraPRO-Medium;
     font-size: 18px;
     line-height: 20px;
     color: #131e29;
   }
    .description {
      font-family: CeraPRO-Regular;
      font-size: 14px;
      line-height: 18px;
      color: #131e29;
      padding-top: 6px;
    }
    .add-payment-button {
      font-family: CeraPRO-Bold;
      font-size: 14px;
      line-height: 10px;
      color: #131e29;
      padding-top: 20px;
      cursor: pointer;
      &:before{
        content: "+";
        color: #131e29;
        line-height: 10px;
        padding-right: 5px;
      }
    }
  }
  .booking-payment-options__section {
    .payment-method__card-title {
      font-family: CeraPRO-Medium;
      font-size: 18px;
      line-height: 12px;
      color: #131e29;
      padding-left: 10px;
      display: inline-block;
    }
    .payment-method__profile-info {
      padding-left: 10px;
      display: block;
    }
    .payment-method__card-info, .payment-method__profile-info {
      font-family: CeraPRO-Regular;
      font-size: 14px;
      line-height: normal;
      color: #131e29;
      white-space: break-spaces;
    }
    label {
      display: flex!important;
    }
  }
}
.payment-option-icon {
  height: 20px;
  width: 20px;
}