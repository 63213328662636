@mixin default-border($size: $entity-border-size, $color: $color-grey-medium, $border-radius: $entity-card-border-radius) {
  border: #{$size} solid #{$color};
  border-radius: #{$border-radius};
}

@mixin text-typography($font-family : $font-family-cera-regular, $font-size : 14px) {
    font-family: $font-family;
    font-size: $font-size;
    line-height: normal;
    letter-spacing: normal;
}

@mixin dot-between($color: $color-grey) {
  color: $color-grey;
  margin: 0 8px;
}