.create_community_button {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 30px;
  button {
    margin: 0;
  }
}
.first_commnunity {
  font-family: $font-family-cera-medium;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: $color-grey-white;
}
.start_first_community__card {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;

}
.first_commnunity__column--left {
  flex: 5 1;
}
.first_commnunity__column--right {
  .start-now__button {
    width: 142px;
    margin: 0;
  }
}
.first_community_subtitle {
  font-family: $font-family-cera-regular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: $color-grey;
  display: block;
  padding-top: 10px;
}

.no-communities-label {
  font-size: 14px;
}