$color-yellow: #f0b323;
$color-yellow-light: #ffd24c;
$color-yellow-light2: #fffbf3;
$color-orange: #fed87d;
$color-grey-dark: #1f2b35;
$color-grey-medium-alt: #323e47;
$color-grey-medium: #333f48;
$color-grey: #7c878e;
$color-grey-light: #a1abb2;
$color-grey-light2: #cdd1d4;
$color-grey-white: #d9dfe3;
$color-grey2: #848e95;
$color-grey3: #fffdfa;
$color-grey-background: #0f171f;
$color-grey-border: #38434a;
$color-white: #ffffff;
$color-black: #131e29;
$color-black-light: #0d151d;
$color-white-grey: #e7ecef;
$color-red-light: #ff4a4a;
$color-white-light-grey: #f6f6f6;
$color-red: #ff4743;
$color-pink: #fff8f8;
$color-green-active: #00ce2d;
$color-green-light: #93e4a5;
$color-google: #4285F4;
$color-facebook: #3b5998;
$color-linkedin: #0077b5;
$color-twitter: #00acee;

$color-yellow-hover: #ffc824;

$font-family-cera-light: 'CeraPRO-Light';
$font-family-cera-regular: 'CeraPRO-Regular';
$font-family-cera-medium: 'CeraPRO-Medium';
$font-family-cera-bold: 'CeraPRO-Bold';
$font-family-cera-medium: 'CeraPRO-Medium';
$font-size-default: 14px;
$font-size-title: 16px;
$font-size-default-small: 12px;
$font-size-title-small: 14px;


$entity-spacing: 30px;
$entity-spacing-small: 22px;

$entity-border-size: 2px;
$entity-card-border-radius: 6px;

$entity-card-height: 482px;
$entity-card-height-small: 282px;

$entity-button-height: 40px;
$entity-button-height-small: 34px;
$entity-button-border-radius: 2px;