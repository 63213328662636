.hero-recommendation__container {
  display: flex;
  flex-direction: column;
  flex: 1;
  .hero-recommendation__header {
    flex: 0;
    margin-bottom: 25px;
  }
  .hero-recommendation__tags {
    flex: 1;
    .hero-recommendation__vertical-tags {
      margin-bottom: 17px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .hero-recommendation__footer {
    flex: 0;
    transform: translateY(16px);
  }
}

.hero-recommendation-profile {
  margin-bottom: 20px;
  display: flex;
  cursor: pointer;
  .hero-recommendation-profile__information {
    margin-left: 19px;
    padding-top: 19px;
    .hero-recommendation-profile__name {
      margin-bottom: 10px;
      font-family: $font-family-cera-regular;
      font-size: $font-size-title;
      line-height: 0.63;
      color: $color-grey-white;
    }
    .hero-recommendation-profile__title {
      position: relative;
      font-family: $font-family-cera-light;
      font-size: $font-size-default;
      line-height: 1.29;
      color: $color-grey-white;
    }
    .hero-recommendation-profile__title span span > span {
      font-family: $font-family-cera-light;
      font-size: $font-size-default;
      line-height: 1.29;
      color: $color-grey-white;
    }
  }
}

@media (max-width: 1360px) {
  .hero-recommendation__container {
    .hero-recommendation__header {
      margin-bottom: 16px;
    }

    .hero-recommendation__tags {
      .hero-recommendation__vertical-tags {
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
  .hero-recommendation-profile {
    margin-bottom: 12px;
    .hero-recommendation-profile__information {
      .hero-recommendation-profile__name {
        margin-bottom: 8px;
        font-size: $font-size-title-small;
        line-height: 1;
      }
      .hero-recommendation-profile__title {
        font-size: $font-size-default-small;
        line-height: 1;
      }
      .hero-recommendation-profile__title span span > span {
        font-size: $font-size-default-small;
        line-height: 1;
      }
    }
  }
}
