.info-toast {
  &.success-message {
    z-index: 1;
    position: fixed;
    top: 140px;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid black;
    height: 40px;
    border-radius: 20px;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.8);
    background-color: $color-yellow;
    text-align: center;
    padding: 0 40px;

    span {
      position: relative;
      top: 10px;
      color: $color-grey-background;
      font-family: $font-family-cera-medium;
    }
  }
}