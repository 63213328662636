@import '../../../theme/colors';

.pageHeader {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  margin: {
    top: 49px;
    bottom: 29px;
  }
  padding: {
    //left: 30px;
    //right: 30px;
  }

  &.--defaultPadding {
    padding: {
      //left: 30px;
      //right: 30px;
    }
  }

  &.--myBookings {
    margin: {
      top: 9px;
      bottom: 23px;
      left: 30px;
    }

    .pageHeader {
      &-titleText {
        color: $white1;
      }
    }
  }

  &-titleText {
    font-size: 24px;
    line-height: 36px;
    color: inherit;
    vertical-align: top;
    color: #7c878e;
  }

  &-icon {
    color: $gray6;
    //font-size: 32px;
    width: 32px;
    height: 28px;
    display: inline-block;
    margin-right: 15px;
    &:before{
      font-size: xx-large;
    }
    &.icon-connections {
      &:before{
        font-size: 30px!important;
        margin-top: -1px;
        display: inline-block;
      }
    }
    &.icon-booking{
      &:before{
        font-size: 33px!important;
        margin-top: -2px;
        display: inline-block;
      }
    }
    &.icon-payment-settings{
      &:before{
        font-size: 33px!important;
        margin-top: -2px;
        display: inline-block;
        width: 33px;
        height: 33px;
        content: '';
        background: url('./../../../assets/svg/header/payment-settings-icon.svg') no-repeat 50% 50%;
      }
    }
    &.icon-general-settings{
      &:before{
        font-size: 33px!important;
        margin-top: -2px;
        display: inline-block;
        width: 33px;
        height: 33px;
        content: '';
        background: url('./../../../assets/svg/header/general-settings-icon.svg') no-repeat 50% 50%;
      }
    }
  }

  &-grid, &-map {
    display: inline-block;
    margin-left: 28px;
    cursor: pointer;
    text-transform: uppercase;
    line-height: 16px;
    vertical-align: middle;
    font-size: 14px;
    color: $gray14;

    &:before {
      width: 16px;
      height: 16px;
      line-height: 16px;
      display: inline-block;
      margin-right: 10px;
      content: '';
      vertical-align: middle;
      background-size: cover;
      background-position: center center;
    }
  }

  &-grid {
    font-family: 'CeraPRO-Light';
    font-weight: bold;
    &:before {
      background: url('./../../../assets/svg/header/grid-icon-disabled.svg') center center;
    }

    &.--active {
      color: $yellow1;

      &:before {
        background: url('./../../../assets/svg/header/grid-icon.svg') center center;
      }
    }
  }

  &-map {
    font-family: 'CeraPRO-Light';
    font-weight: bold;
    &:before {
      background: url('./../../../assets/svg/header/map-icon-disabled.svg') center center;
    }

    &.--active {
      color: $yellow1;

      &:before {
        background: url('./../../../assets/svg/header/map-icon.svg') center center;
      }
    }
  }

  &-itemsDisplay {
    text-align: right;
    flex: 1;
    vertical-align: middle;
    line-height: 36px;
  }
}
//.pageHeader-titleText{
//  color: #fff !important;
//  //margin-left: 28px;
//}
.pageHeader-icon.icon:before{
  vertical-align: top;
}
