.react-datepicker {
  font-size: 1em;
  margin: 0;
  vertical-align: top;
  /*border-radius: 0 0 3px 3px;*/
  border: none;
  background-color: transparent;
}
.react-datepicker__header {
  padding-top: 0.8em;
}
.react-datepicker__month {
  margin: 0;
}
.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em;
  font-size: 0.5em;
  line-height: 1.9em;
  margin: 0;
  padding: 1px;
  color: $blue2;
  border-right: 1px solid $gray3;

  &:last-child {
    border-right: none;
  };
}
.react-datepicker__current-month {
  font-size: 1em;
  height: 20px;
  line-height: 2.4em;
  vertical-align: middle;
  color: $white1;
  background-color: $gray3;
}
.react-datepicker__navigation {
  //top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
   display: inline-block;
    width: 20px;
    height: 21px;
    vertical-align: top;
    border: 1px solid #cdd1d4;
    background-repeat: no-repeat;
    background-position: 5px 3px;
    border-right: none;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: #fff;
    background-image: url('../../../assets/svg/datePicker/calendar-nav-left.svg');
    top: 0;
    left: -20px;
}
.react-datepicker__navigation--next {
  display: inline-block;
    width: 20px;
    height: 21px;
    vertical-align: top;
    border: 1px solid #cdd1d4;
    background-repeat: no-repeat;
    background-position: 5px 3px;
    border-right: none;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: #fff;
    background-image: url('../../../assets/svg/datePicker/calendar-nav-left.svg');
    top: 0;
    right: -20px;
}
.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  //display: none;
  &.datepicker-nav-left{
    //display: inline-block;
  }
  &.datepicker-nav-right{
    //display: inline-block;
  }
}
.react-datepicker__header {
  padding-top: 0;
  font-size: 0.5em;
  text-transform: uppercase;
  border: none;
}
.react-datepicker__month-container {
  margin: 0;
}
.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
  background-color: white;
  line-height: 0.5em;
  border-bottom: 1px solid $gray3;
}
.react-datepicker__day:hover {
  border-radius: 0;
}
.react-datepicker__week:last-child {
  border-bottom: none;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: $yellow1;
  border-radius: 0;
}
.react-datepicker__month-container {
  border: 1px solid $gray3;

  &:last-child {
    border-left: 1px solid $gray3;
    margin-left: 4px;
  }
}


.Cal__MonthList__root{
  max-height: 197px;
}