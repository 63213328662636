.report-modal {
  //override modal css
  .base-modal__modal-content {
    width: 628px;
  }
  .base-modal__modal-body {
    height: 100%;
    padding-top: 0;
  }
  .base-modal__right-area {
    padding: 0;
  }
  .base-modal__modal-header {
    padding: 25px 30px;
  }
  .base-modal__close {
    top: 21px;
    right: 27px;
  }

  padding-top: 14%;

  .report-reason-group {
    padding: 30px;
    border-bottom: 1px solid $color-grey-light2;
    height: 72px;
  }

  .report-reason-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  .report-reason-text {
    font-family: $font-family-cera-regular;
    font-size: 16px;
    font-weight: 600;
    color: $color-black;
  }

  .report-modal-container {
    background-color: $color-white-light-grey;
  }

  .report-reason-textarea {
    padding: 30px;
  }

  .reason-textarea {
    font-family: $font-family-cera-regular;
    width: 100%;
    height: 80px;
    padding: 10px 15px;
    font-size: 16px;
    font-style: italic;
    line-height: 1.38;
    color: $color-grey2;
    resize: none;
    border-radius: 3px;
    border: solid 1px $color-grey-light2;
    background-image: linear-gradient(to top, $color-white 30%, $color-white-light-grey 70%);
    &:focus {
      outline: none;
    }
  }

  .report-buttons-area {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 30px 30px 0;
  }

  .report-button-back {
    font-family:  $font-family-cera-bold;
    color: $color-grey;
    font-size: 14px;
    padding: 0 18px;
    margin: 0 13px 0 0;
    background-color: unset;
    max-width: 110px;
    width: fit-content;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
  }

  .report-button-submit {
    max-width: 110px;
    margin: 0;
  }
}

