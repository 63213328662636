.community-form {
  font-size: $font-size-default;
  .attachment__input {
    display: none;
  }

  .community-form__image {
    display: flex;
    padding-bottom: 27px;
  }

  .community-logo {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    background-color: #a1abb2;
    width: 60px;
    height: 60px;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    .icon-community {
      color: #cdd1d4;
      font-size: 30px;
      line-height: 30px;
    }
  }

  .community-logo-content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-left: 15px;
    padding-top: 13px;

    .requirements {
      color: $color-grey2;
      font-size: $font-size-default;
    }
  }

  .community-form__image-buttons-wrapper {
    text-align: left;
    padding-top: 5px;
    display: flex;
    flex-direction: row;

    .image-buttons__dot {
      color: $color-grey;
      margin: 0 5px;
    }
  }

  .community-form__upload-button {
    font-family: $font-family-cera-bold;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    background-color: unset;
    border: none;
    padding: 0;
    color: $color-yellow;
    cursor: pointer;
    text-transform: uppercase;
    width: auto;
    margin-top: 0;
  }

  .community-form__remove-button {
    color: $color-red-light;
  }

  .community-form__field {
    position: relative;
    min-height: 90px;
    .field-label {
      color: $color-black;
      font-size: $font-size-default;
      font-family: $font-family-cera-regular;
      padding-bottom: 7px;
      span {
        color: $color-grey;
        font-family: $font-family-cera-regular;
        font-size: $font-size-default;
      }
    }

    input, textarea {
      width: 100%;
      border-radius: 3px;
      border:1px solid $color-grey-light2;
      background-image: linear-gradient(to top, #ffffff, #f6f6f6);
      font-size: 16px;
      padding-left: 20px;
      height: 40px;
      font-family: $font-family-cera-medium;
      color: $color-black;
      outline: unset;

      &::-webkit-input-placeholder /* Chrome/Opera/Safari */ {
        color: $color-grey2;
        font-family: $font-family-cera-regular;
        font-style: italic;
      }
      &::-moz-placeholder /* Firefox 19+ */ {
        color: $color-grey2;
        font-family: $font-family-cera-regular;
        font-style: italic;
      }
      &:-ms-input-placeholder  /* IE 10+ */ {
        color: $color-grey2;
        font-family: $font-family-cera-regular;
        font-style: italic;
      }
      &:-moz-placeholder /* Firefox 18- */{
        color: pink;
        font-family: $font-family-cera-regular;
        font-style: italic;
      }
    }

    textarea {
      line-height: 22px;
      padding: 6px 19px;
      height: 80px;
      outline: unset;
    }
  }

  .community-logo__field {
    position: relative;
  }

  .community-form__description {
    min-height: 130px;
  }
  .community-form__audience {
    position: unset;
    margin-bottom: 30px;
  }

  .community-form__actions {
    display: flex;
    justify-content: flex-end;
  }

  .community-form__right-buttons {
    display: flex;
    justify-content: flex-end;
  }

  .community__loader {
    width: 193px;
    height: 50px;
    background-image: url('../../../../assets/images/circle-loader-64px.gif');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .community__button-delete {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-red;
    font-family: $font-family-cera-bold;
    text-transform: uppercase;
    font-size: 14px;
    background-color: unset;
    margin-top: 0;
  }

  .community__button-submit {
    display: flex;
    width: auto;
    margin: 0 0 0 10px;
    font-family: $font-family-cera-bold;
    font-size: 14px;
    text-transform: uppercase;
    padding-left: 20px;
    padding-right: 20px;
  }

  .community__button-cancel {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba($color-grey, 0.9);
    font-family: $font-family-cera-bold;
    text-transform: uppercase;
    font-size: 14px;
    margin-top: 0;
  }
}