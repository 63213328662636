.masonry-container {
  width: 100%;
  margin: auto;
}

.masonry {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: stretch;
  width: 100%;
}

.masonry__column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: stretch;
  flex-grow: 1;
  margin-right: 30px;
  width: 100%;
}

@media screen and (max-width: 1580px) {
  .masonry__column {
    margin-right: 15px;
  }
}