@import '../../../theme/buttons';

& {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  width: 100%;

  .directChat-aboutContent {
    &-contentWrapper {
      box-sizing: border-box;
      display: flex;
      flex-direction: row-reverse;
      flex-grow: 1;
      flex-wrap: wrap;
      height: 100%;
      padding: 0 30px 30px;

      & > div {
        width: 100%;
      }
    }

    &-pageTitle {
      color: $gray1;
      font-size: 14px;
      font-weight: bold;
      line-height: 44px;
      padding: 45px 0 12px;
      text-transform: uppercase;
    }

    &-connection {
      align-items: center;
      display: flex;
      margin-bottom: 10px;
    }

    &-avatar {
      align-items: center;
      border-radius: 50%;
      display: flex;
      height: 50px;
      margin-right: 10px;
      overflow: hidden;
      width: 50px;
    }

    &-avatarImage {
      max-height: 100%;
      max-width: 100%;
    }

    &-userName {
      color: $white1;
      font-size: 16px;
      line-height: 1.5;
    }

    &-userLastName {
      color: $white1;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      text-transform: uppercase;
    }

    &-jobTitle {
      color: $gray1;
      font-size: 16px;
      line-height: 1.5;
    }

    &-connectionData {
      margin-top: 50px;

      &Title {
        color: $gray1;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.5;
      }

      &Info {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }

      &Value {
        color: $white1;
        font-size: 36px;
        line-height: 44px;

        &.green {
          color: $green2;
        }
      }

      &Link {
        @extend %editLink;
        margin-top: 8px;
      }
    }

    &-slider {
      @extend %slider;

      align-self: flex-end;
      align-items: center;
      display: flex;
    }
  }
}