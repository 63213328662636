.login-form__third-party-button {
  @include text-typography($font-family-cera-medium, 14px);
  text-transform: uppercase;
  color: $color-white;
  border: solid 2px $color-yellow;
  border-radius: 5px;
  margin: 10px 0;
  text-align: center;
  &.facebook-login-button {
    background-color: $color-facebook;
    border-color: $color-facebook;
    height: 52px;
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 25px;
  }
  & > .facebook-login-icon {
    width: 38px;
    position: absolute;
    left: 3px;
  }
  &.azure {
    background-color: #336699;
    border-color: #336699;
    height: 52px;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 43px;
    .auth-button-text {
      color: $color-white;

    }
  }
  & > .azure-login-icon {
    width: 38px;
    position: absolute;
    left: 15px;
  }

  &.google {
    background-color: $color-google !important;
    border-color: $color-google !important;
    height: 52px;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 43px;
    .auth-button-text {
      color: $color-white;
    }
  }
  & > .google-login-icon {
    width: 100px;
    position: absolute;
    left: 15px;
    margin-top: 15px;
  }

  &.google-login-button {
    background-color: $color-google !important;
    border-color: $color-google !important;
    border-radius: 5px !important;
    padding-left: 3px !important;
    span {
      @include text-typography($font-family-cera-medium, 14px);
      text-transform: uppercase;
      color: $color-white;
      font-size: 14px;
      line-height: normal;
      letter-spacing: normal;
      margin: 6px auto;
      text-align: center;
    }
    &:hover{
      opacity: 1 !important;
    }
  }
  &.linkedin-login-button {
    background-color: $color-facebook;
    border-color: $color-facebook;
    padding: 0;
    height: 52px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  .linkedin-login-text {
    @include text-typography($font-family-cera-medium, 14px);
    text-transform: uppercase;
    color: $color-white;
    border-radius: 5px;
    margin: 10px 0;
    text-align: center;
    flex-grow: 1;
  }
  & > .linkedin-login-icon {
    width: 49px;
    margin-left: 3px;
  }
}
.auth-button {
  @include text-typography($font-family-cera-medium, 14px);
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 52px;
}
.email-login-icon {
  width: 30px;
  margin-left: 8px;
}
.auth-button-text {
  @include text-typography($font-family-cera-medium, 14px);
  color: $color-yellow;
  text-transform: uppercase;
  text-align: center;
  flex-grow: 1;
}
#login-form__linkedin-button {
  background-color: $color-linkedin;
  border-color: $color-linkedin;
}
#login-form__twitter-button {
  background-color: $color-twitter;
  border-color: $color-twitter;
}
#login-form__google-button {
  background-color: $color-google;
  border-color: $color-google;
}
#login-form__facebook-button {
  background-color: $color-facebook;
  border-color: $color-facebook;
}
// Temporary
.facebook,{
  &__disabled  {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
}
.facebook__disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

