@import '../../../theme/layout';

$margin: 30px;

.office-gallery {
  display: flex;
  //height: 606px;
  height: calc(44.781vw / 1.418);

  margin-top: 27px;

  //@media (min-width: 1680px) and (max-width: 1920px) {
  //  height: 610px;
  //}
  //
  //@media (min-width: 1680px) and (max-width: 1920px) {
  //  height: 610px;
  //}

  @media screen and (max-width: $largeDevices){
    height: 300px;
  }

  &-featured{
    width: 65.92%;
    height: 100%;
    background-size: cover;
  }
  &-aside {
    display: flex;
    flex-direction: column;
    margin-left: 2.28%;
    height: 100%;
    width: 31.8%;
    overflow: hidden;
  }

  &-middle {
    height: 50%;
    background-size: cover;
  }

  &-more{
    position: relative;
    height: 50%;
    margin-top: $margin;
    background-size: cover;

    &:after {
      content: attr(data-label);
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: black;
      opacity: .5;
    }
  }
}
