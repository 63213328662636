.community-uploads__container {
  background-color: $color-grey-dark;
  padding: 30px;
  border-radius: 6px;
}

.community-uploads__header {
  font-size: 14px;
  color: $color-grey-white;
  font-family: $font-family-cera-regular;
  margin-bottom: 16px;
}

.upload-card__container {
  display: flex;
  padding: 10px 0;
  align-items: center;
  width: 100%;
  margin-bottom: 11px;

  .upload-card__img {
    margin-right: 20px;
  }

  .upload-card__details {
    flex: 1;
    margin-right: 20px;
  }

  .card-details__title {
    margin-bottom: 5px;
    max-width: 300px;

    span {
      font-size: 16px;
      color: $color-grey-white;
      font-family: $font-family-cera-regular;
    }
  }

  .card-details__size {
    font-size: 14px;
    color: $color-grey;
    font-family: $font-family-cera-regular;
  }

  .upload-card__download-btn {
    width: 43px;
    height: 40px;
    border-radius: 3px;
    background-color: $color-white;
    padding: 11px 12px;
    visibility: hidden;
  }
  .upload-card__remove-btn {
    width: 43px;
    height: 40px;
    border-radius: 3px;
    background-color: $color-white;
    padding: 12px 5px;
    visibility: hidden;
    margin-right: 10px;
    text-align: center;
    img {
      width: 15px;
      height: 15px;
    }
  }
}

.upload-card__container:hover,
.upload-card__container:active {
  background-color: #333f48;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 100%;
  }

  &:before {
    background-color: $color-grey-medium;
    left: -20px;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }

  &:after {
    background-color: $color-grey-medium;
    left: 100%;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }

  .upload-card__download-btn, .upload-card__remove-btn {
    visibility: visible;
    cursor: pointer;
  }
}

//no uploads section
.community-no-uploads {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 240px;
  cursor: pointer;

  .no-uploads-img {
    margin: 10px 0 17px;
  }

  .no-uploads-title {
    font-family: $font-family-cera-regular;
    font-size: 14px;
    color: $color-grey-white;
    margin-bottom: 10px;
  }

  .no-uploads-subtitle {
    font-family: $font-family-cera-regular;
    font-size: 14px;
    color: $color-grey;
    line-height: 1.43;
    margin-bottom: 12px;
  }

  .no-uploads-images {
    display: flex;
    justify-content: space-between;
    max-width: 160px;
    margin-left: auto;
    margin-right: auto;
  }

  .no-uploads-image{
    display: flex;
    padding: 8px;
    border-radius: 3px;
    border: solid 2px #323e47;
    align-items: center;
  }
}
