.empty-rating-icon {
  width: 20px;
  height: 20px;
  margin-right: 4px;
  background-position: 0 0;
  background-repeat: no-repeat;

  &.theme-blue {
    background-image: url('../../assets/rating-empty.svg');
  }

  &.theme-yellow {
    background-image: url('../../assets/rating-blue.svg');

    &-small {
      background: url('../../assets/rating-blue.svg');
      width: 11px;
      height: 11px;
      margin-right: 2px;
      background-size: cover;
    }
  }
}

.full-rating-icon {
  width: 20px;
  height: 20px;
  margin-right: 3px;
  background-position: 0 0;
  background-repeat: no-repeat;

  &.theme-blue {
    background: url('../../assets/rating-full.svg');
  }

  &.theme-yellow {
    background: url('../../assets/rating-yellow.svg');

    &-small {
      background: url('../../assets/rating-yellow.svg');
      width: 11px;
      height: 11px;
      margin-right: 2px;
      background-size: cover;
    }
  }
}

