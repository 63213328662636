.community-audience__heroes-container {
  margin-left: 30px;
  position: relative;
}
.community-audience__heroes-container .search {
  width: 100%;
  .search-input {
    width: 100%;
    background-image: none;
    border: 1px solid $color-grey-light2;
    padding: 0 54px;
  }
  &.disabled-search {
    opacity: 0.3;
  }
}
.community-audience_email-error {
  font-family: $font-family-cera-regular;
  color: $color-red;
  font-size: 14px;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
  span {
    font-family: $font-family-cera-medium;
    color: $color-red;
    font-size: 14px;
  }
}

@media screen and (max-width: 1460px) {
  .community-audience__heroes-container {
    .community-form__email-suggestion-box {
      max-width: 404px;
    }
  }
}