$black1: #000;
$black2: rgba(0, 0, 0, 0.15);
$black3: rgba(0, 0, 0, 0.3);
$black4: rgba(0, 0, 0, 0.4);
$black5: rgba(0, 0, 0, 0.2);
$black6: #121d28;

$blue1: #131e29;
$blue2: #04284b;
$blue3: #5F73E0;

$gray1: #7c878e;
$gray2: #69757d;
$gray3: #cdd1d4;
$gray4: #a0abb2;
$gray5: #848e95;
$gray6: #333f48;
$gray7: #26333c;
$gray8: #a1abb2;
$gray9: #d6dadc;
$gray10: #bbc1c6;
$gray11: #e6e7e8;
$gray12: #0e161e;
$gray13: #0f171f;
$gray14: #778389;
$gray15: #4f575c;
$gray16: #eee;
$gray17: #8f999f;
$gray18: #748086;
$gray19: #1c2429;
$gray20: #f0f2f4;

$green1: #2eac63;
$green2: #00ce2d;
$green3: #93e4a5;
$green4: #d3ffdd;
$green5: #00ce2e;
$green6: #85E4A8;

$red1: #e54d42;
$red2: #ff4a4a;
$red3: #ff7f7f;

$white1: #fff;
$white2: #f2f2f2;

$yellow1: #f0b323;
$yellow2: #ffc824;
$yellow3: #fed87d;
$yellow4: #fffefa;
$yellow5: #fffcf3;
$yellow6: #ffd570;
$yellow7: #fffbf3;
$yellow8: #f7f7f7;
$yellow9: #ffdd4d;
$yellow10: #fffdf8;
$yellow11: #fff3d7;
$yellow12: #ffd87d;
$yellow13: rgba($yellow1, 0.1);
$color-big-stone: #323e47;