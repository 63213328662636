.office-details {
  padding: 26px 30px 30px 30px;
  height: 100%;
  
  .items-style1 {
    font-size: 24px;
    font-family: 'CeraPRO-Medium';
  }

  &-back-link {
    @extend %typography-fieldTitle;
    cursor: pointer;
    color: $gray1 !important;
    text-transform: uppercase;
    text-decoration: none;
    padding-left: 14px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 14px;
      height: 14px;
      top: 3px;
      left: 0;
      background-repeat: no-repeat;
      background-image: url('../../../assets/svg/officeDetails/angle-left.svg');
    }
  }
  > h3 {
    padding: 11px 0 22px!important;
  }
  &-paragraph {
    display: inline-block;
    color: $gray1;
    line-height: 1.44;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
  }
}

.officeDetailsLoader{
  text-align: center;
  height: 200px;
  background-image: url('../../../assets/images/loader.gif');
  background-position: center center;
  background-repeat: no-repeat;
}
