.community-page__comments-container {
  border-top: 1px solid #131e29;
  padding-bottom: 20px;

  .comments-wrapper__loader {
    height: 20px;
    background-image: url('../../../assets/images/loader.gif');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 50px;
    position: absolute;
    right: 0px;
  }
}

.community__comment-form {
  display: flex;
  padding: 0 30px;
  margin-top: 22px;
  &.community__comment-form--isReplyForm {
    padding-left: 80px;
  }
}

.community-comment__avatar {
  width: 36px;
  height: 36px;
}

.community-post__comment-input-container {
  width: 100%;
  margin-right: 10px;
  position: relative;
}

.community-post__comment-input {
  margin-left: 10px;
  border-radius: 3px;
  border:2px solid $color-grey-medium-alt;
  width: 100%;
  background-color: transparent;
  font-size: 14px;
  padding: 10px 14px;
    &::placeholder {
      color: $color-grey2;
      @include text-typography($font-family-cera-regular, 16px);
      letter-spacing: 0px;
      font-style: italic;
    }
  &:focus {
    border: 2px solid $color-grey;
  }
}

.community-post__validation-errors {
  font-family: $font-family-cera-regular;
  color: $color-red-light;
  font-size: 14px;
  margin-top: 3px;
  margin-left: 10px;
}

.community-comment__hero-picture {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
}

.community__comment-container {
  padding: 0 30px;
  display: flex;
  margin: 20px 0 0;
  &.community__comment-container--isReplyForm {
    margin-left: 50px;
  }

  .post-card-hero-initials {
    width: 36px;
    height: 36px;
    min-width: 36px;
    font-size: 16px;
  }

}
.community-comment__element {
  width: 100%;
  position: relative;
  margin-right: 10px;

  .context-menu {
    position: absolute;
    top:0px;
    right:0px;
    border: 0px;
    background-color: transparent;
    display: none;
    &:hover, &:active {
      border:0px;
    }
    .context-menu-items {
      margin-top: 10px;
    }
  }


}
.community-comment__container {
  flex-direction: row;
  border-radius: 6px;
  width: 100%;
  margin-left: 10px;
  background-color: $color-grey-medium-alt;
  padding: 6px 60px 6px 15px;
  word-break: break-all;

  .author {
    font-weight: bold;
    font-size: 3px;
  }

  &:hover, &:active {
    .context-menu {
      display: block;
    }
  }
}

.community-comment__content {
  color: $color-grey-white;
  line-height: 22px;
  @include text-typography($font-family-cera-regular, 16px);
  a {
    @include text-typography($font-family-cera-regular, 16px);
  }
}

.community-comment__controls {
  display: flex;
  align-items: flex-start;
  padding: 8px 30px;


  .comments-separator {
    padding:0px 5px;
    @include text-typography($font-family-cera-regular, 14px);
    color: $color-grey;
  }

  .comments-timestamp {
    @include text-typography($font-family-cera-regular, 14px);
    color: $color-grey;
  }
}

.community-comment-reply-button {
  color: $color-grey;
  @include text-typography($font-family-cera-regular, 14px);
  cursor: pointer;
  background-color: unset;
  width: auto;
  padding: 0;
  display: flex;
  margin: 0px;
}

.community-comment__author {
  cursor: pointer;
  color: $color-grey-white;
  font-weight: 600;
  @include text-typography($font-family-cera-medium, 16px);
}

.comments-show-all {
  cursor: pointer;
  @include text-typography($font-family-cera-medium, 14px);
  color: $color-yellow;
}