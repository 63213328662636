.office-booked {
  .sublabel{
    margin-top: 8px;
    color: #7c878e;
    font-size: 14px;
    margin-top: 5px;
    > div {
      margin-top: 8px;
      color: #7c878e;
      font-size: 14px;
      margin-top: 0;
    }
  }
  &-title {
    @extend %officeDetails-title;
    margin-top: 57px;
    padding: 0 0;
    margin: 55px 0 8px 0!important;
    margin-bottom: 8 !important;
    padding-top: 0 !important;
  }

  .office-booked-details-with-titles {
    .office-booked-title {
      margin-bottom: 5px;
    }

    .office-booked-details-description {
      font-size: 24px;
      line-height: 1.42;
    }
  }

  &-fieldDescription {
    @extend %typography-ceraGR;

    color: $gray1;
    font-size: 14px;
    margin-top: 10px;
  }

  &-list {
    @extend %displayFlex-wrap;
  }

  .office-booked-details,
  .office-booked-details-available,
  .office-booked-details-unavailable{
      @extend %officeDetails-items
  }


  .office-booked-details-available-before,
  .office-booked-details-unavailable-before {
      @extend %officeDetails-items-before
  }

  .office-booked-details {
    .office-booked-details-available,
    .office-booked-details-unavailable,
    .office-booked-details-available-before,
    .office-booked-details-unavailable-before  {
        width: 100%;
    }
  }

  .office-booked-details-available:after {
    background-image: url(../../../assets/svg/officeDetails/feature-available.svg)
  }

  .office-booked-details-unavailable:after {
    background-image: url(../../../assets/svg/officeDetails/feature-unavailable.svg)
  }

  .office-booked-details-available-before:before {
    background-image: url(../../../assets/svg/officeDetails/feature-available.svg)
  }

  .office-booked-details-unavailable-before:before {
    background-image: url(../../../assets/svg/officeDetails/feature-unavailable.svg)
  }

  .office-booked-details-unavailable {
    color: $gray8;
  }

  .icon-desk,
  .icon-private,
  .icon-event,
  .icon-parking,
  .icon-meeting {
    display: inline-block;
    position: relative;
    font-size: 36px;
    width: auto;
    height: 20px;
    padding-left: 35px;
    text-align: center;
    vertical-align: top;
    text-transform: capitalize;
    text-align: left !important;
    cursor: default;
    height: fit-content;
    &:before {
      content: '';
      position: absolute;
      width: 24px;
      height: 28px;
      top: 8px;
      left: 0;
      z-index: 2;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  .icon-desk:before {
    background-image: url('../../../assets/svg/addBookingSidebar/desk-office-icon.svg');
  }

  .icon-private:before {
    background-image: url('../../../assets/svg/addBookingSidebar/private-office-icon.svg');
  }

  .icon-event:before {
    background-image: url('../../../assets/svg/addBookingSidebar/event-room-icon.svg');
  }

  .icon-meeting:before {
    background-image: url('../../../assets/svg/addBookingSidebar/meeting-room-icon.svg');
  }
}

.location-booked-title.items-style1{
  margin-top: 5px;
  margin-bottom: 0px;
  line-height: 36px;
}

.office-booked {
  .office-booked-details,
  .office-booked-details-available,
  .office-booked-details-unavailable,
  .booking-office-rules .rule-available,
  .booking-office-rules .rule-unavailable,
  .booking-office-services .package-available,
  .booking-office-services .package-unavailable,
  .booking-office-services .service-available,
  .booking-office-services .amenity-available,
  .booking-office-services .service-unavailable {
    .label{
        font-size: 24px;
        
    }
  }

  .booking-office-services{
    .service-available-nonfree{
      flex-wrap: wrap;
      .sublabel{
        display: inline-block;
        div {
          margin-bottom: 3px;
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}

.not-booked-location .not-booked-office-space-item{
  margin-bottom: 25px;
}
.not-booked-location{
  .not-booked-office-space-item{
    .not-booked-office-space-item-label {

    }
  }
}

.not-booked-office-space-item-small{
  display: flex;
  margin-bottom: 5px;
  .not-booked-office-space-item-label {
    width: 70%;
    font-size: 14px;
    color: $gray1;
  }
  .not-booked-office-space-item-value {
    width: 30%;
    text-align: right;
    font-size: 14px;
    color: $gray1;

  }
}

.not-booked-location .not-booked-office-space-item--highlight {
  .not-booked-office-space-item-label, .not-booked-office-space-item-value {
    font-weight: 700;
  }
}
