.booking-recommendation__container {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
}

.booking-recommendation__office-title {
  @include text-typography($font-family-cera-regular);
  font-size: $font-size-title;
  color: $color-grey-white;
  margin-bottom: 5px;
}

.booking-recommendation__office-address {
  margin-bottom: 38px;

  .office-address-line1,
  .office-address-line2 {
    @include text-typography($font-family-cera-regular);
    color: $color-grey;
  }

  .office-address-line1 {
    span {
      @include text-typography($font-family-cera-regular);
      color: $color-grey;
      white-space: nowrap;
    }
  }
}

.booking-recommendation__button {
  margin-bottom: 22px;
}
.booking-recommendation__office {
  flex: 1;
}

.booking-recommendation__office-space {
  @include text-typography($font-family-cera-regular);
  color: $color-grey-white;
  margin-bottom: 6px;
}

.booking-recommendation__office-space-footer {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(4, 30%);
  align-self:stretch;
  height: 40px;

  .booking-recommendation-footer__item {
    display: flex;
    align-items: center;
    margin-right: auto;
    padding: 10px 10px 10px 0;

    &:hover {
      border-radius: 6px;
      background-color: $color-grey-medium;
      cursor: pointer;
      margin-left: -10px;
      padding-left: 10px;

      .booking-recommendation-footer__item-icon {
        display: block;
      }

      .booking-recommendation-footer__item-icon--hover {
        display: none;
      }
    }
  }

  .booking-recommendation-footer__item-count {
    margin-left: 5px;
  }
}

.booking-recommendation-footer__item-icon {
  display: block;
}
.booking-recommendation-footer__item-icon--hover {
  display: none;
}

.booking-recommendation__image-container {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: 3px;
  height: 157px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: $color-grey-medium;
  .booking-recommendation-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: cover;
    height: 157px;
    transition: transform 0.08s ease-in-out;
  }
  .booking-recommendation-image__info-container {
    display: flex;
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    height: 30px;
    width: 44px;
    background-color: $color-grey-background;
    border-radius: 3px;
    padding: 8px;
    transition: all 0.08s ease-in-out;
    .booking-recommendation-image__info {
      display: flex;
      position: absolute;
      top: 8px;
      right: 7px;
      .booking-recommendation-image__info-count {
        @include text-typography($font-family-cera-regular);
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        color: $color-white;
        margin-left: 4px;
      }
    }
  }
  .booking-recommendation__overlay-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @include text-typography($font-family-cera-regular, 14px);
    color: $color-yellow-light;
    cursor: pointer;
    opacity: 0;
    transition: all 0.08s ease-in-out;

    &.overlay-text-no-photos {
      color: $color-grey-light;
      opacity: 1;
    }
  }

  &:hover {
    .booking-recommendation-image {
      transform: scale(1.1);
    }
  }
  &.image-container-no-photos {
    @include default-border($dashboard-border-size, $color-grey-light);
  }
}
.booking-recommendation__container {
  .premium-partner__container {
    position: absolute;
    z-index: 1;
    padding: 10px;
  }
  .health__stamps-container {
    top: 110px;
    .health__stamps-container--health, .health__stamps-container--temperature {
      z-index: 1;
    }
  }
  .health__stamps-tooltip:after {
    left: 2px;
  }
}
.base-modal__see-availability-button {
  text-transform: uppercase;
  @include text-typography($font-family-cera-bold, 14px);
  line-height: 0.64;
  letter-spacing: normal;
  text-align: center;
  color: #131e29;
  width: auto;
  height: fit-content;
  border-radius: 3px;
  border: solid 2px #f0b323;
  background-color: #f0b323;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
  margin-left: 30px;
  cursor: pointer;
  margin-top: 0;
}

@media (max-width: 1360px) {
  .booking-recommendation__office-title {
    font-size: $font-size-title-small;
  }
  .booking-recommendation__office-address {
    margin-bottom: 12px;
    .office-address-line1,
    .office-address-line2,
    .office-address-line1 span {
      font-size: $font-size-default-small;
      line-height: 1.29;
    }
  }
  .booking-recommendation__button {
    margin-bottom: 16px;
  }
  .booking-recommendation__office-space {
    margin-bottom: 0px;
    font-size: $font-size-default-small;
  }
}
