.hero-profile__container {
    padding: 60px 27px;
    .hero-profile__welcome-message {
        @include text-typography($font-family-cera-regular, 30px);
        color: $color-grey-white;
        padding: 6px 5px;
    }
    .hero-profile__profile-message {
        @include text-typography($font-family-cera-regular, 16px);
        color: $color-grey;
        padding: 0px 5px;
        display: inline;
    }
}
.profile-form__form {
    padding-top: 35px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 0.56fr;
    grid-column-gap: 180px
}  
.profile-form__left {
    position: relative;
}
.profile-form__label, .profile-form__delete-section {
    @include text-typography($font-family-cera-regular, 14px);
    color: $color-grey;
    
} 
.profile-form__label-checkbox {
    margin-left: 30px;
    &.profile-form__label-checkbox-checked {
        color: $color-grey-white;
    }
}  
.profile-form__row {
    display: flex;
    flex-direction: row;
    margin: 11px 0px;
    &.profile-form__row-margin-top {
        margin-top: 35px;
    }
    .profile-form__columns {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 30px;
    }
}
.profile-form__top-buttons {
    position: absolute;
    top: 30px;
}
.profile-form__field {
    width: 100%;
    color: $color-grey;
    .profile-form__label {
        margin: 10px 0px;
    }
}
.profile-form__label-error {
    color: $color-red-light;
}
.profile-form__error-text {
    @include text-typography($font-family-cera-medium, 14px);
    color: $color-red-light;
}
.profile-form__input {
    @include text-typography($font-family-cera-regular, 16px);
    color: $color-grey;
    width: 100%;
    background-color: $color-black;
    border: 2px solid $color-grey-medium-alt;
    border-radius: 5px;
    padding: 13px;
    &.profile-form__textarea {
        height: 168px;
        outline: none;
        margin-bottom: 30px;
    }
    &.profile-form__input-value {
        color: $color-grey-white;
        border: 2px solid $color-grey-white;
    }
    &.profile-form__input-error {
        border: 2px solid $color-red-light;
    }
    &:focus {
        border: 2px solid $color-grey-white;
    }
    &.profile-form__textarea.profile-form__label-error {
        border: 2px solid $color-red-light;
    }
}
.profile-form__checkbox {
    position: absolute;
    width: 20px;
    height: 20px;
    border: 2px solid $color-grey-medium-alt;
    border-radius: 3px;
    cursor: pointer;
    &.profile-form__input-value {
        color: $color-grey-white;
        border: 2px solid $color-grey-white;
    }
    .icon {
        color: white;
        &.icon-not-checked {
            display: none;
        }
        &.icon-checked {
            display: block;
        }
    }
}
.profile-form__right {
    .profile-form__picker {
        margin-top: 22px;
        
    }      
}
.profile-form__tags {
    margin-top: 6px;
}
.profile-form__tag {
    display: inline-block;
    margin: 10px 20px 10px 0px;
    padding: 7px;
    border: 2px solid #323e47;
    border-radius: 3px;
    text-transform: uppercase;
    font-family: "CeraPRO-Bold";
    font-size: 14px;
    background-color: $color-grey-medium-alt;
    color: #7c878e;
    text-transform: uppercase;
    &:hover {
        cursor: pointer;
    }
}
.profile-form__tag-checked {
    background-color: $color-yellow;
    color: $color-black;
    border-color: $color-yellow;
}
.profile-form__button {
    text-transform: uppercase;
    width: auto;
    height: 50px;
    margin: 38px 23px 0px 0px;
    border-radius: 3px;
    padding: 0px 27px;
}
.profile-form__delete-section {
    border: 1px solid $color-grey-medium-alt;
    border-radius: 5px;
    text-align: end;
    padding: 5px 10px;
    width: fit-content;
    position: absolute;
    right: 0;
    &:hover {
        color: $color-grey-white;
        border-color: $color-grey-white;
        cursor: pointer
    }
}

.add-experience__button {
    @include text-typography($font-family-cera-bold);
    background-color: $color-yellow;
    color: $color-black;
    border: solid 2px $color-yellow;
}

.save-information__button {
    outline: 0;
    background: none;
    font-size: $font-size-default;
    letter-spacing: normal;
    color: $color-yellow;
    transition: transform 0.08s;
    font-family: $font-family-cera-bold;
    border: solid 2px $color-yellow;
    &:hover {
        color: $color-yellow-light;
    }
    &:active {
        transform: translateY(2px);
    }
}

@media screen and (max-width: 1600px) {
    .profile-form__form {
        grid-template-columns: 1fr 0.85fr;
        grid-column-gap: 140px;
    }
}
@media screen and (max-width: 1200px) {
    .hero-profile__container .hero-profile__profile-message {
        display: block;
    }
    .profile-form__row.profile-form__top-buttons {
        top: 50px;
        flex-direction: column-reverse;
    }
    .profile-form__button {
        margin-top: 10px;
    }
}