%typography-ceraGR {
	font-family: 'CeraPRO-Light';
	font-style: normal;
	font-weight: normal;
}

%typography-tabItem {
	@extend %typography-ceraGR;

	color: $gray1;
	font-size: 14px;
	font-weight: bold;
}

%typography-fieldTitle {
	@extend %typography-tabItem;
}

%typography-tabItem-active {
	@extend %typography-tabItem;

	border-bottom: 3px solid $yellow1;
	color: $white1;
}

%typography-mainSectionTitle {
	@extend %typography-ceraGR;

	color: $gray4;
	font-size: 14px;
	font-weight: bold;
}

%typography-items-style1 {
	@extend %typography-ceraGR;

	color: $white1;
	font-size: 24px;
}

%typography-items-style2 {
	@extend %typography-items-style1;

	color: $gray4;
}

%typography-sectionHeaders {
	@extend %typography-ceraGR;

	color: $gray1;
	font-size: 14px;
	font-weight: bold;
}

%typography-pageTitles {
	@extend %typography-ceraGR;

	color: $white1;
	font-size: 24px;
	font-weight: 500;
}

%typography-pageTitles-description {
	@extend %typography-pageTitles;

	color: $gray1;
}

%typography-fieldsDescription {
	@extend %typography-ceraGR;

	color: $gray5;
	font-size: 16px;
	//font-style: italic;
}

%typography-companyName {
	@extend %typography-ceraGR;

	color: $white1;
	font-size: 36px;
}

%typography-default {
	@extend %typography-ceraGR;

	color: $white1;
	font-size: 18px;
}

.page-title {
	@extend %typography-pageTitles;
}
