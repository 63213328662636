.create-new-post-main-container {
  position: relative;
}
.create-new-post-modal {
  display: block;
  position: absolute;
  z-index: 1001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  .new-post-top-section {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 22px;
    min-height: 100px;
  }

  .new-post-close-modal {
    position: absolute;
    right: 0;
    margin-right: 20px;
    cursor: pointer;
    z-index: 1;
  }

  .new-post-wrapper {
    opacity: 1;
    background-color: $color-grey-medium;
    margin: 140px auto 0 auto;
    min-height: 330px;
    border-radius: 6px;
    padding: 20px 25px 25px;
    position: relative;

    .validation-errors {
      font-family: $font-family-cera-regular;
      color: $color-red-light;
      font-size: 14px;
      margin-top: 5px;
    }
  }

  .new-post-wrapper__loader {
    width: 96px;
    height: 30px;
    background-image: url('../../../assets/images/loader.gif');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .new-post-textarea-input {
    caret-color: $color-yellow;
    background-color: $color-grey-medium;
    border: none;
    width: 90%;
    color: $color-grey;
    font-family: $font-family-cera-medium;
    font-size: 16px;
    resize: none;
    outline: none;
    line-height: 1.5;
    min-height: 80px;
  }

  .new-post-middle-section {
    min-height: 100px;
    margin-bottom: 25px;
  }

  .new-post-bottom-section {
    display: flex;
    justify-content: space-between;
  }

  .create-new-post-btn {
    width: 96px;
    height: 40px;
    padding: 10px;
    margin: 0;
  }

  .create-new-post-btn--disabled {
    opacity: 0.3;
  }

  .icons-wrapper {
    display: flex;
    align-items: center;
  }

  .icons-wrapper-item-img {
    display: block;
  }

  .icons-wrapper-item-img--hover,
  .icons-wrapper-item-img--disabled {
    display: none;
  }

  .icons-wrapper-item {
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    margin-right: 20px;
  }

  .icons-wrapper-item.active-item,
  .icons-wrapper-item:hover{
    background-color: $color-yellow;
    border-radius: 3px;

    .icons-wrapper-item-img--hover {
      display: block;
    }

    .icons-wrapper-item-img {
      display: none;
    }
  }

  .attachment__input {
    display: none;
  }

  .icons-wrapper-item.disabled-item {
    background-color: $color-grey-medium;
    cursor: not-allowed;

    .icons-wrapper-item-img--disabled {
      display: block;
    }

    .icons-wrapper-item-img,
    .icons-wrapper-item-img--hover {
      display: none;
    }
  }
}

.create-post-attachment {
  width: 300px;
  height: 100px;
  border-radius: 6px;
  border: solid 1px $color-grey-light;
  display: flex;
  align-items: center;
  padding: 30px;

  .attachment-icon {
    width: 34px;
    margin-right: 18px;
  }

  .attachment-details-wrapper {
    width: 115px;
    margin-right: 32px;
  }

  .attachment-name {
    margin-bottom: 5px;

    span {
      color: $color-grey-white;
      font-family:  $font-family-cera-regular;
      font-size: 16px;
    }
  }

  .attachment-size {
    color: $color-grey;
    font-family:  $font-family-cera-regular;
    font-size: 14px;
  }

  .attachment-delete {
    width: 40px;
    height: 40px;
    background-color: $color-yellow;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
  }
}

.upload-progress-bar__container {
  display: flex;
  position: relative;
}

.upload-progress-bar__info {
  width: 100%;
  .upload-progress-bar__indicator {
    background-color: #dee4e9;
    height: 5px;
    .floater {
      background-color: orange;
      width: 0%;
      height: 5px;
    }
  }

  .upload-progress-bar__filename {
    padding: 10px 0px;
    @include text-typography($font-family-cera-medium, 16px);
  }
  .upload-progress-bar__status {
    padding: 5px 0px;
    display: flex;
    justify-content: space-between;
    .upload-progress-bar__size,
    .upload-progress-bar__percent {
      @include text-typography($font-family-cera-regular, 14px);
      color: $color-grey-white;
    }
    .upload-progress-bar__size {
      color: $color-grey;
    }
  }

}

.upload-progress-bar__img {
  margin-right: 30px;
}

.upload-progress-bar-cancel {
  position: absolute;
  right: 0;
  top: 10px;
  cursor: pointer;
}
.embedded-media__container {
  padding-bottom: 15px;
}

.create-post-modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(19, 30, 41, 0.8);
  z-index: 100;
}