.gotoTeamsButton{
    position: relative;
    padding-bottom: 20px;

    .myBookings-link.secondary-button{
      margin-top: auto;
      border-color: #7a83eb;
      color: #7a83eb;
      &:hover{
        opacity: 0.8;
      }
      white-space: nowrap;
    }

    .disabled{
      display:none;
    }

    img{
      margin-top: -5px;
      position: absolute;
      margin-left: 50%;
      transform: translateX(-50%);
    }

    span{
      position: absolute;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #7c878e;
      text-transform: uppercase;
      margin-top: -6px;
    }
}

.gotoTeams__parent-position-relative {
  position: relative;
}

.gotoTeams__child-position-absolute {
  position: absolute;
  top: 0px;
}

.gotoTeams__office-booked-title {
  margin-top: 0px!important;
}