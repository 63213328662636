
.see-all-members__grey-container {
  background-color: $color-white-light-grey;
  margin-top: 30px;
  padding: 10px 10px 0px 10px;
  .community-form__search-wrapper {
    padding: 20px;
    &::before {
      top: 28px;
      left: 36px;
    }
  }
}
