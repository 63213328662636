.community-grid {
  display: flex;
  flex-direction: row;
  padding: $community-spacing;

  .pageHeader {
    margin-top: 15px;
  }
}

.community-grid__column {
  margin-right: $community-spacing;
  &:last-child {
    margin-right: 0;
  }
  &.community-grid__column--left {
    flex: 2;
    flex-basis: 20px;
    min-width: 770px;
  }
  &.community-grid__column--right {
    flex: 1;
  }
}


@media only screen and (max-width: 1500px)  {
  .community-grid__column {
    &.community-grid__column--right {
      max-width: 420px;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 1350px)  {
  .community-grid__column {
    &.community-grid__column--right {
      max-width: 370px;
      width: 100%;
    }
  }
}