
.repay-form__container {
  background-color: #f0b323;
  padding: 20px;
  min-height: 322px;
  padding-top: 55px;
}

.repay-form__message-container {
  margin-bottom: 11px;
}

.repay-form__message {
  font-family: 'CeraPRO-Light';
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: center;
  color: #131e29;
  max-width: 768px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.repay-form__card-container {
  width: 389px;
  margin-left: auto;
  margin-right: auto;
}

.repay-form__card-container--bancontact {
  padding: 20px 0px;
  text-align: center;

  .alternative-payment-section {
    color: #131e29;
    padding-bottom: 10px;
  }
}