.booking-success {
  height: 100%;
  background-color: $blue1;

  &-title {
    @extend %typography-ceraGR;
    font-size: 14px;
    font-weight: bold;
    color: $gray1;
    text-transform: uppercase;
    margin: 40px 0 15px 0;
  }

  &-status {
    @extend %typography-ceraGR;
    font-size: 24px;
    color: $green2;
  }

  &-value {
    @extend %typography-ceraGR;
    font-size: 24px;
    font-weight: 500;
    color: $white1;
  }

  &-extend {
    @extend %typography-ceraGR;
    font-size: 14px;
    font-weight: bold;
    color: $yellow1;
    text-transform: uppercase;
    margin-top: 15px;
  }

  &-amenity {
    @extend %typography-ceraGR;
    font-size: 24px;
    color: $white1;
    position: relative;
    padding-left: 40px;
    margin-bottom: 20px;

    &:before {
      content: '';
      width: 15px;
      height: 20px;
      background-image: url(../../../assets/svg/addBookingSidebar/amenity-available.svg);
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      left: 10px;
    }
  }

   &-connection {
    width: 36px;
    height: 36px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 10px;
    position: relative;
    background-position: center;
    background-size: contain;

    &[data-label]:after {
      content: attr(data-label);
      position: absolute;
      background-color: $gray6;
      color: $gray4;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      line-height: 36px;
      text-align: center;
    }
  }

 &-content {
    @extend %addBooking-content;
  }
}
