@import '../../../theme/colors';
@import '../../../theme/typography';
@import '../../../theme/common/variables';
@import '../../../theme/common/reusable';

.navigation {
  background-color: $blue1;
  box-sizing: border-box;
  height: 100%;
  left: 0;
  padding-top: 108px;
  position: fixed;
  width: 100px;
  z-index: 1;

  &Avatar{
    cursor: pointer;
    &.opened {
      &:after{
        content:"\A";
        border-style: solid;
        border-width: 10px 10px 10px 0;
        border-color: transparent $yellow1 transparent transparent;
        position: absolute;
        right: -25px;
        top:15px;
      }
    }
  }

  &Item {
    &-icon {
      color: inherit;
      display: block;
      font-size: 32px;
      height: 32px;
      margin: 6px 0;
      text-align: center;
    }
    .badge{
      position: relative;
      top: -40px;
      left: 60px;
      width: 20px;
      height: 20px;
      background-color: $yellow1;
      border-radius: 50%;
      font-size: 12px;
      color: $gray6;
    }

    &-caption {
      @extend %typography-ceraGR;
      color: inherit;
      display: block;
      font-size: 10px;
      font-weight: bold;
      text-align: center;
    }

    &-anchor {
      color: $gray5;
      display: block;
      height: 50px;
      margin-top: 25px;
      text-decoration: none;
      width: 100%;

      &-active, &:hover, &:focus, &:active {
        color: #ffffff;

        .navigationItem-caption {
          display: block;
        }
      }

      &-active, &:active {
        color: $yellow1;

        &:hover, &:focus {
          color: $yellow1;
        }
      }
    }
  }

  &Avatar {
    border-radius: 50%;
    bottom: 40px;
    display: block;
    height: 50px;
    left: 25px;
    position: absolute;
    width: 50px;
  }
}

.impersonating {
  .navigation {
    &Avatar {
      bottom: 65px;
    }
  }
  .account-tooltip__container {
    bottom: 120px;
  }
}


.navigationItem-anchor {
  .navigation__events_icon {
    height: 34px;
    background-image: url('../../../assets/svg/navigation__events-icon.svg');
    background-repeat: no-repeat;
    background-position: center top;
  }
  &:hover {
    .navigation__events_icon {
      background-image: url('../../../assets/svg/navigation__events-icon--hover.svg');
    }
  }
  &:active, &.navigationItem-anchor-active {
    .navigation__events_icon {
      background-image: url('../../../assets/svg/navigation__events-icon--active.svg');
    }
  }
  .navigation__dashboard_icon {
    height: 34px;
    background-image: url('../../../assets/svg/navigation/dashboard-icon.svg');
    background-repeat: no-repeat;
    background-position: center top;
  }
  &:hover {
    .navigation__dashboard_icon {
      background-image: url('../../../assets/svg/navigation/dashboard-icon--hover.svg');
    }
  }
  &:active, &.navigationItem-anchor-active {
    .navigation__dashboard_icon {
      background-image: url('../../../assets/svg/navigation/dashboard-icon--active.svg');
    }
  }
  .navigation__health-measure_icon {
    height: 34px;
    background-image: url('../../../assets/svg/navigation/health-measure.svg');
    background-repeat: no-repeat;
    background-position: center top;
  }
  &:hover {
    .navigation__health-measure_icon {
      background-image: url('../../../assets/svg/navigation/health-measure--hover.svg');
    }
  }
  &:active, &.navigationItem-anchor-active {
    .navigation__health-measure_icon {
      background-image: url('../../../assets/svg/navigation/health-measure--active.svg');
    }
  }
}
.account-tooltip__container {
  display: none;
  position: absolute;
  bottom: 100px;
  text-align: center;
  left: 20px;
  opacity: 0.7;
}
.account-tooltip-arrow {
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  top: auto;
  bottom: -5px;
  margin-left: 25px;
  border-width: 5px 5px 0;
  border-top-color: $color-grey-medium;
}
.account-tooltip-label {
  background-color: $color-grey-medium;
  font-family: $font-family-cera-regular;
  font-size: 12px;
  width: 95px;
  text-align: center;
  padding: 10px 0;
}
.navigationAvatar:hover + .account-tooltip__container{
  display: unset;
}
.opened.navigationAvatar + .account-tooltip__container{
  display: none;
}
.navigationItem-inactive {
  pointer-events: none;
  opacity: 0.5;
}