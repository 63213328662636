@import '../../../theme/colors';

.check-availability {
  background-color: #333f48;
  height: calc(100% - 60px);
  width: 60vw;
  position: absolute;
  top: 30px;
  left: -60vw;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  -webkit-box-shadow: inset -7px 0px 6px 0px rgba(0, 0, 0, 0.25), -6px 6px 6px 0px rgba(0, 0, 0, 0.25), 0px -6px 6px 0px rgba(0, 0, 0, 0.25);
  box-shadow: inset -7px 0px 6px 0px rgba(0, 0, 0, 0.25), -6px 6px 6px 0px rgba(0, 0, 0, 0.25), 0px -6px 6px 0px rgba(0, 0, 0, 0.25);
  -webkit-transition: all 0.12s ease-in-out;
  -o-transition: all 0.12s ease-in-out;
  -webkit-transition: all 0.09s ease-in-out;
  -o-transition: all 0.09s ease-in-out;
  transition: all 0.09s ease-in-out;

  @media screen and (max-width: 1464px) {
    width: 54vw;
    left: -54vw;
  }

  @media screen and (max-width: 1286px) {
    width: 50vw;
    left: -50vw;
  }

  @media screen and (max-width: 1148px) {
    width: 46vw;
    left: -46vw;
  }

  @media screen and (max-width: 1067px) {
    width: 40vw;
    left: -40vw;
  }

  @media screen and (max-width: 1023px) {
    height: 74vh;
  }

  .check-availability__container {
    height: 100%;
    width: 100%;
    position: relative;
    padding: 15px;

    .check-availability__toggle {
      position: absolute;
      height: 50px;
      width: 40px;
      left: -40px;
      top: 193px;
      background-color: #ffffff;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      cursor: pointer;

      @media screen and (max-width: 1023px) {
        display: none;
      }

      .icon {
        color: $blue1;
        position: relative;
        top: 15px;
        left: 12px;
      }
    }
  }

  &.check-availability--closed {
    left: 0;

    .check-availability__container {
      .check-availability__toggle {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        -webkit-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);

        .icon {
          left: 10px;
        }
      }
    }
  }
}

.check-availability-label__container {
  margin-top: 38px;
  margin-bottom: -28px !important;

  .check-availability-label__text {
    display: inline;
    margin: 0;
    font-weight: 600;
    letter-spacing: 1px;
    cursor: pointer;
  }
}

.check-availability__content {
  height: 100%;

  .check-availability__action {
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    .check-availability__date-container,
    .check-availability__month-container {
      width: 50%;
      display: inline-block;
    }
    .check-availability__date-container {
      margin: 0;
      margin-right: 7px;
    }

    .check-availability__month-container {
      margin: 0;
      margin-left: 7px;
      .dropdown {
        border-radius: 3px;
        &.expanded {
          .container {
            height: 225px;
          }
        }
        .input {
          font-style: normal;
          color: #131e29;
          font-size: 16px;
        }
      }
    }
  }

  .check-availability__display {
    height: 100%;
    overflow: hidden;
  }
}

.check-availability-table {
  max-height: calc(100% - 70px);
  height: 100%;
  border-radius: 3px;

  .check-availability-table__table {
    width: 100%;
    margin: 0;

    .check-availability-table__header {
      background-color: #7c878e;

      tr {
        &:first-child {
          border-bottom: 1px solid #ffffff;
        }

        th {
          padding: 8px 10px;
          color: #ffffff;
          text-align: left;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 1px;
          border-right: 1px solid #ffffff;
          min-width: 80px;

          &:first-child {
            text-align: right;
            width: 100px;
          }

          &:last-child {
            border-right: none;
          }
        }
      }
    }

    .check-availability-table__body {
      background-color: #ffffff;

      tr {
        &:first-child {
          border-bottom: 1px solid #ffffff;
        }

        td {
          padding: 8px 10px;
          color: #333f48;
          text-align: left;
          font-size: 14px;
          letter-spacing: 0.6px;
          border-right: 1px solid #ffffff;

          &:first-child {
            text-align: right;
          }

          &:last-child {
            border-right: none;
          }

          &.check-availability-table__data--availabile {
            font-weight: 600;
          }
        }
      }
    }
  }
}

.check-availability-table__no-data {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-top: 30px;
}
