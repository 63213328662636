.premium-partner__container {
  padding: 11px;
  width: fit-content;
  &:hover {
    .health__stamps-tooltip--premium {
      opacity: 1;
    }
  }
}
.premium-partner__icon {
  width: 24px;
  height: 24px;
}
.upper-stamps .premium-partner__container {
  padding: 0;
  position: relative;
  top: 10px;
  left: 10px;
}