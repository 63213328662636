.card__most-active-heroes {
  height: 100%;
}

.active-hero__announcements-wrapper {
  margin-bottom: 10px;
}

.active-hero__announcement {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  cursor: pointer;
}

.active-hero__image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 20px;
}

.active-hero__details-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 20px;
  max-width: 370px;
}

.active-hero__name {
  @include text-typography($font-family-cera-regular, 16px);
  color: $color-grey-white;
}

.active-hero__job {
  @include text-typography($font-family-cera-regular, 14px);
  color: $color-grey;
}

.active-hero__message-button {
  background-color: unset;
  @include default-border($dashboard-border-size, $color-grey-medium, $dashboard-button-border-radius);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-top: 0;
  .active-hero__message-icon {
    display: block;
  }
  .active-hero__message-icon--hover {
    display: none;
  }
}

.active-hero__announcement:hover,
.active-hero__announcement:active {
  background-color: $color-grey-medium;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 100%;
  }

  &:before {
    background-color: $color-grey-medium;
    left: -20px;
    border-bottom-left-radius: $dashboard-card-border-radius;
    border-top-left-radius: $dashboard-card-border-radius;
  }

  &:after {
    background-color: $color-grey-medium;
    left: 100%;
    border-bottom-right-radius: $dashboard-card-border-radius;
    border-top-right-radius: $dashboard-card-border-radius;
  }

  .active-hero__job {
    color: $color-white;
  }

  .active-hero__message-button {
    border: unset;
    background-color: $color-white;
  }
  .active-hero__message-icon {
    display: none;
  }
  .active-hero__message-icon--hover {
    display: block;
  }
  .most-active-heroes-initials {
    background: #131e29;
  }
}