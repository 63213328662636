@import '../../../theme/colors';
@import '../../../theme/layout';

.directChat {
  display: flex;
  height: 100%;
  position: relative;
  .icon-search{
    &:before {
      content: ''!important;
      display: block;
      width: 25px;
      height: 25px;
      background: url('../../../assets/svg/header/icon-search.svg') no-repeat 50% 50%;
      position: absolute;
    }
  }
  .defaultAvatar2{
    background-image: url('./../../../assets/images/defaultAvatar.png');
    background-size: cover;

  }
  .insideChatAvatar{
    width: 40px!important;
    height: 40px!important;
    min-width: 40px!important;
    object-fit: contain;
    & + .directChat-messages-message{
      margin-top: 5px!important;
      //margin-left: 10px;
    }
  }

  &-contacts {
    &-container {
      display: flex;
      height: 100%;
      width: 25%;

      @media only screen and (max-width : $largeDevices) {
        width: 33.333333%;
      }
    }

    &Content {
      @import 'contactsList';
    }
  }

  &-messages {
    &-container {
      display: flex;
      height: 100%;
      width: 50%;

      @media only screen and (max-width : $largeDevices) {
        width: 66.666667%;
      }
    }

    &Content {
      @import 'messageWindow';
    }
  }

  &-aboutContent {
    @import 'aboutContact';

    @media only screen and (max-width : $largeDevices) {
      display: none;
    }
  }

  .rightSidebar {
    display: flex;

    &-content {
      display: flex;
      flex-grow: 1;
    }
  }
}

.directChat-contacts-container{
  padding-left: 30px;
}

.directChat-contactsContent .directChat-contacts-pageTitle::before {
  font-size: 33px;
  vertical-align: bottom;
}
.eventsContainer-mainContent .officesGrid-title-events,
.directChat-contactsContent .directChat-contacts-pageTitle,
.page-title.community-header,
.pageHeader-titleText{
  color: #a0abb2!important;
  font-size: 24px;
  line-height: 36px;
  font-style: normal;
}