.accordion__section {
  display: flex;
  flex-direction: column;
  padding: 26px 0;
}

.rooms-wrapper .accordion-wrapper{
  border-bottom: dashed 1px #4f575c;
  &:last-child {
    border: none;
  }
}

.accordion {
  background-color: #0f171f;
  color: #444;
  cursor: pointer;
  padding: 0 0 10px 0;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.1s ease;
}

.accordion__title {
  font-family: $font-family-cera-regular;
  font-size: 24px;
  text-align: left;
  cursor: pointer;
  &--cancelled {
    color: $color-red-light;
  }
}

.accordion__icon {
  margin-left: auto;
  transition: transform 0.3s ease;
}

.rotate {
  transform: rotate(180deg);
}

.accordion__text {
  font-family: $font-family-cera-regular;
  font-weight: 400;
  font-size: 14px;
}

.accordion_office {
  display: inline;
  color: #7c878e;
}

.accordion_office-comma {
  font-family: $font-family-cera-bold;
  font-size: 14px;
  color: #7a858c;
}

.accordion_office-greater {
  margin-left: 10px;
}

.accordion_office-name {
  font-family: $font-family-cera-bold;
  font-size: 14px;
  color: #F0B323;
  text-transform: uppercase;
  &:hover {
    text-decoration: underline;
  }
  span {
   font-family: $font-family-cera-bold;
   font-size: 14px;
    color: #7c878e;
    padding-left: 10px;
    display: inline-block;
  }
}

.accordion_meeting-default-response, .no-of-invited {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: $font-family-cera-bold;
  font-size: 14px;
  color: #ffffff;
  text-transform: uppercase;
}
.no-of-invited {
  color: #7c878e;
}

.accordion_meeting-response {
  font-family: $font-family-cera-bold;
  font-size: 14px;
  text-transform: uppercase;
  color: #f0b323;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.response_caret {
  margin-right: 5px;
}

.accordion__content {
  margin-top: 16px;
}

.accordion_invited-by,
.accordion_meeting-hours {
  display: flex;
  justify-content: space-between;
  font-family: $font-family-cera-regular;
  font-size: 14px;

  span {
    font-family: $font-family-cera-bold;
    font-size: 14px;
    color: #7c878e;
    text-transform: uppercase;
  }
  .working-days span {
    color: #fff;
  }
}

.accordion__dropdown--disabled {
  opacity: 0.4;
  pointer-events: none;
}

.accordion_invited-by {
  margin-bottom: 16px;
}

.accordion_hero-profile {
  font-family: $font-family-cera-medium;
  font-size: 14px;
  color: #f0b323;
}

.accordion_attendees {
  display: flex;
  justify-content: space-between;
  font-family: $font-family-cera-regular;
  font-size: 14px;
  margin-bottom: 16px;

  .attendees-text {
    font-family: $font-family-cera-bold;
    font-size: 14px;
    color: #7c878e;
    text-transform: uppercase;
  }

  .attendees-no {
    font-family: $font-family-cera-regular;
    font-size: 14px;
    color: #ffffff;

    span {
      font-family: $font-family-cera-regular;
      font-size: 14px;
      color: #7c878e;
    }
  }
}

.accordion_meeting-hours.with-margin-bottom {
  margin-bottom: 16px;
}