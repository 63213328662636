.popular-communities__container {
    border-radius: $entity-card-border-radius;
    background-color: $color-grey-dark;
    padding: 30px;

    .popular-communities__header {
        font-size: 16px;
        font-family: $font-family-cera-medium;
        color: $color-grey-light;
        margin-bottom: 5px;
    }
    .popular-communities__cards {
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;

        .community-card {
            margin-bottom: 15px;
            max-width: 46%;
        }
    }
    .popular-communities__display-all {
        display: flex;
        justify-content: flex-start;
        .entity-button {
            width: auto;
            font-family: $font-family-cera-medium;
        }
    }
}