@import '../../../theme/layout';

.mapInfo {
  display: flex;
  &-item {
    margin-left: 0;
    line-height: 33px;
    padding: 9px 0;
    display: flex;
    margin-right: 20px;
    &:first-child{
      margin-left: 0px;
    }
  }

  &-icon {
    display: inline-block;
    width: 28px;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin-right: 10px;
    vertical-align: middle;

    &.--parking {
      background-image: url('../../../assets/svg/map/parking.svg');
    }

    &.--partially {
      background-image: url('../../../assets/svg/map/partially.svg');
    }

    &.--available {
      background-image: url('../../../assets/svg/map/available.svg');
    }

    &.--parkingUnavailable {
      margin-left: 40px;
      background-image: url('../../../assets/svg/map/parking-unavailable.svg');
    }

    &.--partialUnavailable {
      background-image: url('../../../assets/svg/map/partially-unavailable.svg');
    }

    &.--normalUnavailable {
      background-image: url('../../../assets/svg/map/normal-unavailable.svg');
    }
    &.--comingUpUnavailable {
      background-image: url('../../../assets/svg/map/coming-up.svg');
    }
    &.--healthyMeasures {
      background-image: url('../../../assets/svg/map/health-safety-measures.svg');
    }
    &.--temperatureScanner {
      background-image: url('../../../assets/svg/map/temperature-control-in-place.svg');
    }
    &.--premiumPartner {
      background-image: url('../../../assets/svg/map/premium-partner.svg');
    }
  }

  &-label {
    font-size: 14px;
    font-weight: 500;
    color: $gray8;
    font-family: 'CeraPRO-Medium';
    max-width: 110px;
    display: inline-block;
    line-height: 20px;
  }

}
.markerInfo {
  cursor: pointer;
  &--coming-soon {
    background-image: none;
    .markerInfo-image-container {
      background-position: center;
      background-size: 30%;
      float: left;
    }
    .markerInfo-data {
      padding-bottom: 0;
    }
  }
}
.markerInfo-image-container {
  background-image: url('../../../assets/images/circle-loader-64px.gif');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10%;
}
.markerInfo-default-image {
  background-color: #131e29;
}
.markerInfo-image {
  width: 300px;
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  box-shadow: inset 0 -8px 6px -6px $blue1;
  -webkit-box-shadow: inset 0 -8px 6px -6px $blue1;
  -moz-box-shadow: inset 0 -8px 6px -6px $blue1;

  .health-measure-icon,
  .temperature-scanner-icon {
    position: absolute;
    width: 21px;
    height: 24px;
  }

  .health-measure-icon {
    background-image: url('../../../assets/svg/map/health-safety-measures.svg');
    background-size: contain;
    left: 5px;
    bottom: 5px;
  }
  .temperature-scanner-icon {
    left: 30px;
    bottom: 5px;
    background-image: url('../../../assets/svg/map/temperature-control-in-place.svg');
    background-size: contain;
  }
  .premium-partner-icon {
    position: absolute;
    width: 18px;
    height: 18px;
    left: 10px;
    top: 10px;
    background-image: url('../../../assets/svg/map/premium-partner.svg');
    background-size: contain;
  }
}

.mapInfo-item--coming-up {
  .mapInfo-label {
    max-width: 65px;
  }
}

.mapInfo-info--available {
  .mapInfo-label {
    max-width: 65px;
  }
}
.mapInfo-info--unavailable {
  .mapInfo-label {
    max-width: 80px;
  }
}
.mapInfo-item--health-measures {
  .mapInfo-label {
    max-width: 90px;
  }
}
.mapInfo-item--premium-partner {
  .mapInfo-label {
    max-width: 80px;
  }
}
.markerInfo-products__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 10px;
  grid-auto-rows: 1fr;
  margin-top: 15px;
}
.markerInfo-products__item {
  display: inline;
  .markerInfo-products__product-icon {
    display: inline-block;
    width: 26px;
    height: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin-right: 10px;
    vertical-align: middle;
  }
  .desk-icon {
    background-image: url('../../../assets/svg/map/desk-icon.svg');
  }
  .privateOffice-icon {
    background-image: url('../../../assets/svg/map/private-office-icon.svg');
  }
  .meetingRoom-icon {
    background-image: url('../../../assets/svg/map/meeting-room-icon.svg');
  }
  .eventRoom-icon {
    background-image: url('../../../assets/svg/map/event-room-icon.svg');
  }
}
.markerInfo--coming-soon {
  .premium-partner-icon {
    width: 18px;
    height: 18px;
    background-image: url('../../../assets/svg/map/premium-partner.svg');
    background-size: contain;
    background-repeat: no-repeat;
    padding: 0 12px;
    margin-top: 2px;
  }
}
.markerInfo__coming-soon {
  background-color: $gray1;
  text-align: center;
  padding: 3px 0;
  width: 300px;
  div {
    font-family: 'CeraPRO-Bold';
    font-size: 12px;
    color: $black6;
    text-transform: uppercase;
  }
}