@import '../../../theme/colors';

.requestExport {
  .modal-close-button{
    background: url('../../../assets/svg/accountProfile/close-icon.svg') no-repeat;
    height: 25px;
    width: 25px;
    float: right;
    margin-right: -10px;
    &:hover{
      cursor: pointer;
    }
  }
  &-description {
    color: $gray1;
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 30px;
  }

  .items-style1 {
    color: $gray1;
  }

  &-warning {
    color: $red2;
    font-size: 18px;
    margin-top: 44px;
    margin-bottom: 19px;
  }

  &-actions {
    text-align: right;
    padding-top: 30px;
    button{
      margin-top: 0px;
    }
  }

  &-blockButton, &-cancelButton {
    text-transform: uppercase;
    width: auto !important;
    display: inline-block !important;
    margin-left: 30px !important;
  }

  .main-button{
    color: $white1;
  }

  &-blockButton {
    &.secondary-button {
      border: 0 none;
      padding: {
        left: 0;
        right: 0;
      }
      color: $gray1;

      &:focus{
        outline: none;
      }

      &:hover, &:active {
        color: $gray7;
      }
    }
  }
}
