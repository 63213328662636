.office-schedule {

  &-title {
    @extend %officeDetails-titleBorder;
  }

  &-subTitle {
    @extend %typography-companyName;
    margin: 20px 0;
  }

  &-fieldDescription {
    @extend %typography-fieldsDescription;

    margin-top: 10px;
  }
}