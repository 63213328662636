.cancelActionContainer{
  position: relative;
  padding-bottom: 20px;

  .myBookings-link.secondary-button{
    margin-top: auto;
    border-color: #ff4a4a;
    color: #ff4a4a;
    &:hover{
      border-color: #ff7f7f;
      color: #ff7f7f;
    }
  }

  .disabled{
    display:none;
  }

  img{
    margin-top: -5px;
    position: absolute;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  span{
    position: absolute;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #7c878e;
    text-transform: uppercase;
    margin-top: -6px;
  }
}

.office-cancelation {
  margin-bottom: 30px;
  &-disclaimer {
    font-family: 'CeraPRO-Light';
    font-size: 18px;
    font-weight: 100;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
  }
  &.office-cancelation-location-booked {
    .office-cancelation-title {
      padding-top: 0;

      &:before {
        display: none;
      }
    }
  }

  &-title {
    @extend %officeDetails-titleBorder;
  }

  &-header {
    @extend %displayFlex-nowrap;
    margin-top: 70px;
  }

  &-condition {
    @extend %displayFlex-nowrap;
    margin-top: 20px;
  }

  &-percentage {
    @extend %displayFlex-nowrap;
    margin-top: 10px;
  }

  &-legend {
    width: 220px;
    color: $gray1;
  }

  &-column {
    width: 180px;
    margin-left: 40px;
    color: $gray1;

    & > span {
      font-size: 24px;
    }
  }

}
