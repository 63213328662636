.card__community-activity {
  height: auto;
}

.community-activity__post-wrapper {
  margin-bottom: 15px;
}

.community-post {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  min-height: 80px;
  cursor: pointer;
  width: 100%;
}

.community-post__title {
  @include text-typography($font-family-cera-regular, 16px);
  color: $color-grey-white;
  margin-bottom: 3px;
}

.community-post__author {
  @include text-typography($font-family-cera-regular, 14px);
  color: $color-grey;
}

.community-post__details-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 420px;
  width: 100%;
  flex: 1;
}

.community-post__image-container {
  max-width: 60px;
  width: 60px;
  max-height: 60px;
  height: 60px;
  margin-right: 20px;
  overflow: hidden;
  object-fit: contain;
}

.community-post__image {
  width: 60px;
  height: 60px;
  border-radius: $dashboard-border-size;
}


.community-post:hover,
.community-post:active {
  background-color: $color-grey-medium;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 100%;
  }

  &:before {
    background-color: $color-grey-medium;
    left: -20px;
    border-bottom-left-radius: $dashboard-card-border-radius;
    border-top-left-radius: $dashboard-card-border-radius;
  }

  &:after {
    background-color: $color-grey-medium;
    left: 100%;
    border-bottom-right-radius: $dashboard-card-border-radius;
    border-top-right-radius: $dashboard-card-border-radius;
  }

  .community-post__image {
      transform: scale(1.08);
      transition: 0.4s ease;
  }

  .community-post__author {
    color: $color-white;
  }
}