@import '../../../theme/colors';
.language-icon {
  width: 20px;
  cursor: pointer;
}
.language-list {
  border-radius: 3px;
  position: absolute;
  background-color: #0f171e;
  top: 50px;
  right: -70px;
  width: 150px;

  &:before{
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #0f171e;
    top: -12px ;
    right: 68px;
    position: absolute;
    z-index: 120;
  }
  li {
    padding: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
      padding-left: 10px;
      font-family: CeraPRO-Regular;
      font-size: 16px;
      color: #7c878e;
      &:hover {
        color: $yellow1;
      }
    }
  }
}

.leave-us-feedback {
  font: inherit;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: #f0b323;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 100%;

  .callToFeedback{
    position: relative;
    font: inherit;
    font-size: 14px;
    text-transform: uppercase;
    display: inline-block;
    color: #f0b323;
    cursor: pointer;
    font-family: 'CeraPRO-Medium';
    font-weight: normal;
  }
  .arrow{
    width: 6px;
    height: 11px;
    cursor: pointer;
    position: relative;
    z-index: 120;
    display: inline-block;
    font-size: 0;
    vertical-align: middle;
    background: url('./../../../assets/svg/officeBox/arrow-yellow.svg') no-repeat center center ;
    background-size: contain;
    margin-right: 10px;
    margin-left: 10px;
  }
  &:hover{
    color: #ffc824;
    .callToFeedback{
      color: #ffc824;
    }
    span{
      background: url('./../../../assets/svg/officeBox/arrow-yellow-hover.svg') no-repeat center center ;
    }
  }
}

.feedback {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  //width: 90%;
  position: relative;
}

.feedback.notifications {
  &>span {
    height: 100%;
  }

  .edit-section{
    min-height: 165px;
    height: 100%;
    position: relative;
    .feedbackLoader{
      height: 100%;
      img{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .notifications-icon-on {
    cursor: pointer;
    display: block;
    text-align: center;
    width: 17px;
    height: 26.7px;
    margin-top: 2px;
    float: right;
    font-size: 12px;
    color: $gray6;
    font-weight: bold;
    background: url('../../../assets/svg/header/yellow-light.svg') no-repeat 50% 50% !important;
    position: relative;
  }
  .notifications-icon {
    cursor: pointer;
    display: block;
    text-align: center;
    width: 17px;
    height: 26.7px;
    background: url('../../../assets/svg/header/gray-light.svg') no-repeat 50% 50%;
    float: right;
    font-size: 12px;
    color: $gray6;
    font-weight: bold;
  }
  .notifications-results{
    right: -23px;
    top: 50px;
    padding-bottom: 5px;
    .edit-section {
      padding: 20px;
      background-color: #fffbf3;
      border-top: 1px solid #e6e7e8;
      .edit-section-cell{
        padding-bottom: 20px;
      }
      textarea{
        width: 100%;
        background-image: linear-gradient(to top, #ffffff, #f6f6f6);
        font-family: 'CeraPRO-Light';
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: left;
        color: #131e29;
        padding: 13px 12px 14px;
        min-height: 57px;
        min-width: 100%;
        box-sizing: border-box;
        flex-grow: 1;
        outline: none!important;
        border: 1px solid #cdd1d4;
        border-radius: 3px;
        margin-bottom: 20px;
        &:hover,
        &:focus{
          outline: none!important;
          background-image: linear-gradient(to top, #fffefa, #fffcf3)!important;
          border: 1px solid #ffd87d!important;
        }

      }
      button {
        border-radius: 3px;
        border: solid 2px #f0b323;
        font-family: 'CeraPRO-Medium';
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        background-color: transparent;
        color: #f0b323;
        padding-top: 12px;
        padding-bottom: 12px;
        text-transform: uppercase;
        cursor: pointer;
        //margin-top: 20px;
        //margin-bottom: 20px;
      }
    }
  }
}
.notifications {
  margin-right: 30px;
  ul{
    li.noNotifications{
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.44;
      letter-spacing: normal;
      color: #7c878e;
      text-align: center;
      //text-transform: uppercase;
      width: 100%;
      margin: auto 0;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      padding: 0px;
      display: block;
      border-top: none;
    }
    li + li.noNotifications{
      display: none;
    }
  }

  &-icon {
    cursor: pointer;
    display: block;
    text-align: center;
    width: 25px;
    height: 30px;
    padding-top: 7px;
    background: url('../../../assets/svg/header/notifications-off.svg') no-repeat 50% 50%;
    float: right;
    font-size: 12px;
    color: $gray6;
    text-align: center;
    font-weight: bold;

    &-on {
      background: url('../../../assets/svg/header/notifications-on.svg') no-repeat 50% 50%;
    }
  }

  &.notifications-active {
    .notifications-results {
      display: block;
    }
  }

  .notifications-results {
    display: none;
    border-radius: 3px;
    position: absolute;
    background-color: $white1;
    right: 12px;
    width: 408px;
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.15);
    top: 70px;
    z-index: 120;

    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 12px solid $white1;
      top: -12px;
      right: 19px;
      position: absolute;
      box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.15);
      z-index: 120;
    }

    &-header {
      background-color: $white1;
      border-radius: 3px;
      padding: 9px;
      position: relative;
      z-index: 120;
      display: flex;

      span {
        color: $gray9;
        font-family: 'CeraPRO-Medium';
        display: block;
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
        flex: 1;
        text-align: left;
      }

      a.markAllAsRead {
        color: $yellow1;
        font-family: 'CeraPRO-Medium';
        display: block;
        font-weight: bold;
        font-size: 10px;
        text-transform: uppercase;
        text-decoration: none;
        flex: 1;
        text-align: right;
        &:hover{
          color: $yellow1;
          cursor: pointer;
        }
      }
    }

    li {
      border-top: 1px solid $gray11;
      padding: 20px;
      display: flex;

      &.notification-unseen {
        background-color: $yellow7;
      }

      .notifications-result-image {
        background-color: $blue1;
        background-size: cover;
        background-position: center center;
        min-width: 45px;
        border-radius: 3px;
        height: 45px;
        flex: 0 0 13%;
      }

      .notifications-result-details {
        padding-left: 9px;
        flex: 1;
        text-align: left;
        >p {
          font-size: 14px;
          line-height: 16px;
          color: $blue1;
          min-height: 32px;
          font-family: CeraPRO-Regular;

          span {
            color: $blue1;
            font-weight: bold;
            font-size: 14px;

            &.tertiary-button {
              font-size: 14px;
              text-transform: none;
              margin-left: 0;
            }
          }
        }

        .notifications-result-time {
          font-size: 10px;
          line-height: 18px;
          color: $gray10;
          display: block;
        }
        .secondary-action {
          margin-left: 20px;
        }

        .tertiary-button {
          font-weight: bold;
          color: $yellow1;
          margin-left: 18px;
          margin-top: 16px;
          font-size: 14px;
          text-transform: uppercase;
          cursor: pointer;
        }

        .primary-button-leftSide {
          background-color: $yellow1;
          border-radius: 3px;
          font-family: 'CeraPRO-Bold';
          color: $blue1;
          font-size: 14px;
          text-transform: uppercase;
          cursor: pointer;
          padding: 16px;
        }

        .simple-button-link {
          color: #7c878e;
          font-family: 'CeraPRO-Regular';
          margin-left: 18px;
          margin-top: 16px;
          font-size: 14px;
          cursor: pointer;
        }

        .secondary-button-leftSide {
          font-family: 'CeraPRO-Medium';
          position: relative;
          text-transform: uppercase;

          &.bookedOffice-directions {
            width: 128px;
          }

          &.button-drive {
            border-radius: 100px;
            padding: 0px 18px 0 33px;

            &:before {
              content: '';
              display: block;
              width: 18px;
              height: 18px;
              position: absolute;
              left: 9px;
              top: 11px;
              background: url('../../../assets/svg/header/plan-drive.svg') no-repeat 50% 50%;
            }
          }
        }

        .notifications-result-start-time {
          font-size: 14px;
          color: $blue1;
          margin-left: 10px;
          line-height:
                  b {
            font-size: 14px;
            color: $blue1;
            font-weight: 500;
          }
        }
      }
    }

    &-footer {
      a {
        border-top: 1px solid $gray11;
        padding: 8px;
        display: block;
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none;
        text-align: center;
        color: $yellow1;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .notifications {
    min-width: auto;
  }
  .feedback {
    &.notifications {
      min-width: auto;
    }
  }
}

