.community-card {
  display: flex;
  padding: 10px 0;
  align-items: center;
  width: 100%;

  .community-card__image {
    width: 60px;
    height: 60px;
    margin-right: 20px;
    border-radius: $entity-card-border-radius;
    overflow: hidden;
    object-fit: contain;
  }

  .community-card__details {
    flex: 1;
    margin-right: 15px;
  }

  .community-card__based-on-info {
    font-family: $font-family-cera-regular;
    font-size: 12px;
    color: $color-grey;
    font-style: italic;
    margin-bottom: 5px;
  }

  .community-card__name {
    max-width: 309px;
    margin-bottom: 4px;

    span {
      font-size: 16px;
      color: $color-grey-white;
      font-family: $font-family-cera-medium;
      line-height: 1.38;
    }
  }

  .community-card__footer {

    .dot-between__footer {
      margin: 0 3px;
    }

    span{
      font-size: 14px;
      color: $color-grey;
      font-family: $font-family-cera-medium;
    }
  }

  .community-card__svg {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    vertical-align: middle;
  }

  .dashboard-button.dashboard-button--secondary {
    padding: 0 17px;
  }

}

.community-card:hover,
.community-card:active {
  background-color: $color-grey-medium;
  position: relative;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 100%;
  }

  &:before {
    background-color: $color-grey-medium;
    left: -20px;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }

  &:after {
    background-color: $color-grey-medium;
    left: 100%;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }

  .community-card__footer {
    color: $color-white;
  }

  .dashboard-button--secondary{
    border: unset;
    background-color: $color-white;
    color: $color-black;
    border: 2px solid $color-grey-medium;
    border-radius: 4px;
  }

}