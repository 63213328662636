@import './colors';

button {
  color: $black1;
  background-color: $gray14;
  border: 0 none;
  outline: none;
}

.special-button {
  color: $red2;
  background-color: $red3;
}

.clicked-button {
  color: $blue1;
  background-color: $blue2;
}