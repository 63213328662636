.card__co-creation-projects {
  height: auto;
  .co-creation-project__container {
    .co-creation-project__header {
      display: flex;
      flex-direction: row;
      margin-bottom: 29px;
      max-width: 100%;
      .co-creation-project__thumb-container {
        width: 60px;
        height: 60px;
        max-width: 60px;
        max-height: 60px;
        overflow: hidden;
        object-fit: contain;
        border-radius: 8px;
        margin-right: 19px;
        .co-creation-project__thumb:hover {
          transform: scale(1.08);
          transition: 0.4s ease;
          cursor: pointer;
        }
      }
      .co-creation-project__thumb-container-no-thumb {
        display: flex;
        justify-content: center;
        align-items: center;
        @include default-border($dashboard-border-size, $color-grey-light);
        .co-creation-project__no-thumb {
          font-size: 11px;
        }
      }
      .co-creation-project__description {
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-width: 425px;
        width: 100%;
        flex: 1;
        span {
          @include text-typography($font-family-cera-medium);
          font-size: $font-size-title;
          color: $color-grey-white;
        }
        &:hover {
          cursor: pointer;
          span {
            color: $color-white;
          }
        }
      }
    }
    .co-creation-project__button {
      margin: 20px 0 15px 0;
    }
    .co-creation-project__body {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .co-creation-project__remuneration {
        width: 50%;
      }
      .co-creation-project__body-item {
        @include text-typography($font-family-cera-regular);
        margin-bottom: 6px;
      }
      .co-creation-project__label {
        color: $color-grey;
      }
      .co-creation-project__value {
        font-size: $font-size-title;
        color: $color-grey-white;
      }
    }
  }
  .co-creation-projects_footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;
    .co-creation-projects__bullet {
      width: 10px;
      height: 10px;
      background-color: $color-grey-medium;
      padding: 0;
      border-radius: 50px;
      margin: 0 5px;
      &.co-creation-projects__bullet-active {
        background-color: $color-grey-white;
      }
      &:hover {
        background-color: $color-grey;
        cursor: pointer;
      }
    }
  }
}
