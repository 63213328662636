.context-menu {
  position: relative;
  font-family: $font-family-cera-regular;
  color: $color-grey;
  border-radius: 3px;
  border: solid 2px $color-grey-medium-alt;
  background-color: $color-black;
  width: 40px;
  height: 40px;
  padding: 5px 0 0 4px;
  cursor: pointer;
  &:hover, &:active {
    border: solid 2px #7c878e;

    .context-menu-dots {
      color: $color-white
    }
  }

  .context-menu-dots {
    color: $color-grey-white;
    letter-spacing: -2px;
    font-size: 22px;
    font-family: $font-family-cera-regular;
  }

  .context-menu-items {
    position: absolute;
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #e5e7e7;
    background-color: $color-white;
    margin-top: 25px;
    right: 0;
    z-index: 1;

    &:before {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      bottom: 100%;
      right: 7px;
      border-width: 0 12px 12px 12px;
      border-style: solid;
      border-color: rgba(0,0,0,0.1) transparent;
    }
    &:after {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      bottom: 100%;
      right: 7px;
      border-width: 0 10px 10px 10px;
      border-style: solid;
      border-color: $color-white transparent;
    }
  }

  .context-menu-item {
    font-family: $font-family-cera-regular;
    font-size: 14px;
    color: $color-black;
    padding: 15px;
    border-bottom: solid 1px $color-grey-light2;
    white-space: nowrap;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: $color-yellow-light2;
      font-weight: 600;
    }
  }
  .context-menu-item-disabled {
    cursor: not-allowed;
    color: $color-grey;
    &:hover {
      color: $color-grey;
      font-weight: 500;
    }
  }
}