//RESPONSIVE VARIABLES

$phone-width: 320px;
$tablet-width: 768px;
$notebook-width: 1024px;
$big-tablet-width: 1280px;
$desktop-width: 1680px;

//RESPONSIVE MIXIN

@mixin phone {
    @media (min-width: #{$phone-width}) and (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$tablet-width}) and (max-width: #{$notebook-width - 1px}) {
        @content;
    }
}

@mixin big-tablet {
    @media (min-width: #{$phone-width}) and (max-width: #{$big-tablet-width - 1px}) {
        @content;
    }
}
@mixin mobile {
    @media (min-width: #{$phone-width}) and (max-width: #{$notebook-width - 1px}){
        @content;
    }
}

@mixin notebook {
    @media (min-width: #{$notebook-width}) and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop-width}) {
        @content;
    }
}

@mixin retina {
    @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
        @content;
    }
}

@mixin print {
    @media print {
        @content;
    }
}

//RESPONSIVE STYLE
.paymentAccountForm {
    @include phone{
        flex-direction: column;
    }
    @include tablet{
        flex-direction: row;
        flex-wrap: wrap;
    }
    .third{
        @include phone{
             min-width: 320px!important;
         }
        @include tablet{
            min-width: 320px!important;
            width: 50%;
        }
    }
    .third:last-child{
        @include tablet{
            width:100%!important;
            min-width: 320px!important;
            text-align: left!important;
        }
    }
}
.account-action {
    display: flex;
    flex-direction: column-reverse;
}
@media (min-width: 320px) and (max-width: 1024px){
.payment-settings {
    .paymentProfile-details {
        display: flex;
        flex-wrap: wrap;
        a {
            width: 100%;
            margin-bottom: 2px !important;
        }
        .account-action{
            padding-left: 0px !important;
            padding-right: 30px;
            margin-bottom: 7px !important;

            button{
                padding-top: 0px !important;
            }

        }
    }
}
}

@include mobile {
.payment-settings {
    .paymentProfile-details {
        display: flex;
        flex-wrap: wrap;
        a {
            width: 100%;
        }
        .account-action{
            padding-left: 0px !important;
            padding-right: 30px;
            button{
                padding-top: 7px !important;

            }

        }
    }
}
}
.payment-settings,
.location-container,
.homeContainer,
.community,
.connections{
@include mobile{
    flex-direction: column;
}
> section,
> aside {
    @include mobile{
        //height: 1000%;
        flex: 1;
        min-width: 100%;
    }
}
> aside {
    @include big-tablet {
        //display: none;
        width: 400px;
        min-width: 400px;
        position: fixed;
        z-index: 50;
        right: -400px;
    }
    @include big-tablet {
        .rightSidebar-content {
            .active {
                position: absolute;
                width: 40px;
                height: 40px;
                //background: linear-gradient(90deg, #333f48, #131e29);
                background-color: #f0b323;
                z-index: 50;
                margin-left: -440px;
                top: 180px;
                span{
                    left: 0;
                    position: absolute;
                    top: 0;
                    right: 0px;
                    width: 40px;
                    opacity: 0.4;
                    height: 40px;
                    background: url('./../assets/svg/officeBox/arrow.svg') no-repeat center center ;
                    cursor: pointer;
                    z-index: 10;

                }
                & + div {
                    right: 400px;
                    background-color: #131e29;
                }
                & + .not-booked-location,
                & + .booked-location {
                    background-color: #131e29;
                    position: fixed;
                    height: 100%;
                    margin-right: 0px;
                    right: 0px;
                    width:400px;
                    padding: 0px 30px;
                }
                & + .booked-location {
                    padding-top: 55px;
                }
            }
            .non-active {
                position: absolute;
                width: 40px;
                height: 40px;
                //background: linear-gradient(90deg, transparent, #6f7a88);
                background-color: #f0b323;
                opacity: 0.8;
                z-index: 50;
                margin-left: -40px;
                top: 180px;
                box-shadow: 5px 5px 13px rgba(51, 63, 72, 0.43137254901960786);
                span{
                    left: 0;
                    transform: scale(-1);
                    position: absolute;
                    top: 0;
                    right: 0px;
                    width: 40px;
                    opacity: 0.4;
                    height: 40px;
                    background: url('./../assets/svg/officeBox/arrow.svg') no-repeat center center;
                    cursor: pointer;
                    z-index: 10;

                }
                & + div {
                    right: -400px;
                    background-color: #131e29;
                }
            }
        }
    }
}
&-sidebarHeader{
    @include mobile {
        position: fixed;
        background: #131e29;
        margin-top: -75px;
        z-index: 95;
        padding: 0 15px;
        width: calc(100% - 130px);
    }

}
&-sidebar{
    @include mobile {
        padding: 0 15px;
        margin-top: 75px;
    }
}
&-sidebarSection {

}
&-inviteButton {
    @include mobile {
        margin-left: 15px;
        width: calc(100% - 33px);
    }

}
}

.rightSidebar {
    max-width: initial;
.connections-item {
    min-width: 100%;
}
}

.profileBox {
@include tablet{
    width: 50%;
}
@include phone{
    width: 100%;
}

.profileBox.connections-item{
    min-width: 100%;
    max-width: 100%;
    padding-right: 0px;

    @include tablet {
        width: 100%;
    }
    @include phone {
        width: 100%;
    }
}
&-firstName,
&-lastName {
    @include mobile {
        display: inline-block;
    }
    @include notebook {
        display: inline-block;
    }

}
&-image {
    @include mobile {
        display: inline-block!important;
    }
}
&-info {
    @include mobile {
        display: inline-block;
        margin: 0 15px;
        padding-right: 0;
        padding-top: 15px!important;
        vertical-align: top;
    }
    @include notebook {
        padding-right: 0;
    }
}
&-action{
    @include mobile {
        right: 15px!important;
    }

    @include notebook {
        right: 15px!important;
    }
}
}
.office-details {

.contact-info-fields {
    @include notebook {
        display: block;
        justify-content: space-between;
        >div {
            display: inline-block;
            width: calc(50%)!important;
        }
    }

}
}

.bookedOffice-directions.secondary-button-leftSide{
@media (min-width: 320px) and (max-width: 1199px) {
    margin-top: 15px;
}
}

.office-space-galleryItem:first-child {
@include mobile {
    margin-left: 0px !important;
}

@include tablet {
    margin-left: 0px !important;
}

@include notebook {
    margin-left: 0px !important;
}
}
.callToFeedback,
.callToFeedback+.arrow{
@include mobile {
    display: none!important;
}
@media screen and (max-width: 1100px){
    display: none!important;
}
}

.notifications-icon{
@include mobile {
    margin-top: -4px!important;
}
}

.advanced-search-column {
&-smaller{
    flex: 1;
}
&s{
    max-width: 100%;
}
.search-radio-group {
    > label:last-child{
        @media (min-width: 320px) and (max-width: 1279px){
            margin-bottom: 0px;
        }

    }
}
&-inputs{
    max-width: initial;
    margin: 0 0 0 0;
    padding: 20px 30px 0 30px;

    > div {
        max-width: 269px;
    }
    @media (min-width: 680px) and (max-width: 1279px){
        flex: 0 0 100%;
        display: flex;
        max-width: 100%;
        > div{
            flex: 1;
            max-width: initial;
            &:first-child{
                margin-right: 30px;
                div{
                    max-width: initial;
                }
             //margin-right: 30px;
            }
            &:last-child{
                margin-left: 30px;
                div{
                    max-width: initial;
                }
            }
        }
    }
}
}
