@import 'common/variables';
@import 'common/reusable';

.health__stamps-tooltip {
  @include text-typography($font-family-cera-regular, 14px);
  line-height: normal;
  letter-spacing: normal;
  position: absolute;
  width: fit-content;
  top: -57px;
  left: 0;
  background-color: $color-grey-medium-alt;
  color: $color-grey-white;
  padding: 13px;
  border-radius: 3px;
  cursor: default;
  opacity: 0;
  -webkit-transition: all .08s ease-in-out;
  transition: all .08s ease-in-out;
  white-space: nowrap;

  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 5px;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-top: 10px solid $color-grey-medium-alt;
  }
}

.health__stamps-container {
  position: absolute;
  left: 10px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.health__stamps-container--health {
  position: relative;
  margin-right: 5px;
  .health__stamp-icon:hover + .health__stamps-tooltip--health {
    opacity: 1;
  }
}

.health__stamps-container--temperature {
  position: relative;
  .health__stamp-icon:hover + .health__stamps-tooltip--temperature {
      opacity: 1;
  }
}

.health__stamp-icon--map {
  width: 21px;
}
