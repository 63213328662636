.wrapper {
  cursor: pointer;
  &.deactivate-events {
    pointer-events: none;
  }
}
.base-modal__modal-header-title {
  font-family: $font-family-cera-regular;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $color-grey;
}

.previous-auth-check {
  .base-modal__modal-overlay {
    z-index: -1;
  }
  padding-top: 10%;
  .base-modal__modal-content {
    width: 670px;
    height: 285px;

  }
  .base-modal__right-area {
    padding: 25px 18px 30px 30px;
  }
  .base-modal__close {
    top: -5px;
    right: 10px;
    color: $color-grey;
  }
  .base-modal__modal-body {
    padding-top: 45px;
  }
  .base-modal__confirmation-message {
    font-family: $font-family-cera-regular;
    font-size: 18px;
    line-height: normal;
    letter-spacing: normal;
    color: $color-grey-background;
    margin-bottom: 55px;
  }

  .base-modal__conjunction {
    font-family: $font-family-cera-regular;
    font-size: 14px;
    line-height: normal;
    letter-spacing: normal;
    color: $color-grey;
    margin: 0 20px;
    display: block;
  }
  .base-modal__left-area-icon{
    color: $color-yellow;
    margin-left: 15px;
    margin-bottom: 16px;
    width: 20px;
    height: 20px;
  }
  .base-modal__confirmation-actions {
    display: flex;
    align-items: center;
    width: 100%;
    button, a {
      width: 100%;
      padding: 10px 20px;
      height: 50px;
      cursor:pointer;
      max-width: 260px;
      margin: 0;
    }
    .google-login-button {
      background-color: $color-google;
      border-color: $color-google;
      height: 52px;
    }
    .facebook-login-button {
      padding: 10px 20px 10px 45px;
      max-width: 260px;
    }
    .linkedin-login-button {
      padding: 0;
      max-width: 260px;
    }
    .linkedin-login-text {
      margin: 10px 10px;
    }
    .auth-button {
      padding: 0;
    }
  }
  .base-modal__confirmation-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}