$icons: (
    icon-dots: "\e900",
    icon-community: "\e901",
    icon-booking: "\e902",
    icon-connections: "\e903",
    icon-directChat: "\e904",
    icon-ecoStats: "\e905",
    icon-events: "\e91d",
    icon-home: "\e907",
    icon-comment: "\e908",
    icon-fullStar: "\e909",
    icon-like: "\e90a",
    icon-photo: "\e90b",
    icon-share: "\e90c",
    icon-star: "\e90d",
    icon-quotes: "\e90e",
    icon-arrow: "\e90f",
    icon-location: "\e910",
    icon-locationFull: "\e911",
    icon-plus: "\e912",
    icon-close: "\e913",
    icon-delete: "\e914",
    icon-markRead: "\e915",
    icon-mute: "\e916",
    icon-muted: "\e917",
    icon-newConversation: "\e918",
    icon-report: "\e919",
    icon-search: "\e91a",
    icon-slider: "\e91b"
);

.icon {
    &::before {
        content: '';
        font-family: 'glyphify' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    @each $icon, $content in $icons {
        &.#{$icon}::before {
            content: $content;
        }            
    }
}  
