.community-form .community-edit-form__actions {
  height: fit-content;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;

  .edit-community__button-delete {
    padding: 0;
  }

  .edit-community__loader {
    width: 175px;
  }
}

.edit-community__verticals {
  @include text-typography($font-family-cera-regular, 14px);
  color: $color-grey;
}

.community-form .community-form__field .field-label.edit-community_field-label{
  padding-bottom: 3px;
}

.edit-community__field-text--not-editable {
  @include text-typography($font-family-cera-regular, 24px);
  color: $color-black;
}