@import '../../../theme/colors';

.mapContainer {
  width: 100%;
  height: 538px;
  position: relative;
  //padding: 0 30px;
  margin-bottom: 62px;
  box-sizing: border-box;
  overflow: hidden;

  &-element {
    height: 100%;
  }

  &-map {
    height: 100%;

    >div:first-child{
      background-color: #22303D !important;
    }

    .gm-style-pbc{
      background-color: rgba(0,0,0,0.15)
    }
  }

  &-bottomInfo {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    background-color: rgba($blue1, .65);
    padding: {
      left: 28px;
      right: 28px;
    }
  }

  &.--no-padding {
    padding: 0;
  }

  &.--full-height {
    height: 100%;
  }
}

.gm-style {
  .gmnoprint {
     &.gm-bundled-control.gm-bundled-control-on-bottom {
       margin: 25px 10px !important;
     }
    > div {
      background-color: rgba(19, 30, 41, 0)!important;
      > button {
        background-color: rgba(255, 255, 255, 0.65)!important;
        width: 30px!important;
        height: 30px!important;
        + div {
          background: transparent!important;
        }
        > img {
          height: 14px!important;
          width: 14px!important;
          padding: 8px 8px 8px!important;
          margin: 0!important;
        }
      }
    }
  }
}
