.meeting-attendees_dropdown {
  height: 50px;
  margin-top: 30px;
  position: relative;
  padding-left: 0;
  padding-right: 0;
  background-color: #0f171f;

  .dropdown-input {
    justify-content: center;
    display: flex;
    text-transform: uppercase;
    font-size: 14px;
    font-family: $font-family-cera-bold;
    color: #f0b323;
    user-select: none;
    cursor: pointer;
    line-height: 48px;
    height: 50px;
    padding-left: 0;
    padding-right: 0;
    border: 2px solid #f0b323;
    align-items: center;

    &:after {
      height: 50px;
    }

    img {
      margin-right: 10px;
    }
  }

  &.dropdown__selector .container {
    height: 116px;
    width: 100%;
    border: solid 2px #ecb022;
    top: 48px;
    background-color: #0f171f;

    .option {
      font-family: $font-family-cera-regular;
      font-size: 14px;
      letter-spacing: normal;
      height: auto;
      cursor: pointer;
      border-bottom: none;
      background-color: #0f171f;
      font-weight: 500;
      line-height: initial;
      padding: 12px 12px 12px 18px;
      color: #f0b323;
      margin: 10px;

      &:hover {
        background-color: #f0b323;
        color: #0f171f;
      }

      .accepted {
        margin-right: 10px;
      }

      .declined {
        margin-right: 15px;
      }
    }
  }
}

.meeting-attendees_dropdown__loader {
  width: 30%;
}

.meeting-attendees_dropdown__loader--sent {
  margin: auto;
}

.meeting-attendees_dropdown.expanded {
  .dropdown-input {
    border: 2px solid #f0b323;
    height: 50px;
    line-height: 48px;
    padding-left: 0;
    padding-right: 0;
  }

  &.dropdown__selector .dropdown-input:after {
    height: 50px;
  }

}
