.booking-payment-container {
  .booking-payment_back {
    display: flex;
    cursor: pointer;
    color: $color-grey;
    font-family: $font-family-cera-bold;
    font-size: 14px;
    text-transform: uppercase;

    img {
      margin-right: 6px;
    }
  }

  .booking-payment_title {
    font-family: $font-family-cera-medium;
    font-size: 30px;
    color: $color-grey-white;
    padding: 15px 0;
  }

  .booking-payment_subtitle {
    font-family: $font-family-cera-regular;
    font-size: 16px;
    color: $color-grey;
    margin-bottom: 45px;
  }

  .select-account-type {
    display: flex;
    flex-direction: column;

    .account-types {
      margin-bottom: 44px;
    }
  }

  .account-type_title {
    font-family: $font-family-cera-medium;
    font-size: 16px;
    color: $color-grey-light;
    margin: 3px 0 10px 0;
  }

  .text-field__content {
    display: flex;
    padding: 10px 0 0;
  }

  .account-type-select {
    label {
      padding: 3px 30px 0 0;
    }
    input[type=radio] {
      position: absolute;
      opacity: 0;
    }
    span {
      cursor: pointer;
      position: relative;
      align-items: center;
      display: flex;
      font-family: $font-family-cera-regular;
      font-size: 14px;
      color: #848e95;
    }
    span:before {
      display: block;
      content: '';
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-image: linear-gradient(to bottom, $color-black, $color-black);
      border: solid 2px $color-grey-medium-alt;
      box-sizing: border-box;
      left: 0;
      top: 0;
      z-index: 1;
      float: left;
      clear: none;
      margin: 0 10px 0 0;
    }
    input[type=radio]:checked + span {
      color: $color-grey-white;
    }
    input[type=radio]:checked + span:before {
      background: url('../../../assets/svg/account-selected.svg') no-repeat center center $color-black;
      border: solid 2px $color-grey-white;
      float: left;
      clear: none;
      margin: 0 10px 0 0;
    }
    input[type=radio]:hover + span:before {
      border: solid 2px $color-grey-white;
      background: url('../../../assets/svg/account-selected.svg') no-repeat center center $color-black;
    }
  }

  .account-type-buttons {
    display: flex;
    flex-direction: row;
    margin-top: 52px;
  }

  .continue-to-payment {

  }

  .continue-to-payment-disabled {
    font-family: $font-family-cera-bold;
    max-width: 284px;
    border-radius: 3px;
    background-color: $color-grey-medium;
    font-size: 14px;
    text-align: center;
    color: $color-black;
    text-transform: uppercase;
    padding: 15px 20px;
    cursor: not-allowed;
    &.payment-method-btn {
      width: 223px;
    }
  }
  .continue-to-payment {
    font-family: $font-family-cera-bold;
    max-width: 284px;
    border-radius: 3px;
    background-color: $color-yellow;
    font-size: 14px;
    color: $color-black;
    text-transform: uppercase;
    padding: 15px 20px;
    cursor: pointer;
    &.payment-method-btn {
      width: 223px;
    }
  }
  .cancel-booking-payment {
    font-family: $font-family-cera-bold;
    background-color: $color-black;
    max-width: 284px;
    font-size: 14px;
    text-align: center;
    color: $color-yellow;
    text-transform: uppercase;
    padding: 15px 23px 15px 0;
  }

  .personal-info-headline {
    font-family: $font-family-cera-medium;
    font-size: 16px;
    color: $color-grey-light;
    margin-bottom: 18px;
  }

  .payment-info-group {

  }

  .business-info-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 624px;
    margin-bottom: 10px;

    span {
      font-family: $font-family-cera-regular;
      font-size: 12px;
      color: $color-grey2;
      margin-top: 5px;
    }
  }

  .company-name-input {
    width: 406px;
  }
  .vat-number-input {
    width: 188px;
  }

  .payment-info-group-multiple {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 624px;
    margin-top: 25px;

    label {
      max-width: 188px;
    }
  }

  .payment-info-label {
    font-family: $font-family-cera-regular;
    font-size: 14px;
    color: $color-grey;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  .payment-info-input {
    font-family: $font-family-cera-medium;
    max-width: 624px;
    height: 50px;
    border-radius: 3px;
    border: solid 2px $color-grey-medium-alt;
    background-color: $color-black;
    color: $color-grey-white;
    padding-left: 14px;
    margin-top: 10px;
    &-completed{
      border: solid 2px $color-grey-white;
    }
  }

}

.payment-input-error {
  border: 2px solid $color-red !important;
}