.community-info {
  background-color: $color-grey-dark;
  border-radius: 6px;
  padding: 30px;
  margin-bottom: 30px;
}

.community-info-title {
  color: $color-grey-white;
  font-size: $font-size-default;
  padding-bottom: 15px;
}

.community-info-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.community-info-audience {
  font-size: $font-size-title;
  font-family: $font-family-cera-regular;
  color: $color-grey-white;
}
.community-info-audience--verticals {
  color: $color-grey;
  font-size: $font-size-default;
  line-height: 20px;
}
.community-info__members {
  padding-bottom: 20px;
  .community-info-member__icons {
    display: flex;
    padding:0 0 10px;
  }
  .community-info-member-icon,
  .community-info-member-counter {
    display: flex;
    width: 36px;
    height:36px;
    background-size: contain;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
  }
  .community-info-member-counter {
    background-color: $color-grey-medium;
    color: #a0abb2;
    font-size: $font-size-default-small;
    justify-content: center;
    line-height: 33px;
    margin: 0px;
  }
  .community-info-member-names {
    span {
      font-size: $font-size-default;
      color: #7c878e;
    }
  }
}

.community-info__invites {
  padding-bottom: 30px;
}

.community-info__invite-button {
  margin: 0px;
}

.community-info__fields {
  padding-bottom: 26px;
  .description,
  .description span{
    color: $color-grey;
    font-size: $font-size-default;
    line-height: 20px;
  }
}
.expand-button__text-link {
  width: 76px;
}
.community-info-admin__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-bottom: 10px;
  padding-top: 10px;
}
.community-info-admin__avatar-container {
  width: 60px;
  min-width: 60px;
  height: 60px;
  overflow: hidden;
  object-fit: contain;
  border-radius:50%;
}
.community-info-admin__avatar {
  width: 100%;
}

.community-info-admin__info {
  @include text-typography($font-family-cera-regular);
  display: flex;
  flex-direction: row;
  width: 100%;
  .community-info-admin__name {
    font-size: $font-size-title;
    font-family: $font-family-cera-medium;
    color: $color-grey-white;
    line-height: 30px;
  }

  .community-info-admin__label {
    background-color: $color-grey-white;
    border-radius: 3px;
    color: $color-grey-dark;
    font-size: $font-size-default;
    text-transform: uppercase;
    font-family: $font-family-cera-medium;
    padding: 1px 5px;
    margin-left: 5px;
  }

  .community-info-admin__details {
    width: 100%;
    flex: 1;
    flex-direction: column;
    display: flex;
    margin-left: 20px;
  }
  .community-info-admin__headline {
    flex: 1;
    line-height: 13px;
  }
  .community-info-admin__headline span {
    font-size: $font-size-default;
    color: $color-grey;
  }
}

.community-info__message-button {
  background-color: unset;
  @include default-border($entity-border-size, $color-grey-medium, $entity-button-border-radius);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-top: 0;
  margin-left: 5px;
  .community-info__message-icon {
    display: block;
  }
  .community-info__message-icon--hover {
    display: none;
  }
}

.community-info-admin__container:hover,
.community-info-admin__container:active {
  background-color: $color-grey-medium;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 100%;
  }

  &:before {
    background-color: $color-grey-medium;
    left: -10px;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }

  &:after {
    background-color: $color-grey-medium;
    left: 100%;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }

  .community-info__message-button {
    border: unset;
    background-color: $color-white;
  }
  .community-info__message-icon {
    display: none;
  }
  .community-info__message-icon--hover {
    display: block;
  }

}

.community-info__leave-button-container {
  padding-top: 10px;
}

.community-info-leave-button {
  color: $color-grey;
  @include text-typography($font-family-cera-medium, 14px);
  cursor: pointer;
  background-color: unset;
  width: auto;
  padding: 0;
  display: flex;
}