.card__new-company {
  height: auto;
  .premium-partner__container {
    position: absolute;
    padding: 20px 5px;
    z-index: 1;
    .health__stamps-tooltip {
      top: -30px;
    }
  }
}
.new-company__header {
  display: flex;
  flex-direction: row;
  margin-bottom: 22px;

  .company-logo__container {
    max-width: 90px;
    width: 100%;
    height: 90px;
    margin-right: 20px;
    overflow: hidden;
    object-fit: contain;

    &.company-logo__container-no-logo {
      max-width: 90px;
      width: 100%;
      height: 90px;
      margin-right: 20px;

      overflow: hidden;
      object-fit: contain;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      @include default-border($dashboard-border-size, $color-grey-light);

      img {
        width: 31px;
        height: 31px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        -o-object-fit: none;
        object-fit: none;
      }

      &:hover .company__logo {
        transform: none;
        transition: none;
      }
    }

  }

  .company__logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .company-logo__container:hover .company__logo,
  .company-logo__container:active .company__logo {
    transform: scale(1.08);
    transition: 0.4s ease;
  }

  .company__info-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .company__name {
    @include text-typography($font-family-cera-regular, 16px);
    color: $color-grey-white;
  }

  .company__address {
    @include text-typography;
    color: $color-grey;
    line-height: 1.29;
  }
}

.new-company__description {
  margin-bottom: 27px;
  @include text-typography;
  color: $color-grey;
  line-height: 1.43;
  span {
    @include text-typography;
    color: $color-grey;
  }
}

.new-company__office-space {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  .product-types__container {
    display: flex;
    flex-direction: row;
  }

  .product__container {
    width: 25%;
    max-width: 130px;
    @include default-border($dashboard-border-size, $color-grey-medium-alt, 3px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 10px;
    cursor: pointer;
    margin-right: 8px;
  }

  .product__container:last-child {
    margin-right: 0;
  }

  .product__container:hover,
  .product__container:active {
    background-color: $color-grey-dark;
    border-color: $color-grey-dark;
    .product__logo,
    .product__name {
      color: $color-yellow-light;
    }
  }

  .product__logo {
    width: 50%;
    height: 50%;
    color: $color-yellow;
    margin-bottom: 8px;
  }

  .product-name__container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
  }

  .product__name {
    @include text-typography($font-family-cera-bold);
    color: $color-yellow;
    text-transform: uppercase;
    text-align: center;
    vertical-align: center;
  }
}

.new-company__jobs {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  .card__subtitle {
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 1360px) {
  .new-company__office-space {
    .product__name {
      font-size: 12px;
    }
  }
  .new-company__header,
  .new-company__jobs {
    margin-bottom: 12px;
  }
  .new-company__description,
  .new-company__office-space {
    margin-bottom: 16px;
  }
  .job-announcements__wrapper {
    .job-announcement__container {
      padding: 10px 0;
    }
  }
}
