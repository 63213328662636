.all-communities {
  background-color: $color-black;
  padding: 0;

  .no-more-communities {
    font-family: $font-family-cera-regular;
    text-align: center;
    margin-top: 20px;
  }

  &.popular-communities__container {
    .community-card{
      margin-bottom: 50px;
    }
  }

}


.all-communities__title {
  font-family: $font-family-cera-regular;
  font-size: 24px;
  color: $color-grey;
  margin-bottom: 17px;
}

.all-communities__load_more {
  button {
    max-width: 500px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
  }
}