.cancelation-table{
  table{
    margin-top: 30px;
    th {
      font-family: "CeraPRO-Light";
      font-size: 14px;
      text-align: left;
      font-weight: 300;
      color: #a0abb2;
      padding-bottom: 10px;
      width: 16%;

      th:first-child{
        width: 20%;
      }
    }

    td {
      padding-bottom: 15px;
      width: 16%;

      td:first-child{
        width: 20%;
      }
    }

    td.small-td {
      font-family: "CeraPRO-Light";
      font-size: 14px;
      text-align: left;
      font-weight: 300;
      color: #a0abb2;
      padding-bottom: 18px;
    }

    tr
    {
      tr.percent{
        td{
          span{
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #a0abb2;
          }
        }
      }
    }
  }
}