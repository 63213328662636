.myBookings {
  &-navigation {
    display: inline-block;
    margin-left: 30px;
    margin-top: 28px;
    cursor: pointer;
    color: #7c878e !important;
    text-transform: uppercase;
    text-decoration: none;
    //padding-left: 9px;
    position: relative;
    font-size: 14px;
    font-weight: bold;
    + .pageHeader.\--myBookings {
      margin-top: 5px;
    }

    &:before {
      content: '';
      position: relative;
      display: inline-block;
      width: 14px;
      height: 14px;
      top: 3px;
      left: 0;
      background-repeat: no-repeat;
      background-image: url('./../../../assets/svg/myBookings/angle-left.svg');
    }
  }

  &-filters {
    padding-left: 30px;
    margin-bottom: 15px;
  }
}
.myBookings.\--hideTitle{
  padding-top: 20px!important;

  .booking-title{
    display: none;
  }
}

.booking-menu-container {
  padding-left: 30px;
}

.my-bookings-menu {
  margin-bottom: 20px;
  
  .my-bookings-menu-border {
    display: none;
  }

  .my-bookings-menu-list {
    display: flex;
    list-style: none;

    .my-bookings-menu__link {
      cursor: pointer;
      padding-right: 30px;

      a {
        font-family: 'CeraPRO-Bold';
        text-transform: uppercase;
        font-size: 14px;
        color: #7c878e;
        position: relative;
      }

      &.my-bookings-menu__link-active {
        a {
          color: #ffffff;
        }
      }
    }
  }

  .my-bookings-menu__link-active {
    .my-bookings-menu-border {
      display: block;
      border-bottom: 3px solid #f0b323;
      position: absolute;
      width: 100%;
      bottom: -11px;
      left: 0;
    }
  }

}

