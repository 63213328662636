@import '../../../../theme/layout';

.bookedOffice {
  display: flex;

  @media screen and (max-width: $largeDevices) {
    display: block;
  }

  &-details {
    flex: 2;
  }

  &-actions {
    flex: 1;
    width: 130px;
    text-align: center;

    @media screen and (max-width: $largeDevices) {
      width: 100%;
    }
  }

  &-directions {
    &.secondary-button-leftSide {
      font-family: 'CeraPRO-Medium';
      text-transform: uppercase;
      line-height: 18px;
      text-align: center;

      padding: 0 8px 0 30px;
      margin-bottom: 7px;

      width: 100%;
      height: 40px;

      position: relative;

      &:active {
        margin-top: 0;
      }
    }
  }

  &-directionsIcon {
    width:18px;
    height: 18px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 6px;
    background: url('./../../../../assets/svg/officeBox/path.svg') center transparent;
    background-size: cover;
    position: absolute;
    left: 8px;
    top: 9px;
  }
}

.event__attendees {
  font-size: 14px;
  color: #7c878e;
  cursor: pointer;
  border-bottom: 1px solid #7c878e;
  padding-bottom: 3px;
  &:hover {
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
  }
}
.bookedOffice-checkin {
  padding-top: 0px;

  .bookedOffice-checkinbutton {
    text-transform: uppercase;
    font-family: CeraPRO-Bold;
    font-size: 14px;

  }

  .bookedOffice-cancelBooking {
    color: #7c878e;
    font-family: CeraPRO-Regular;
    font-size: 14px;
    line-height: 10px;
    letter-spacing: normal;
    text-align: center;
    cursor: pointer;
    display: block;
    padding: 10px 0 20px;
  }
}
.bookedOffice-checkout {
  display: flex;
  padding: 20px 0;
  .bookedOffice-checkinbutton {
    text-transform: uppercase;
    font-family: CeraPRO-Bold;
    font-size: 12px;
    margin-top: 0;
    padding: 0px;
  }

  .bookedOffice-checkinbutton-extend{
    margin-left: 10px;
    text-transform: uppercase;
    font-family: CeraPRO-Bold;
    font-size: 12px;
    margin-top: 0;
    padding: 0px;
  }

}
.bookedOffice-extendBooking {
  display: flex;
  flex-direction: column;
  padding: 30px 0 ;
  input[type="radio"]  + label {
    font-family: CeraPRO-Regular;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.71;
    letter-spacing: normal;
    color: #131e29;
  }
  input[type="radio"]:checked  + label {
    font-family: CeraPRO-Medium;
  }
  input[type="radio"]:disabled  + label {
    cursor: not-allowed;
  }
  label::before {
    background-image: linear-gradient(to bottom, #ffffff 2%, #f6f6f6 97%) !important;
    border: solid 1px #cdd1d4 !important;
  }
}
.bookedOffice-popup-title {
  font-family: CeraPRO-Regular;
  font-size: 24px;
  line-height: normal;
  letter-spacing: normal;
  color: #7c878e;
  display: block;
}
.bookedOffice-popup-body {
  padding: 54px 28px 54px 0px;
  img {
    width: 80px;
    height: 60px;
    border-radius: 3px;
    padding: 0 10px;
  }
  .bookedOffice-text {
    font-family: CeraPRO-Medium;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #131e29;
    padding: 10px 0;
  }
}
.bookedOffice-submitButton {
  color: #131e29 !important;
}
