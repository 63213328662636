.my-meetings {

  .my-bookings-menu {
    margin-bottom: 40px;
  }

  .my-meetings-title {
    font-family: $font-family-cera-light;
    font-size: 24px;
    color: #a0abb2;
    line-height: 36px;
    margin-top: 5px;
    margin-bottom: 23px;
  }

  .meetings-container {
    padding: 0 30px;
  }

  .meetings-wrapper {
    position: relative;
    padding: 23px 27px 27px 27px;
    border-radius: 0 0 3px 3px;
    background-color: $color-grey-background;
  }

  .meetings-tile {
    max-width: 417px;
    width: 100%;
    margin-bottom: 30px;
  }

  .office-title {
    font-family: $font-family-cera-regular;
    font-size: 18px;
    color:$color-white;
    margin-bottom: 18px;
  }

  .office-title span {
    font-family: $font-family-cera-light;
    font-size: 22px;
    color: #758087;
  }

  .office-distance {
    font-family: $font-family-cera-regular;
    font-size: 14px;
    color: $color-grey;
  }

  .meeting-main-image {
    border-radius: 3px 3px 0 0;
    width: 100%;
    min-width: 417px;
    overflow: hidden;
    object-fit: cover;
    height: 320px;
  }

  .meetings__extend-button{
    text-align: left;
    font-size: 14px;
    font-family: $font-family-cera-bold;
    text-transform: uppercase;
    margin-top: 15px;
  }

  .office-location {
    background: url('../../../assets/svg/events/event-link.svg') no-repeat 50% 50% $color-yellow;

    &:hover {
      background-color: $color-yellow-hover;
    }
  }

}

.start-new-meeting-tile {
  max-width: 417px;
  width: 100%;
  height: 664px;
  border-radius: 3px;
  background-color: #0f171f;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;

  .start-new-meeting-img {
    margin-bottom: 20px;
  }

  .start-new-meeting-title {
    font-family: $font-family-cera-regular;
    line-height: 1.57;
    color: #ffffff;
    font-size: 24px;
    margin-bottom: 20px;
  }

  .start-new-meeting-subtitle {
    font-family: $font-family-cera-regular;
    font-size: 14px;
    color: #7c878e;
    line-height: 1.57;
    text-align: center;
    margin-bottom: 55px;
  }

  .start-new-meeting-btn {
    width: 220px;
    height: 50px;
    border-radius: 3px;
    background-color: #f0b323;
    font-family: $font-family-cera-bold;
    font-size: 14px;
    text-align: center;
    color: #131e29;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    display: flex;
  }

}
