$thumbSize: 60px;

.office-connections {

  &-title {
    @extend %officeDetails-titleBorder;
  }

  &-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-height: 240px;
    overflow: hidden;
  }

  &-item {
    display: flex;
    justify-content: flex-start;
    width: 30%;
    padding: 0 20px 20px 0;

    & .thumb {
      width: $thumbSize;
      height: $thumbSize;
      display: inline-block;
      background-size: contain;
      border-radius: 3px;
    }

    .details {
      margin-left: 20px;
    }

    .details-name {
      @extend %typography-pageTitles;

      margin-top: 5px;
    }

    .details-company {
      @extend %typography-fieldsDescription;

      margin-top: 5px;
    }
  }

  &-expanded,
  &-collapsed {
    @extend %typography-fieldTitle;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    cursor: pointer;
    color: $yellow1;
    text-transform: uppercase;
    text-decoration: none;
    user-select: none;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 14px;
      height: 14px;
      background-repeat: no-repeat;
      background-position: 50%;
      background-image: $iconExpand;
    }
  }

  &-expanded:after {
    transform: rotate(180deg);
  }
}
