@import '../../../theme/common/variables';
@import '../../../theme/common/reusable';
.meeting-details__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 75%;
  background-color: $color-grey-background;
  border-radius: 3px;
  margin: 30px;
  padding: 30px;
}
.meeting-details__column {
  display: flex;
  flex-direction: column;
}
.meeting-details__title {
  font-family: $font-family-cera-light;
  font-size: 24px;
  line-height: 1.42;
  color: $color-white;
}
.meeting-details__attendees {
  margin-top: 50px;
}
.meeting-details__attendee {
  margin: 10px 0;
}
.booking-invite-members__image-section {
  display: flex;
  align-items: center;
}
.meeting-details__attendee-picture {
  border-radius: 50%;
}
.meeting-details__attendee-name {
  display: flex;
  font-family: $font-family-cera-regular;
  font-size: 16px;
}
.meeting-details__more-button {
  font-family: $font-family-cera-bold;
  font-size: 14px;
  color: $color-yellow;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 50px;
}

.meeting-details__detail-section {
  margin-bottom: 25px;
}
.meeting-details__category {
  font-family: $font-family-cera-bold;
  font-size: 14px;
  color: $color-grey;
  margin-bottom: 8px;
  text-transform: uppercase;
}
.meeting-details__value {
  font-family: $font-family-cera-light;
  font-size: 24px;
  color: $color-white;
  margin-bottom: 15px;
}
.meeting-details__right-side {
  padding-top: 200px;
  &--disabled {
    opacity: 0.4;
    pointer-events: none;
  }
  .meeting-attendees_buttons {
    margin-top: 30px;
  }
}