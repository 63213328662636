.invite-members__header {
  padding: 20px 30px 30px 30px;
}

.invite-members__content {
  height: 430px;
  background-color: $color-white-light-grey;
  display: flex;
  flex-direction: row;
  border-top: 1px solid $color-grey-light2;
  border-bottom: 1px solid $color-grey-light2;
}

.invite-members__suggested-members-wrapper {
  width: 64%;
  border-right: 1px solid $color-grey-light2;
}

.invite-members__selected-members-wrapper {
  width: 36%;
}

.invite-members__load-button {
  @include text-typography($font-family-cera-regular, 13px);
  background-color: unset;
  color: $color-yellow;
  padding: 15px;

}

.invite-members__column-title {
  @include text-typography($font-family-cera-regular);
  letter-spacing: normal;
  color: $color-black;
  padding: 20px 30px 10px 30px;
}

.invite-members__selected-members {
  display: flex;
  flex-direction: column;
}

.invite-members__selected-members-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 12px;
  margin: 0 30px 10px 30px;
  border: 1px solid $color-grey;
  border-radius: 2px;
}

.invite-members__selected-members-name {
  font-family: $font-family-cera-medium;
  font-weight: 500;
  font-size: $font-size-default;
  text-transform: uppercase;
  color: $color-grey;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.invite-members__selected-members-remove {
  height: 14px;
  width: 14px;
  margin-left: 7px;
  cursor: pointer;
}

.invite-members__suggested-members-card {
  display: flex;
  flex-direction: row;
  width: 96%;
  height: 80px;
  cursor: pointer;
  align-items: center;
  padding-left: 20px;
  border-radius: 2px;
  margin: 0 auto;
}

.invite-members__suggested-members-card--checked {
  background-color: #ffffff;
}

.invite-members__selected-members-picture {
  border-radius: 50%;
  display: flex;
  cursor: pointer;
  margin-right: 20px;
}

.invite-members__suggested-members-details-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 220px;
}

.invite-members__suggested-members-scroll {
  div:nth-child(3) {
    // For the scrollbar that it is not aligned with hero card
    // Sorry for important, Andra B.
    right: 8px !important;
  }
}

.invite-members__suggested-members-name {
  @include text-typography($font-family-cera-medium, 16px);
  color: $color-black;
}

.invite-members__suggested-members-headline {
  @include text-typography($font-family-cera-regular, 14px);
  color: $color-grey;
}

.invite-members__footer {
  padding: 30px;
}

.invite-members__search-message {
  @include text-typography($font-family-cera-light, 14px);
  color: $color-black;
  text-align: center;
  margin-top: 20px;
}

.community__loader.community-invite-members__loader {
  width: 165px;
}

.invite-members__radio-button-wrapper .invite-members__radio-button-label {
  margin-bottom: 30px;
}
