.virtual-tour__background-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 5px;
  padding: 15px;
  margin-top: 30px;
  height: 80px;
}
.virtual-tour__description {
  font-family: 'CeraPRO-Medium';
  font-size: 18px;
  color: $blue1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-right: 30px;
}
.virtual-tour__button {
  display: inline-flex;
  background-color: $blue1;
  padding: 15px 25px;
  text-transform: uppercase;
  text-align: center;
  min-width: 190px;
  border-radius: 5px;
}
.virtual-tour__button-text {
  font-family: 'CeraPRO-Medium';
  font-size: 14px;
  color: $yellow1;
  margin-left: 8px;
  margin-top: 2px;
}