.image-gallery-content {
  .image-gallery-left-nav,
  .image-gallery-right-nav {
    width: 100px;
  }
  .image-gallery-left-nav,
  .image-gallery-right-nav {
    font-size: 1.5em;
  }
  .image-gallery-left-nav::before,
  .image-gallery-right-nav::before {
    width: 43px;
    height: 43px;
    background-color: $color-yellow;
    border-radius: 3px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: none;
    font-weight: bold;
    margin-left: 10px;
  }
  .image-gallery-slide img {
    border-radius: 3px;
  }
  .image-gallery-slide-wrapper {
    min-height: 450px;
  }
}