@import '../../../theme/buttons';
@import '../../../theme/formElements';



& {
  height: 100%;
  width: 100%;

  .directChat-messages {
    &-messageWindow {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 101px 30px 30px 0;
    }

    &-header {
      background: $white1;
      color: $gray1;
      font-size: 16px;
      padding: 30px;
      position: relative;
      text-align: center;
      span{
        background: $white1;
        color: $gray1;
        font-size: 16px;
        padding: 30px;
        position: relative;
        text-align: center;
      }
    }

    &-options {
      position: absolute;
      right: 30px;
      text-align: right;
      top: 25px;
      z-index: 2;

      &.active {
        .directChat-messages-optionsList {
          display: block;
        }
      }

      &Button {
        @extend %simpleButton;
      }

      &List {
        background-color: $white1;
        border: 1px solid $gray11;
        border-radius: 3px;
        box-shadow: 0 8px 15px 0 $black2;
        display: none;
        margin-right: -25px;
        position: relative;
        text-align: left;

        &::before {
          border-bottom: 10px solid $white1;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          content: '';
          position: absolute;
          right: 26px;
          top: -10px;
          z-index: 4;
        }

        &::after {
          border-bottom: 12px solid $gray11;
          border-left: 12px solid transparent;
          border-right: 12px solid transparent;
          content: '';
          position: absolute;
          right: 24px;
          top: -12px;
          z-index: 3;
        }
      }

      &Item {
        align-items: center;
        align-content: center;
        border-top: 1px solid $gray3;
        color: $blue1;
        cursor: pointer;
        display: flex;
        font-size: 18px;
        line-height: 20px;
        padding: 13px 20px;

        &:hover {
          color: $yellow1;

          .directChat-messages-optionsIcon {
            color: $yellow1;
          }
        }

        &:first-child {
          border: none;
        }
      }

      &Icon {
        color: $gray1;
        font-size: 20px;
        margin-right: 9px;
      }
    }

    &-content {
      background: $white1;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      //padding: 0 20px 0 30px;

      & > div {
        flex-grow: 1;
      }
    }

    &-messageAvatar {
      align-items: center;
      border-radius: 50%;
      display: flex;
      height: 50px;
      margin-right: 10px;
      min-width: 50px;
      overflow: hidden;
      width: 50px;
    }

    &-messageImage {
      max-height: 50px;
      max-width: 50px;
    }

    &-messageWrapper {
      display: flex;

      &.directionShift {
        margin-top: 15px;
      }

      &.mine {
        flex-direction: row-reverse;
      }
    }

    &-message {
      background: $gray20;
      border-radius: 3px;
      color: $blue1;
      font-size: 18px;
      line-height: 1.44;
      margin-top: 15px;
      max-width: 60%;
      padding: 12px;
      white-space: pre-line;

      &.withoutAvatar {
        margin-left: 50px;
      }

      &.mine {
        background: $yellow1;
        margin-left: 0;
      }

      &.withImage {
        align-items: center;
        display: flex;
        max-width: 70%;

        &.mine {
          flex-direction: row-reverse;

          .directChat-messages-messageImageWrapper {
            margin-left: 0;
            margin-right: 20px;
          }
        }
      }

      &ImageWrapper {
        max-height: 100px;
        margin-left: 20px;
        max-width: 100px;
      }

      &ImageUpload {
        max-height: 100px;
        max-width: 100px;
      }
    }

    &-newMessage {
      align-items: flex-end;
      background: $white1;
      display: flex;
      padding: 30px 30px 30px;
      position: relative;
      &:after {
        content: '';
        display: block;
        width: 18px;
        height: 16px;
        background: url('../../../assets/svg/header/arrow.svg') no-repeat 50% 50%;
        position: absolute;
        right: 50px;
        top: 52px;
      }

      &Input {
        @extend %modal-input;
        min-width: 100%;
        box-sizing: border-box;
        color: $gray5;
        flex-grow: 1;
        font-size: 16px;
        font-style: italic;
        height: 50px;
        line-height: 1.5;
        padding: 12px 20px;


        &.withImage {
          height: 100px;
          padding-left: 120px;
        }
      }

      &ImageUpload {
        @extend %quickCtaButton;

        margin-left: 30px;

        &.icon{
          &.icon-photo::before{
            position: absolute;
          }
        }
      }
    }

    &-imageWrapper {
      align-items: center;
      display: flex;
      height: 76px;
      justify-content: center;
      left: 50px;
      position: absolute;
      top: 42px;
      width: 76px;
    }

    &-image {
      max-height: 76px;
      max-width: 76px;
    }

    &-removeImage {
      color: $yellow1;
      cursor: pointer;
      font-size: 12px;
      position: absolute;
      right: 5px;
      top: 5px;

      &:hover {
        color: $yellow2;
      }
    }

    &-informationWrapper {
      align-items: center;
      background: $white1;
      display: flex;
      flex-grow: 1;
    }

    &-information {
      color: $blue1;
      padding: 150px;
      width: 100%;
      text-align: center;
    }
  }
  .mineMessageGroup, .otherMessageGroup{
    padding: 0 20px 0 30px;
  }

  .otherMessageGroup + .mineMessageGroup{
    margin-top: 15px;
  }
  .mineMessageGroup + .otherMessageGroup{
    margin-top: 29px;
  }
}

.directChat-messages-message {
  word-wrap: break-word;
  //autolinker component install and style anchor
}