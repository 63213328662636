@import '../../../theme/layout';

.myBookings-link.secondary-button{
  margin-top: 63px;
}

.homeSidebar-myBookings-link.myBookings-link.secondary-button{
  margin-left: 30px;
  margin-right: 30px;
  width:calc(100% - 60px);
}
.rightSidebar {
  .myBookings {
    .booking-title{
      padding-left: 0px;
      margin-top: 27px;
      margin-bottom: 3px;
      
    }
    .officesGrid-vertical .officesGrid-topInfo {
      margin-bottom: 8px;
    }
    .officeBox-info {
      padding: 20px 0 10px!important;
      .officeBox-title.items-style1{
        position: relative;
        display: inline-block;
        line-height: inherit;
      }
    }
  }
}
.myBookings {
  padding: {
    left: 30px;
    right: 30px;
    top: 55px;
    bottom: 55px;
  }
  .booking-options{
    > div {
      padding-bottom: 29px;
    }
  }


  @media screen and (max-width: $largeDevices) {
    padding: {
      left: 15px;
      right: 15px;
    }
  }

  &-link {
    display: block;
    width: 100%;

    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
  }

  .officeBox {
    &-image {
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;
    }
  }

  &.--noTopPadding {
    padding-top: 0;
  }
}

.booking-title{
  text-transform: uppercase;
    text-decoration: none;
    margin-top: 30px;
    margin-bottom: 10px;
    padding-top: 30px;
    position: relative;
    color: #7c878e;
    font-size: 14px;
    font-weight: bold;
  padding-left: 15px;
  padding-right: 15px;
}

.active-booking-label{
  font-size: 24px;
  font-weight: normal;
}

.officesGrid-topSection{
  //height: 20px;
  //>div{
  //  display: none!important;
  //
  //}
  margin-bottom: 2px;
  position: relative;
}
