@import '../../../theme/colors';
@import '../../../theme/layout';

.location-booked-title {
  margin-bottom: 25px;
  margin-top: 20px;
  font-family: 'CeraPRO-Medium';
  font-size: 24px;

  .success-message {
    font-size: 24px;
    font-weight: 500;
    color: $green2;
    font-family: 'CeraPRO-Medium';
  }

  .error-message {
    font-size: 24px;
    font-weight: 500;
    color: $red1;
    font-family: 'CeraPRO-Medium';
  }

  .active-message {
    font-size: 24px;
    font-weight: 500;
    color: $green2;
    font-family: 'CeraPRO-Medium';
    color: $green2;
  }

  .closed-message {
    font-size: 24px;
    font-weight: 500;
    color: $white1;
    font-family: 'CeraPRO-Medium';
  }

  .pending-message {
    font-size: 24px;
    font-weight: 500;
    color: $yellow1;
    font-family: 'CeraPRO-Medium';
  }
}

.booked-location {
  .officesGrid  {
    padding-top: 0;
  }

  .officeBox-image {
    position: relative;

    &:before {
      content: 'COMING UP';
      width: 74px;
      height: 13px;
      line-height: 13px;
      font-size: 13px;
      font-weight: bold;
      text-align: left;
      color: $blue1;
      border-radius: 47px;
      background-color: $yellow1;
      position: absolute;
      top: 15px;
      padding: 5px 10px 5px 19px;
      left: -10px;
    }
  }

  .cancel-booking {
    display: block;
    font-size: 14px;
    font-weight: bold;
    color: $red2;
    margin-top: 17px;
    margin-bottom: 6px;
  }

  p {
    color: $gray1;
    font-size: 14px;

    span {
      color: $red2;
      font-size: 14px;
    }
  }

  button {
    width: 100%;

    &.main-button {
      margin: 30px 0;
      @media only screen and (max-width : $largeDevices) {
        font-size: 11px;
        line-height: 49px;
      }
    }

    &.send-mail {
      margin-bottom: 0;
      text-transform: uppercase;
      position: relative;

      &:before {
          content: '';
          display: block;
          background: url('../../../assets/svg/events/email-icon.svg') no-repeat 50% 50%;
          background-size: contain;
          width: 22px;
          height: 15px;
          position: absolute;
          left: 20px;
          top: 17px;

          @media only screen and (max-width : $largeDevices) {
            left: 16px;
            top: 16px;
          }
      }
    }
  }
}
