.card__upcoming-bookings {
  height: auto;
  .card__title {
    margin-bottom: 10px;
  }
  .upcoming-booking__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding-bottom: 10px;
    padding-top: 10px;

    .upcoming-booking__img-container {
      width: 60px;
      height: 60px;
      max-width: 60px;
      max-height: 60px;
      overflow: hidden;
      object-fit: contain;
      border-radius: 8px;
      margin-right: 20px;
    }
    .upcoming-booking__img-container-no-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      @include default-border($dashboard-border-size, $color-grey-light);
      .upcoming-booking__no-img {
        font-size: 11px;
      }
    }

    .upcoming-booking__info {
      @include text-typography($font-family-cera-regular);
      display: flex;
      flex-direction: row;
      .upcoming-booking__title {
        font-size: $font-size-title;
      }
      .upcoming-booking__date,
      .upcoming-booking__start, 
      .upcoming-booking__start span{
        font-size: $font-size-default;
        color: $color-grey;
        line-height: 19px;
        span {
          color: $color-white;
        }
      }
    }
  }
  .upcoming-bookings__button {
    margin-top: 10px;
  }
  .upcoming-booking__container:hover,
  .upcoming-booking__container:active {
    background-color: $color-grey-medium;
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 100%;
    }

    &:before {
      background-color: $color-grey-medium;
      left: -20px;
      border-bottom-left-radius: $dashboard-card-border-radius;
      border-top-left-radius: $dashboard-card-border-radius;
    }

    &:after {
      background-color: $color-grey-medium;
      left: 100%;
      border-bottom-right-radius: $dashboard-card-border-radius;
      border-top-right-radius: $dashboard-card-border-radius;
    }
    .upcoming-booking__img {
      transform: scale(1.08);
      transition: 0.4s ease;
    }
    .upcoming-booking__date,
    .upcoming-booking__start {
      color: $color-grey-white;
    }
    .dashboard-button.dashboard-button--secondary {
      border-color: $color-white;
      background-color: $color-white;
      color: $color-black;
    }
  }
}
@media screen and (max-width: 1360px) {
  .card__upcoming-bookings .upcoming-booking__container .upcoming-booking__info {
    .upcoming-booking__title {
      font-size: $font-size-title-small;
    }
    .upcoming-booking__date,
    .upcoming-booking__start,
    .upcoming-booking__start span {
      font-size: $font-size-default-small;
    }
  }
}
