.booking-office-rules {

  &-title {
    @extend %officeDetails-titleBorder;
  }

  &-list {
    @extend %displayFlex-wrap;
  }

  .attachments {
    &:after {
      @extend %typography-fieldTitle;

      content: attr(data-label);
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
      font-size: 14px;
      color: $yellow1;
      user-select: none;
      background: none;
      line-height: 24px;
    }
  }

  &-attachment {
    color: $gray2;
    font-size: 12px;
    margin-top: 10px;
    cursor: pointer;
  }

  .rule-available,
  .rule-unavailable {
    @extend %officeDetails-items;
    width: 100% !important;
  }

  .rule-available:after {
    background-image: url(../../../assets/svg/officeDetails/feature-available.svg)
  }

  .rule-unavailable:after {
  }

  .rule-unavailable {
  }
}
