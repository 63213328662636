.login-form__container {
  margin: 30px 0 15px 0;
}
.login-form__row {
  display: flex;
  flex-direction: row;
  margin: 15px 0;
}
.login-form__columns {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  & > div {
    cursor: pointer;
  }
}
.login-form__forgot-password {
  @include text-typography($font-family-cera-regular, 16px);
  color: $color-yellow;
}
.login-form__submit-button, .login-form__third-party-button {
  @include text-typography($font-family-cera-bold, 14px);
  text-transform: uppercase;
  background-color: $color-yellow;
  color: $color-black;
  border: solid 2px $color-yellow;
  border-radius: 5px;
  padding: 15px 0;
}
#open-signup, #open-forgot {
  margin-top: 15px;
}
#open-login {
  margin-bottom: 10px;
}
.auth-form__error-message {
  @include text-typography($font-family-cera-regular, 12px);
  color: $color-red-light;
}
.profile-form__input[type="password"] {
  font: small-caption;
  font-size: 16px;
}