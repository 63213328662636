@import '../../../theme/colors';

.add-booking-checkboxes-group {


  &-list {

    display: inline-flex;
    flex-wrap: wrap;

    .add-booking-checkboxes-column{

      width: 50%;
      min-width: 165px;

      & > label {
        white-space: nowrap;
        margin-bottom: 20px;
        width: 100%;

        input[type=checkbox] {
          position: absolute;
          opacity: 0;

          & + span {
            position: relative;
            cursor: pointer;
            padding-left: 28px;
          }

          & + span:before, & + span:after{
            display: block;
            content: '';
            width: 20px;
            height: 20px;
            border-radius: 3px;
            background-image: linear-gradient(to bottom, $white1, $yellow8);
            border: solid 1px $gray3;
            box-sizing: border-box;
            position: absolute;
            z-index: 6;
            left: 0;
            top: 0;
          }

          & + span:after {
            z-index: 1;
          }

          &:hover + span:before {
              background: rgba($green3, .5);
          }

          &:checked + span:before {
            background: url('./../../../assets/svg/check.svg') no-repeat center center #d3ffdd;
            border: solid 1px #93e4a5;
          }

          &:checked + span.disabled:before {
            background: url('./../../../assets/svg/check-gray.svg') no-repeat center center #7A878D;
            border: solid 1px #7A878D;
          }

          & + span.disabled {
            color: #7A878D;
            &:before{
              background: #7A878D;
              border: solid 1px #7A878D;
            }
          }
        }
      }

    }
  }

  & > span {
    user-select: none;
    margin-left: 10px;
  }

  input[type=checkbox]:checked ~ span {
    color: $blue1;
    font-weight: 500;
  }
}
