@import '../../../theme/colors';
@import '../../../theme/layout';

.edit-section.fullWidth{
  width: 100%;
  .edit-section-cell{
    width: 100% !important;

  }
}
textarea.edit-area {
  min-height: 100px !important;

  //height: 100px !important;
  width: 100% !important;
}
.connections-container{
  .profileBox{
    padding-right: 0px;
    min-width: inherit;
    .profileBox-firstName{
      font-weight: 200;
      font-family: 'CeraPRO-Light';
    }
    .profileBox-lastName{
      font-weight: 500;
      font-family: 'CeraPRO-Medium';
    }
    &-image{
      width: 120px;
      height: 120px;
      min-width: 120px;
    }

    &-info{
      white-space: pre-wrap;
      > .profileBox-infoSmall {
        white-space: pre-wrap;
        display: inline-block;
        .profileBox-infoSmall {
          display: inline-block;
        }
      }
    }
    //.profileBox.connections-item{
    //  min-width: 100%;
    //  max-width: 100%;
    //
    //}
  }
}

.connections-sidebarSection {
  .profileBox-firstName{
    font-weight: 200;
    font-family: 'CeraPRO-Light';
  }
  .profileBox-lastName{
    font-weight: 500;
    font-family: 'CeraPRO-Medium';
  }
  .profileBox-info {
    > .profileBox-infoSmall{
      white-space: pre-wrap;
      display: inline-block;
      .profileBox-infoSmall{
      display: inline-block;
      }
    }
  }
}
.connection {
  display: flex;
  height: 100%;
  position: relative;

  .connection-pagePadding {

        .connection-sectionTitle {
          margin-bottom: 24px;
          & + .connection-experienceBox {
            margin-bottom: 45px;
          }
        }
  }
    .connection-environment,
  .connection-expectations {
      margin-bottom: 52px !important;
    .connection-sectionTitle{
      margin-bottom: 22px !important;
    }
  }
  .profileBox {

    &-container {
      .profileBox-infoSmall {
        white-space: normal;

        line-height: normal;
        padding: 0px 0px;
        margin-bottom: 0px !important;
      }
      .spending-limit {
        margin-top: 22px;
      }
      .profileBox-action.profileBox-addConnection{
        margin: 20px auto 0px;
      }

    }
    &-image{
      width: 120px;
      height: 120px;
      min-width: 120px;
    }
    .profileBox-firstName {
      font-family: 'CeraPRO-Medium';
      font-size: 36px;
      font-weight: 400;
      .profileBox-lastName{
        font-family: 'CeraPRO-Medium';
        font-size: 36px;
        font-weight: 500;
      }
    }

    //.profileBox-infoSmall{
    //  margin-bottom: 0px;
    //}

  }

  &-navigation {
    margin-left: 50px;
    cursor: pointer;
    //color: #7c878e !important;
    //text-decoration: none;
    //position: relative;
    //font-size: 20px;
    line-height: 14px;

    color: #7c878e !important;
    text-transform: uppercase;
    text-decoration: none;
    padding-left: 14px;
    font-size: 14px;
    font-weight: bold;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 14px;
      height: 14px;
      top: 2px;
      left: 0;
      background-repeat: no-repeat;
      background-image: url('./../../../assets/svg/myBookings/angle-left.svg'); 
    }
  }

  &-content {
    flex: 3;
    background: url('./../../../assets/svg/connections/connection-bg.svg') no-repeat center -50px;
    background-size: 94%;
    padding-top: 54px;
    .profileBox {
      margin-bottom: 67px;
    }
  }

  // &-experience {
  //   margin-top: 67px;
  // }

  &-experienceBox {
    margin-bottom: 55px;
  }

  &-sectionTitle {
    text-transform: uppercase;
    color: $gray1;
    margin-bottom: 28px;
    font-size: 14px;
    font-weight: bold;
    &.--smallMargin {
      margin-bottom: 8px;
    }
  }

  &-pagePadding {
    padding: {
      left: 30px;
      right: 30px;
    }
  }

  &-officesGrid {
    padding-top: 0;
  }

  &-reviews {
    margin-top: 55px;
    padding: {
      left: 30px;
      right: 30px;
    };
  }

  &-review {
    display: flex;
    margin-bottom: 59px;

    @media screen and (max-width: $largeDevices){
      display: block;
    }
  }

  &-reviewOffice {
    width: 418px;
    min-width: 312px;
  }

  &-reviewText {
    padding-left: 29px;
    font-size: 18px;
    color: $gray1;
    line-height: 1.4;

    @media screen and (max-width: $largeDevices){
      padding-left: 0;
    }
  }

  &-starRating {
    margin-bottom: 17px;
  }

  &-sidebarInfo {
    margin-bottom: 48px;
    padding: {
      left: 29px;
      right: 30px;
    }
      .connection-sectionTitle.\--smallMargin {
          margin-bottom: 3px;
      }

    @media screen and (max-width: $largeDevices){
      padding: {
        left: 15px;
        right: 15px;
      }
    }
  }

  &-sidebarInfoText {
    font-size: 24px;
    line-height: 1.4;
    overflow-wrap: break-word;
    @media screen and (max-width: $largeDevices) {
      font-size: 16px;
      font-weight: normal;
    }
  }

  &-actions {
    padding-left: 29px;

    & > .connection-action{
      text-align: left;

      & > .connection-removeConnection{
        padding-left: 0;
        text-align: left;
      }
    }
  }

  &-removeConnection, &-blockConnection {
    text-transform: uppercase;
    background-color: transparent;
    border: 0 none;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    color: $red2;

    &:active {
      transform: translateY(-2px);
    }
  }

  &-blockConnection {
    color: $yellow2;
    margin-top: 22px;
  }

  &-sidebarContent {
    padding: {
      top: 55px;
      bottom: 55px;
    }
  }
}
