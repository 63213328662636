@import '../../../theme/colors';

.checkbox {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 30px;
  border-radius: 100px;
  background-color: $white2;
  cursor: pointer;

  &.checked {
    background-color: $green4;

    &:after {
      right: 0;
      background-color: $green5;
    }
  }

  &:after {
    position: absolute;
    content: '';
    border-radius: 50%;
    top: -5px;
    right: 40px;
    width: 40px;
    height: 40px;
    background-color: $blue1;
    transition: all .1s ease-in-out;
  }
}