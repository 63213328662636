@import 'common/variables';
@import 'common/reusable';
.cookie-container {
  width: 100%;
  background-color: $color-yellow;
  position: fixed;
  bottom: 0;
  z-index: 13;
}
.cookie-content {
  max-width: 1440px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 10px 70px;
  display: flex;
  p {
    font-family: $font-family-cera-bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-size: 14px;
    line-height: 1.43;
    color: $color-black;
    max-width: 944px;
  }
  .cookie-button {
    font-family: $font-family-cera-bold;
    font-stretch: normal;
    font-style: normal;
    border-radius: 3px;
    background-color: $color-black;
    color: $color-yellow;
    padding: 10px 20px;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    margin-left: 27px;
    cursor: pointer;
    max-height: 45px;
  }
  .cookie-privacy-policy {
    font-family: $font-family-cera-bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-size: 14px;
    line-height: 1.43;
    color: $color-black;
    max-width: 944px;
    text-decoration: underline;
  }
}
