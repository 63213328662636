@import '../../../theme/colors';

.modal-dialog-centered {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
&.edit-account-dialog-modal{
  .modal-dialog-content {
    margin: 0;
  }
}
  .modal-dialog-content {
    width: auto;
    border-radius: 3px;
    padding: 30px 30px 30px 77px;
    background-color: $white1;
    position: relative;
    outline: none;

    &:before {
      background: $gray6 url('../../../assets/svg/modalDialog/notification-review-pin.svg') no-repeat 50% calc(100% - 15px);
      content: '';
      display: block;
      height: 100%;
      width: 48px;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      position: absolute;
      left: -1px;
      top: 0;
    }

    .location-review {

      p {
        color: $gray1;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 14px;
        margin-bottom: 13px;
        margin-top: 28px;
      }

      &-header {
        display: flex;
        align-items: center;

        .location-image {
          background-color: $blue1;
          background-size: cover;
          background-position: center center;
          max-width: 45px;
          border-radius: 3px;
          height: 45px;
          flex: 1;
        }

        h3 {
          color: $gray1;
          padding: 0 8px;
          flex: 1;
        }
      }

      textarea {
        width: calc(100% - 40px);
        resize: none;
        height: 134.7px;
        border-radius: 3px;
        background-image: linear-gradient(to top, $white1, #f7f7f7);
        border: solid 1px #cdd1d4;
        padding: 8px 20px;
        font-size: 16px;
        font-style: italic;
        line-height: 1.5;
        text-align: left;
        color: $gray5;
        outline: none;
        font-family: 'CeraPRO-Medium';
      }

      .location-review-action {
        float: right;
        margin-top: 30px;

        .tertiary-button {
          color: $gray1;
          font-size: 14px;
          font-weight: bold;
          text-align: left;
          margin-right: 30px;
          cursor: pointer;
        }

        .secondary-button {
          color: $white1;
          background-color: $yellow1;

          &:hover {
            border: 2px solid $yellow1;
          }
          &:focus{
            outline: none;
          }
        }
      }
    }
  }

  &.--connections {
    .modal-dialog-content {
      &:before {
        background-image: none;
      }

      &:after {
        position: absolute;
        content: '\e903';
        font-family: glyphify;
        left: 12px;
        bottom: 17px;;
        font-size: 22px;
        color: $yellow1;
      }
    }
  }

  &.--postHeader {
    .modal-dialog-content {
      &:before {
        background-image: none;
      }

      &:after {
        position: absolute;
        content: "\E901";
        font-family: glyphify;
        left: 12px;
        bottom: 17px;;
        font-size: 22px;
        color: $yellow1;
      }
    }
  }

  &.--postComment {
    .modal-dialog-content {
      &:before {
        background-image: none;
      }

      &:after {
        position: absolute;
        content: "\E901";
        font-family: glyphify;
        left: 12px;
        bottom: 17px;;
        font-size: 22px;
        color: $yellow1;
      }
    }
  }

  &.--paymentAccount {
    .modal-dialog-content {
      &:before {
        background-image: none;
      }
      
      &:after {
        position: absolute;
        content: "\E903";
        font-family: glyphify;
        left: 12px;
        bottom: 17px;;
        font-size: 22px;
        color: $yellow1;
      }
    }
  }
}
