@import '../../../theme/colors';
.search-radio-group {

  & > label {
    display: block;
    white-space: nowrap;
    margin-bottom: 20px;

    input[type=radio], input[type=checkbox] {
      position: absolute;
      opacity: 0;

      & + span {
        position: relative;
        cursor: pointer;
        padding-left: 28px;
        margin-top: -5px;
        position: relative;
        display: inline-block;
      }

      & + span:before, & + span:after{
        display: block;
        content: '';
        width: 20px;
        height: 20px;
        border-radius: 3px;
        background-image: linear-gradient(to bottom, $white1, $yellow8);
        border: solid 1px $gray3;
        box-sizing: border-box;
        position: absolute;
        z-index: 6;
        left: 0;
        top: 0;
      }

      & + span:after {
        z-index: 1;
      }

      &:hover + span:before {
        background: rgba($green3, .5);
      }

      &:checked + span:before {
        background: url('./../../../assets/svg/check.svg') no-repeat center center #d3ffdd;
        border: solid 1px #93e4a5;
      }
    }
  }

  & > span {
    user-select: none;
    margin-left: 10px;
  }

  input[type=radio]:checked ~ span {
    color: $black1;
    font-weight: 500;

  }
}


.search-radio-group.circled > label input[type=radio] + span:before, .search-radio-group.circled > label input[type=radio] + span:after {
  border-radius: 10px;
  top: -3px;
}
.search-radio-group.circled > label input[type=radio]:checked + span:before {
  background: url('../../../assets/images/green-circle.svg') no-repeat center center #d3ffdd;
  border: solid 1px #93e4a5;
  float: left;
  clear: none;
  margin: 0px 10px 0 0px;
}
.search-radio-group.circled > label {
  margin-bottom: 10px;
}
.community-audience__label {
  font-family: $font-family-cera-regular;
  font-size: $font-size-default;
  color: $color-grey-dark;
}
#community-audience__label-extra {
  color: $color-grey;
}