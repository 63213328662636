.media-filters-selector__container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 247px;
  border-radius: 3px;
  margin-bottom: 15px;

  .media-filters-selector__label-container {
    width: 83px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;

    .media-filters-selector__label {
      @include text-typography($font-family-cera-bold, 14px);
      text-align: center;
      color: $color-grey;
      text-transform: uppercase;
    }
  }

  .media-filters-selector__picker-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: $color-black;
    width: 247px;
    position: relative;

    .media-filters-selector__picker {
      border:0;
      @include text-typography($font-family-cera-bold, 14px);
      color: #d9dfe3;
      outline: none;
      cursor: pointer;
      text-transform: uppercase;
      background-color: $color-black;
      width: 100%;
      -webkit-appearance: none;
      -moz-appearance: none;

      option {
        text-transform: uppercase;
        cursor: pointer;
      }
    }

    .media-filters-selector__picker-arrow {
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
      width: 12px;
    }
  }
}

