.community-audience__picker {
  margin-left: 30px;
}
#community-audience__tags{
  .community-audience__tag {
    display: inline-block;
    margin: 0px 10px 10px 0px;
    padding: 7px;
    border: 2px solid $color-grey-light;
    border-radius: 3px;
    text-transform: uppercase;
    font-family: $font-family-cera-bold;
    font-size: 14px;
    background-color: $color-white;
    color: $color-grey;
    text-transform: uppercase;
    &:hover {
      cursor: pointer;
    }
  }
  .community-audience__tag-checked {
    background-color: $color-yellow;
    color: $color-black;
    border-color: $color-yellow;
  }
}
