.open-login__container--full {
  padding: 20px 30px;
  min-height: calc(100vh - 175px);
}
.open-login__title {
  @include text-typography($font-family-cera-regular, 30px);
  color: $color-grey-white;
  padding: 6px 5px;
  &--yellow  {
    display: inline;
    @include text-typography($font-family-cera-regular, 30px);
    color: $color-yellow;
  }
}
.open-login__subtitle {
  @include text-typography($font-family-cera-regular, 16px);
  color: $color-grey;
  padding: 0 5px;
  display: inline;
}
.open-login__container--small {
  width: 622px;
  margin-left: 100px;
  margin-top: 30px;
}
.open-login__container--centered {
  margin-top: 80px;
}
.open-login__later-message {
  display: block;
  @include text-typography($font-family-cera-bold, 16px);
  color: $color-yellow;
  padding: 0 5px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
}
.open-login__delimiter {
  display: flex;
  @include text-typography($font-family-cera-bold, 12px);
  color: $color-grey;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  margin: 10px 0;
  &::before, &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid $color-grey;
  }
  &::before {
    margin-right: 10px;
  }
  &::after {
    margin-left: 10px;
  }
}
.open-login__account-message {
  text-align: center;
  margin-top: 15px;
}
.open-login__info-message, .open-login__action-message {
  display: inline;
  @include text-typography($font-family-cera-regular, 22px);
}
.open-login__action-message {
  padding-left: 3px;
  color: $color-yellow;
}
.open-login__loader {
  height: 20px;
  background-image: url('../../../assets/images/loader-inverse.gif');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100px;
  margin: auto;
}
.open-login__footer {
  position: relative;
  text-align: center;
  transform: translateX(-50px);
  .list-inline-dot {
    a {
      padding: 0;
      margin: 0;
    }

    .bulletDot {
      padding: 0 10px 0 10px;
    }
  }
}
.reset-confirmation__container {
  margin-top: 50px;
}