.meeting-attendees_buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 57px;

  .accept {
    width: 174px;
    height: 50px;
    border-radius: 3px;
    background-color: #f0b323;
    font-family: $font-family-cera-bold;
    font-size: 14px;
    text-align: center;
    color: #131e29;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-right: 10px;
  }

  .decline {
    width: 173.5px;
    height: 50px;
    border-radius: 3px;
    border: solid 2px #f0b323;
    font-family: $font-family-cera-bold;
    font-size: 14px;
    text-align: center;
    color: #f0b323;
    background-color: #0f171f;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .accept-img {
    margin-right: 10px;
  }

  .decline-img {
    margin-right: 8px;
  }

}