.booking-office-services {

  width: 30%;
  margin-left: 2%;
  &-title {
    @extend %officeDetails-titleBorder;
  }

  &-fieldDescription {
    @extend %typography-ceraGR;

    color: $gray1;
    font-size: 14px;
    margin-top: 10px;
  }

  &-list {
    @extend %displayFlex-wrap;
  }

  &-packages {
    @extend %displayFlex-wrap;

    margin-top: 50px;
  }

  .package-available,
  .package-unavailable {
    @extend %typography-ceraGR;

    color: $white1;
    font-size: 36px;
  }

  .package-available,
  .package-unavailable,
  .service-available,
  .amenity-available,
  .service-unavailable {
      @extend %officeDetails-items;
      width: 100% !important;
      .sublabel{
        color: $gray1;
        font-size: 14px;

      }
  }
  .amenity-available{
    padding-bottom: 10px;
  }

  .service-available-nonfree{
    display: flex;
    padding-bottom:20px;
    .label{
      width: 70%;
      font-size: 24px;
    }
    .price{
      width: 30%;
      font-size: 18px;
      text-align: right;
    }
  }

  .package-available:after,
  .amenity-available:after,
  .service-available:after {
    background-image: url(../../../assets/svg/officeDetails/feature-available.svg)
  }

  .package-unavailable:after,
  .service-unavailable:after {
    background-image: url(../../../assets/svg/officeDetails/feature-unavailable.svg)
  }

  .service-unavailable {
    color: $gray8;
  }
}
