@charset "UTF-8";
.main-button-leftSide, .main-button, .directChat-dialog-actionShare {
  background-color: #f0b323;
  box-sizing: border-box;
  border-radius: 3px;
  color: #131e29;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  height: 50px;
  line-height: 50px;
  margin-bottom: 2px;
  padding: 0 30px;
  user-select: none; }
  .main-button-leftSide:hover, .main-button:hover, .directChat-dialog-actionShare:hover {
    background-color: #ffc824; }
  .main-button-leftSide:active, .main-button:active, .directChat-dialog-actionShare:active {
    margin-bottom: 0; }
  .main-button-leftSide:disabled, .main-button:disabled, .directChat-dialog-actionShare:disabled, .disabled.main-button-leftSide, .disabled.main-button, .disabled.directChat-dialog-actionShare {
    background-color: #7c878e;
    color: #69757d;
    cursor: default; }
    .main-button-leftSide:disabled:hover, .main-button:disabled:hover, .directChat-dialog-actionShare:disabled:hover, .disabled.main-button-leftSide:hover, .disabled.main-button:hover, .disabled.directChat-dialog-actionShare:hover {
      background-color: #7c878e;
      color: #69757d; }
    .main-button-leftSide:disabled:active, .main-button:disabled:active, .directChat-dialog-actionShare:disabled:active, .disabled.main-button-leftSide:active, .disabled.main-button:active, .disabled.directChat-dialog-actionShare:active {
      margin-bottom: 0; }

.main-button-leftSide {
  height: 40px;
  line-height: 40px;
  padding: 0 20px; }

.secondary-button-leftSide, .secondary-button {
  background-color: transparent;
  border: 2px solid #f0b323;
  border-radius: 3px;
  box-sizing: border-box;
  color: #f0b323;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  height: 50px;
  line-height: 46px;
  margin-bottom: 2px;
  padding: 0 28px;
  user-select: none; }
  .secondary-button-leftSide:hover, .secondary-button:hover {
    border-color: #ffc824;
    color: #ffc824; }
  .secondary-button-leftSide:disabled, .secondary-button:disabled, .disabled.secondary-button-leftSide, .disabled.secondary-button {
    border-color: #69757d;
    color: #69757d;
    cursor: default; }
    .secondary-button-leftSide:disabled:hover, .secondary-button:disabled:hover, .disabled.secondary-button-leftSide:hover, .disabled.secondary-button:hover {
      border-color: #69757d;
      color: #69757d; }
    .secondary-button-leftSide:disabled:active, .secondary-button:disabled:active, .disabled.secondary-button-leftSide:active, .disabled.secondary-button:active {
      margin-bottom: 0; }

.secondary-button-leftSide {
  height: 40px;
  line-height: 36px;
  padding: 0 18px; }

.tag {
  background-color: transparent;
  border-radius: 3px;
  border: solid 1px #cdd1d4;
  box-sizing: border-box;
  color: #a0abb2;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  padding: 9px 29px 9px 9px;
  position: relative;
  user-select: none; }
  .tag::after {
    background: url("../assets/x.svg") no-repeat 0 0;
    color: red;
    background-size: 11px 11px;
    content: " ";
    display: block;
    height: 11px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 11px; }
  .tag:hover, .tag:active {
    border-color: #a0abb2; }
  .tag:disabled, .disabled.tag {
    background-color: #7c878e;
    border-color: #7c878e;
    color: #69757d;
    cursor: default; }
    .tag:disabled:hover, .tag:disabled:active, .disabled.tag:hover, .disabled.tag:active {
      background-color: #7c878e;
      border-color: #7c878e;
      color: #69757d; }

.directChat-dialog-actionCancel {
  background: transparent;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin: 2px 0;
  padding: 5px;
  text-decoration: none;
  text-transform: uppercase; }

.directChat-dialog-actionCancel {
  color: #7c878e; }
  .directChat-dialog-actionCancel:hover, .directChat-dialog-actionCancel:active, .active.directChat-dialog-actionCancel {
    color: #f2f2f2; }
  .directChat-dialog-actionCancel:active {
    margin: 4px 0 0; }
  .directChat-dialog-actionCancel:disabled, .disabled.directChat-dialog-actionCancel {
    color: #333f48; }
    .directChat-dialog-actionCancel:disabled:active, .disabled.directChat-dialog-actionCancel:active {
      margin: 2px 0; }

.main-button:focus {
  outline: none; }

.main-button-leftSide:focus {
  outline: none; }

.secondary-button {
  font-family: 'CeraPRO-Medium';
  margin-top: 2px; }
  .secondary-button:focus {
    outline: none; }

.secondary-button-leftSide {
  margin-top: 2px; }
  .secondary-button-leftSide:focus {
    outline: none; }

.directChat-dialog-messageBox {
  align-content: center;
  background-image: linear-gradient(to top, #333f48, #26333c);
  border: 1px solid transparent;
  border-top-color: #26333c;
  border-bottom-color: #333f48;
  border-radius: 3px;
  box-sizing: border-box;
  color: #a1abb2;
  font-size: 16px;
  font-weight: 500;
  height: 50px;
  line-height: 1.56;
  outline: none;
  padding: 10px 20px;
  resize: none; }
  .active.directChat-dialog-messageBox, .directChat-dialog-messageBox:focus {
    background-image: linear-gradient(to top, #fffefa, #fffcf3);
    border: 1px solid #ffd87d;
    color: #131e29;
    font-size: 18px;
    font-weight: normal; }

.directChat-dialog-messageBox {
  display: flex;
  flex-grow: 1; }

.directChat-dialog-messageBox {
  border-radius: 3px;
  background-image: linear-gradient(to top, #fff, #f7f7f7);
  border: 1px solid #cdd1d4;
  color: #7c878e;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  resize: none; }
  .directChat-dialog-messageBox:hover, .directChat-dialog-messageBox:focus {
    background-image: linear-gradient(to top, #fffefa, #fffcf3);
    border: 1px solid #ffd87d; }

.label-optional {
  font-family: 'CeraPRO-Regular';
  font-size: 14px;
  font-style: italic;
  text-transform: capitalize;
  color: #aab4bb; }

.icon::before, .directChat-dialog.modal-dialog-centered .modal-dialog-content::before {
  content: '';
  font-family: 'glyphify' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon.icon-dots::before, .directChat-dialog.modal-dialog-centered .icon-dots.modal-dialog-content::before {
  content: ""; }

.icon.icon-community::before, .directChat-dialog.modal-dialog-centered .icon-community.modal-dialog-content::before {
  content: ""; }

.icon.icon-booking::before, .directChat-dialog.modal-dialog-centered .icon-booking.modal-dialog-content::before {
  content: ""; }

.icon.icon-connections::before, .directChat-dialog.modal-dialog-centered .icon-connections.modal-dialog-content::before {
  content: ""; }

.icon.icon-directChat::before, .directChat-dialog.modal-dialog-centered .modal-dialog-content::before {
  content: ""; }

.icon.icon-ecoStats::before, .directChat-dialog.modal-dialog-centered .icon-ecoStats.modal-dialog-content::before {
  content: ""; }

.icon.icon-events::before, .directChat-dialog.modal-dialog-centered .icon-events.modal-dialog-content::before {
  content: ""; }

.icon.icon-home::before, .directChat-dialog.modal-dialog-centered .icon-home.modal-dialog-content::before {
  content: ""; }

.icon.icon-comment::before, .directChat-dialog.modal-dialog-centered .icon-comment.modal-dialog-content::before {
  content: ""; }

.icon.icon-fullStar::before, .directChat-dialog.modal-dialog-centered .icon-fullStar.modal-dialog-content::before {
  content: ""; }

.icon.icon-like::before, .directChat-dialog.modal-dialog-centered .icon-like.modal-dialog-content::before {
  content: ""; }

.icon.icon-photo::before, .directChat-dialog.modal-dialog-centered .icon-photo.modal-dialog-content::before {
  content: ""; }

.icon.icon-share::before, .directChat-dialog.modal-dialog-centered .icon-share.modal-dialog-content::before {
  content: ""; }

.icon.icon-star::before, .directChat-dialog.modal-dialog-centered .icon-star.modal-dialog-content::before {
  content: ""; }

.icon.icon-quotes::before, .directChat-dialog.modal-dialog-centered .icon-quotes.modal-dialog-content::before {
  content: ""; }

.icon.icon-arrow::before, .directChat-dialog.modal-dialog-centered .icon-arrow.modal-dialog-content::before {
  content: ""; }

.icon.icon-location::before, .directChat-dialog.modal-dialog-centered .icon-location.modal-dialog-content::before {
  content: ""; }

.icon.icon-locationFull::before, .directChat-dialog.modal-dialog-centered .icon-locationFull.modal-dialog-content::before {
  content: ""; }

.icon.icon-plus::before, .directChat-dialog.modal-dialog-centered .icon-plus.modal-dialog-content::before {
  content: ""; }

.icon.icon-close::before, .directChat-dialog.modal-dialog-centered .icon-close.modal-dialog-content::before {
  content: ""; }

.icon.icon-delete::before, .directChat-dialog.modal-dialog-centered .icon-delete.modal-dialog-content::before {
  content: ""; }

.icon.icon-markRead::before, .directChat-dialog.modal-dialog-centered .icon-markRead.modal-dialog-content::before {
  content: ""; }

.icon.icon-mute::before, .directChat-dialog.modal-dialog-centered .icon-mute.modal-dialog-content::before {
  content: ""; }

.icon.icon-muted::before, .directChat-dialog.modal-dialog-centered .icon-muted.modal-dialog-content::before {
  content: ""; }

.icon.icon-newConversation::before, .directChat-dialog.modal-dialog-centered .icon-newConversation.modal-dialog-content::before {
  content: ""; }

.icon.icon-report::before, .directChat-dialog.modal-dialog-centered .icon-report.modal-dialog-content::before {
  content: ""; }

.icon.icon-search::before, .directChat-dialog.modal-dialog-centered .icon-search.modal-dialog-content::before {
  content: ""; }

.icon.icon-slider::before, .directChat-dialog.modal-dialog-centered .icon-slider.modal-dialog-content::before {
  content: ""; }

.directChat-dialog.modal-dialog-centered .modal-dialog-content {
  box-sizing: border-box;
  min-width: 580px;
  padding: 25px 25px 25px 72px; }
  .directChat-dialog.modal-dialog-centered .modal-dialog-content:before {
    align-items: flex-end;
    background: #333f48;
    box-sizing: border-box;
    color: #f0b323;
    display: flex;
    font-size: 24px;
    justify-content: center;
    padding-bottom: 15px;
    text-align: center;
    vertical-align: bottom; }

.directChat-dialog-title {
  color: #7c878e;
  font-size: 24px;
  padding-bottom: 25px; }

.directChat-dialog-messageBox {
  box-sizing: border-box;
  height: 120px;
  padding: 15px 18px;
  width: 100%; }

.directChat-dialog-actions {
  display: flex;
  justify-content: flex-end; }

.directChat-dialog-action {
  margin-left: 30px; }

.directChat-dialog-actionShare {
  text-transform: uppercase; }

.directChat-dialog-actionCancel {
  text-transform: uppercase; }
  .directChat-dialog-actionCancel:hover {
    color: #26333c; }

.directChat-dialog-message {
  color: #ff4a4a;
  font-size: 18px;
  margin-top: 44px;
  margin-bottom: 19px; }

.directChat-dialog-info {
  color: #7c878e;
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 61px; }
