.card__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: $entity-spacing;
  border-radius: $entity-card-border-radius;
  &.card__container--margin-right {
    margin-right: $entity-spacing;
  }
  &.card__container--dark {
    background: $color-grey-dark;
  }
  &.card__container--light {
    background: $color-grey-medium;
  }
  &.card__container--fixed-height {
    min-height: $entity-card-height;
    height: auto;
  }
  &.card__container--border {
    @include default-border;
  }
}

.card__title {
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  font-family: $font-family-cera-regular;
  font-size: $font-size-title;
  line-height: 0.63;
  color: $color-grey-light;
}

.card__subtitle {
  padding: 0;
  margin: 0;
  margin-bottom: 16px;
  font-family: $font-family-cera-light;
  font-size: $font-size-default;
  line-height: 0.71;
  color: $color-grey-white;
}

@media (max-width: 1360px) {
  .card__container {
    padding: $entity-spacing-small;
    &.card__container--fixed-height {
      min-height: $entity-card-height-small;
    }
    .card__title {
      font-size: $font-size-title-small;
      margin-bottom: 16px;
    }
    .card__subtitle {
      margin-bottom: 12px;
      font-size: $font-size-default-small;
    }
  }
}
