.booking-payment {
  height: 100%;
  background-color: $yellow1;
  .booking-payment-content .booking-payment-title {
    margin-top: 0;
  }
  .checkboxPaymentOption{
    white-space: nowrap;
    margin-bottom: 20px;
    margin-top: 20px;
    display: flex;
    
    .left-side{
      flex: 1;
    }

    .first-line{
      font-size: 24px;
      font-weight: bold;
      color: $black1;
    }

    .second-line{
      font-size: 14px;
      font-weight: bold;
      color: $black1;
    }
  }
  
  .voucherInfo{
    bottom: -2px;
    position: relative;
  }

  .subTotalStyle{
    //font-size: 24px;
    //font-weight: 500;
    line-height: 1.2;

    font-family: 'CeraPRO-Medium';
    font-size: 30px!important;
    font-weight: 500;
  }
  .booking-payment-scrollable {
    .booking-payment-item:first-child{
      margin-bottom: 0px !important;

    }
    > div > .spacer-30:last-child{
      height: 0px !important;
    }
  }
  .booking-payment-footer {
    height: auto;
    padding-bottom: 70px;
  }
    .payment-options-box-light {
      > div {
        padding-bottom: 15px;
        &:last-child{
          padding-bottom: 0px;
        }
      }
        label{
            font-family: 'CeraPRO-Medium';
            font-size: 24px;
            font-weight: 500;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .paymentProfile-second-line{
            font-family: 'CeraPRO-Medium';
            font-size: 14px;
            .active-subscription{
              font-size: 16px;
              color: $green1;
              font-weight: 500;
            }
        }
    }

  .payment-options-box-light .LoaderInverseSmall {
    margin-bottom: 20px;
  }
  .product-price-breakdown{
    & > div{
    color: $black1;
    font-size: 14px;
    }
    margin-top: -5px;
    margin-left:10px;
  }

  .profileUpdateBox{
    width: 100%;
    margin-bottom: 30px;
    & + .LoaderInverseSmall {
      margin-bottom: 20px;
    }
    .add-card-button{
      line-height: 18px;
      font-family: 'CeraPRO-Medium';
      padding: 17px;
    }
  }

  .back-button{
    position: relative;
    top:-3px;
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: bold;
    color: $blue1;

    &:before {
      content: '';
      position: relative;
      display: inline-block;
      width: 14px;
      height: 14px;
      top: 2px;
      left: 0;
      background-repeat: no-repeat;
      background-image: url('../../../assets/svg/addBookingSidebar/angle-left.svg');
    }
    &--padding {
      padding: 30px;
    }
  }

  .card-errors-container{
    padding: 15px 0 15px 0;
    color: red;
    text-align: left;
  }

  .book-errors{
    padding: 15px 0 15px 0;
    color: red;
    text-align: left;
  }

  .voucher-errors-container{
    .error-item{
      font-size: 14px;
      margin-bottom: 15px;
      color: red;
      text-align: left;
    }
  }

  .new-credit-card-connected{
      margin-top: 10px;
      color: $white1;
      background-color: $blue3;
      padding: 15px;
      display: flex;
      .check{
        content: '';
        background-image: url('../../../assets/svg/addBookingSidebar/check-cc-connected.svg');
        width:40px;
        height: 40px;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center center;
      }
      .content{
        font-weight: normal;
        font-size: 16px;
        display: inline-block;
        padding-left: 50px;
        line-height: 20px;
        span{
          &.label{
            font-size: 12px;
            display: block;
            line-height: 18px;
          }
        }
      }
  }

  &-title {
    @extend %addBooking-title;
    display: block !important;
  }

  &-content {
    @extend %addBooking-content;
  }

  &-scrollable {
    padding: 30px;
  }

  &-footer {
    @extend %addBooking-footer;
    position: relative !important;
    margin-bottom: 5px;

    .booking-payment-footer-price {
      margin-bottom: 5px;
    }
  }

  &-finish {
    @extend %addBooking-finishButton;
    border-radius: 3px;
    margin-bottom: 30px!important;
    margin-top: 15px;
    font-family: 'CeraPRO-Medium';
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
  }

  &-info {

    border-radius: 3px;
    background-color: $green2;
    line-height: 1.44;
    font-weight: normal;
    font-size: 14px;
    padding: 30px 20px;

    & > strong {
      font-weight: bold;
      font-size: 14px;
      text-decoration: underline;
    }
  }

  &-item {
    margin-bottom: 10px;
    display: flex;
    .subTotalStyle{
      //font-size: 24px;
      //font-weight: 500;
      line-height: 1.2;

      font-family: 'CeraPRO-Medium';
      font-size: 30px!important;
      font-weight: 500;
    }
    & > .item-label {
      font-weight: 500;
      color: $blue1;
      font-size: 22px;
      line-height: 1.56;
      width: 100%;
      text-transform: capitalize;
    }

    & > .item-value {
      font-weight: 500;
      color: $blue1;
      font-size: 24px;
      line-height: 1.17;
      float: right;
      width: 30%;
      text-align: right;
    }

    &.free > .item-label{
      &:after {
        content: '';
        position: absolute;
        right: 0;
        width: 14px;
        height: 14px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../../../assets/svg/officeDetails/feature-available.svg');
      }
    }
  }

  &-subitem {
    margin: 0 0 10px 10px;

    & > .item-label,
    & > .item-value {
      color: $blue1;
      font-size: 14px;
      line-height: 1.56;
      vertical-align: text-top;
    }

    & > .item-value {
      float: right;
    }

    & > .item-sublabel {
      > div {
        color: $black1;
        display: block;
        font-size: 12px;
      }
      color: $black1;
      display: block;
      font-size: 12px;
    }

    &.free > .item-label{
      &:after {
        content: '';
        position: absolute;
        right: 30px;
        width: 14px;
        height: 14px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../../../assets/svg/officeDetails/feature-available.svg');
      }
    }
  }

  .booking-payment-item{
    span{
      font-size: 24px;
      //font-weight: 400;
      line-height: 1;
    }
    .item-label{
      padding-right: 20px;
      position: relative;
    }
  }
  .booking-footer-item {
    display: flex;
    margin-bottom: 10px;

    .subto
    & + div .booking-payment-title{
      margin-top: 30px;
    }

    & + div> .LoaderInverseSmall{
      margin-top: 35px;
    }
    &:nth-child(1){
      //margin-top: 25px;
      span{
        font-size: 24px;
        font-weight: 500;
        line-height: 1.2;
      }
    }
    &:nth-child(2){
      span{
        font-size: 24px;
        line-height: 1.2;
      }
    }
    &.mt20 {
      margin-top: 22px;
      
      span{
        //font-size: 24px;
        //font-weight: 500;
        line-height: 1.2;

        font-family: 'CeraPRO-Medium';
        font-size: 30px!important;
        font-weight: 500;
      }
    }

    & > .item-label {
      //font-weight: 500;
      color: $blue1;
      font-size: 26px;
      line-height: 1.56;
      width: 70%;
      text-transform: capitalize;
      padding-right: 10px;
    }

    & > .item-value {
      //font-weight: 500;
      color: $blue1;
      width: 30%;
      font-size: 26px;
      line-height: 1.17;
      text-align: right;
      word-break: break-all;
      white-space: pre;
      display: inline-table;

    }

    .delete-coupon{
      color: red;
      font-size: 12px !important;
      font-weight: bold;
      cursor: pointer;
      text-align: right;
      width: 100%;
      margin-top: -5px;
    }

    .voucher-input{
      width: 100%;
      padding: 14px 10px;
      border-radius: 3px;
      border: 1px solid $gray3;
      color: $black1;
      font-size: 16px;
    }

    .voucher-redeem-button{
        border: 2px solid $black1;
        color: $black1;
        font-weight: bold;
        padding: 0 15px;
        outline: none;
        font-size: 16px;

        &:disabled{
          border-color: $black1;
          color: $black1;
          cursor: default;
        }
      &[disabled=""]{
        border-color: $black1;
        color: $black1;
        margin-top: 0!important;
        cursor: default;
      }
    }
  }
  .booking-footer-item-sub{
    .booking-footer-sublabel {
      margin-top: -10px;
      margin-bottom: 10px;
      display: flex;
      width: 100%;
      //margin-top: -15px;

      & > .item-label {
        font-weight: normal;
        color: $blue1;
        font-size: 14px;
        line-height: 1.56;
        width: 70%;
        //font-weight: 500;
      }

      & > .item-value {
        font-weight: normal;
        color: $blue1;
        font-size: 14px;
        line-height: 1.17;
        float: right;
        width: 30%;
        text-align: right;
        //font-weight: 500;
      }
    }
  }

  .booking-payment-subitem{
    span{
      //font-weight: 500;
    }
  }
}

@media (min-width: 1300px) {
  .booking-payment {
    .booking-payment-footer {
      padding-bottom: 0px;
      height: auto;
    }
  }
}

button{
  margin: 15px 0 0 0;
  font-size: 14px;
  display: block;
  padding: 20px;
  width: 100%;
  border-radius: 3px;
  line-height: 14px;

  &:focus {outline:0;}

  &.personal-button{
    color: $white1;
    background-color: $blue3;
    &:hover{
      cursor: pointer;
      background-color: lighten($blue3, 2%);
    }
    &.outside{
      border-radius: 5px;
      &:focus {outline:0;}
      margin-bottom: 35px;
      margin-top: 15px;
    }
  }

  &.business-button{
    color: $black1;
    background-color: $yellow1;
    font-weight: bold;
    &:hover{
      cursor: pointer;
      background-color: $yellow2;
    }
  }
}

.payment-options-box{
  margin-top: 10px;
  padding: 30px;
  background-color: #101F28;

  button{
    margin: 2px 0 15px 0;
    font-size: 14px;
    display: block;
    padding: 20px;
    width: 100%;
    border-radius: 3px;

    &:focus {outline:0;}

    &.personal-button{
      color: $white1;
      background-color: $blue3;
      &:hover{
        cursor: pointer;
        background-color: lighten($blue3, 2%);
      }
      &.outside{
        border-radius: 5px;
        &:focus {outline:0;}
      }
    }

    &.business-button{
      color: $black1;
      background-color: $yellow1;
      font-weight: bold;
      &:hover{
        cursor: pointer;
        background-color: $yellow2;
      }
    }
  }

  &-description{
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
  }

  &-light{
    &:focus {outline:0;}
    .add-card-button{
      @extend %addBooking-finishButton;
      font-family: 'CeraPRO-Medium';
      border-radius: 3px;
      margin-top: 10px;
      line-height: 18px !important;
      padding: 17px;
      &:focus {outline:0;}
      &:hover{
        cursor: pointer;
      }
      &.disabled{
        cursor: not-allowed;
        background-color: rgba(255, 255, 255, 0.3);
      }
    }

    .StripeElement {
      background-color: #fff;
      color: $black1;
      padding: 15px;
      margin-top: 2px;
      border-radius: 3px;
      border: 1px solid transparent;
      font-family: 'CeraPRO-Medium';

      &--focus{
        border: 1px solid $black1;
      }

      &--complete{
        border: 1px solid $green6;
      }

      &--invalid{
        border: 1px solid $red1;
        // &::after{
        //   content: 'INVALID CARD DETAILS';
        //   font-size: 16px;
        //   line-height: 40px;
        //   color: red;
        //   display: block;
        //   text-align: center;
        // }
      }
    }
  }
}

@media (min-width: 576px){
  .business-dialog-modal.modal-dialog {
  max-width: 630px;
  }
}

.business-dialog-modal{

  position: fixed !important;
  width: 630px;
  margin-left: auto;
  margin-right: auto;
  .modal-dialog-content{
    width: 100%;
    pointer-events: auto;
    &:before{
      //background: $gray6 url('../../../assets/svg/modalDialog/user.svg') no-repeat 50% calc(100% - 15px);
    }

    .dialog-center-confirmation{
      .dialog-center-confirmation-header{
        h3{
          color: $gray1;
          font-size: 20px;
          font-weight: normal;
        }
      }
      .dialog-center-confirmation-body{
          div{
            color: $gray1;
            padding: 50px 0 50px 0;
            font-size: 14px;
          }
      }
    }
    .dialog-center-confirmation-action{
        text-align: right;

        .secondary-button{
          color: $black1;
          background-color: $yellow1;

          display: inline-block;
          font-size: 14px;
          font-weight: bold;
          height: 50px;
          line-height: 46px;
          margin-bottom: 2px;
          padding: 0 28px;
          &:hover{
            cursor: pointer;
            border: 2px solid $yellow1;
            color: $white1;
          }
          &:focus{
            outline: none;
          }
        }
        .tertiary-button{
          color: $gray1;
          background-color: transparent;

          display: inline-block;
          font-size: 14px;
          font-weight: bold;
          height: 50px;
          line-height: 46px;
          margin-bottom: 2px;
          padding: 0 28px;
          &:hover{
            cursor: pointer;
          }
        }
    }
  }
}

.events-details {
  .events-details__label {
    color: #000000;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 17px;
    .events-details__counter {
      font-size: 14px;
      &.events-details__counter--error {
        color: $red1;
      }
    }
  }
  .events-details__textarea {
    width: 100%;
    resize: none;
    color: #131e29;
    padding: 10px 20px;
    line-height: 28px;
    background: linear-gradient(to top, #fffefa, #fffcf3);
    border-radius: 3px;
    border: 1px solid #ffd87d;
    &:focus {
      outline: none;
    }
  }
  .events-details__error {
    color: $red1;
    margin-top: 8px;
    font-weight: 600;
  }
}

.initialPopUp .initial-schedule-button {
  padding: 0 23px;
}

.payment-profile__details {
  background-color: #f0b323;
  width: calc(100vw - 585px);
  position: absolute;
  right: 455px;
  padding: 20px;
  border-right: 3px solid #131e29;

  ::-webkit-input-placeholder {
    font-family: 'CeraPRO-Light';
    font-weight: 600;
    font-size: 16px;
  }

  :-ms-input-placeholder {
    font-family: 'CeraPRO-Light';
    font-weight: 600;
    font-size: 16px;
  }

  ::placeholder {
    font-family: 'CeraPRO-Light';
    font-weight: 600;
    font-size: 16px;
  }

  .payment-profile__title {
    text-align: center;
    font-family: 'CeraPRO-Regular';
    font-size: 23px;
    font-weight: 600;
    color: #131e29;
    margin-bottom: 30px;
  }

  .payment-profile__address {
    max-width: 710px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  /* form specific formatting */
  .form-group {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
  }

  .form-group label {
    flex: none;
    display: block;
    width: 125px;
    font-weight: bold;
    font-size: 1em;
  }

  .form-group label.right-inline {
    text-align: right;
    padding-right: 8px;
    padding-left: 10px;
    width: auto;
  }

  .form-group .input-control {
    //flex: 1 1 auto;
    display: block;
    margin-right: 12px;
    padding: 10px;
    color: #000;
    font-family: 'CeraPRO-Light';
    font-weight: 600;
    border-radius: 3px;
    border: 1px solid #f0b323;

    &:focus {
      border: 1px solid;
    }
  }

  .payment-profile__card-details {
    max-width: 345px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  .payment-address-input-error.input-control {
    border: 1px solid red;
  }

  .form-group__input {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .payment-profile__error-msg {
    font-size: 12px;
    color: red;
    padding: 7px 0 0 0;
  }
}

.booking-payment .left-card-details .card-errors-container {
  padding: 7px 0 0px 0;
  color: red;
  font-weight: 600;
  text-align: center;
  font-size: 14px;
}

@media screen and (max-width: 1860px) {
  .payment-profile__details {
    width: calc(100vw - 570px);
    right: 440px;
  }
}

@media screen and (max-width: 1800px) {
  .payment-profile__details {
    width: calc(100vw - 562px);
    right: 432px;
  }
}

@media (max-width: 1440px) {
  .booking-payment .booking-footer-item {
    .voucher-input {
      font-size: 12px;
      padding: 12px 10px;
    }
    .voucher-redeem-button {
      font-size: 14px;
      height: 40px;
      line-height: unset;
    }
  }
}

@media (max-width: 768px) {
  .payment-profile__details {
    .form-group {
      flex-direction: column;
    }

    .form-group .input-control {
      margin-top: 2px;
    }

    .form-group label.right-inline {
      text-align: left;
      padding-right: 0;
      padding-left: 0;
    }
  }
}