.payment-method_cc {
  max-width: 624px;
  min-height: 138px;
  border-radius: 3px;
  background-color: #666ee8;
  margin-top: 20px;
  padding: 20px;
  margin-bottom: 60px;

  &.payment-method_bc {
    background-color: #0098e2;
  }
  &.voucher-added-group {
    background-color: $color-grey-medium;
    min-height: 130px;
  }

  .add-cc-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card-title {
    font-family: $font-family-cera-medium;
    text-align: center;
    font-size: 24px;
    color: $color-white;
  }

  .card-button {
    font-family: $font-family-cera-bold;
    width: 222px;
    height: 40px;
    border-radius: 3px;
    background-color: $color-yellow;
    font-size: 14px;
    text-align: center;
    color: $color-black;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
  }

  .StripeElement {
    background-color: #fff;
    color: #000;
    padding: 15px;
    border-radius: 3px;
    border: 1px solid transparent;
    font-family: $font-family-cera-medium;
    margin-bottom: 10px;
  }

  .add-card-button {
    width: 100%;
    height: 50px;
    line-height: 14px;
    text-align: center;
    text-transform: uppercase;
    background-color: $color-black;
    color: $color-yellow;
    font-family: $font-family-cera-medium;
    cursor: pointer;
    user-select: none;
    border-radius: 3px;
    padding-top: 18px;
  }

  .card-connected {
    margin-left: 8px;
    margin-top: 15px;
  }

  .credit-card-connected {
    font-family: $font-family-cera-medium;
    color: $color-white;
    font-size: 24px;
    margin-bottom: 10px;
  }
}

.card-error-text {
  font-family: $font-family-cera-regular;
  color: $color-red;
  margin-top: -40px;
}

.error-item {
  font-family: $font-family-cera-regular;
  color: red;
  padding-top: 10px;
}
.payment-method_voucher {
  display: flex;
  flex-direction: row;
  margin-top: 20px;

  .voucher-input {
    max-width: 431px;
    width: 100%;
    margin-top: 0;
    margin-right: 20px;

    &::-webkit-input-placeholder { /* Edge */
      font-family: $font-family-cera-regular;
      font-style: italic;
      color: $color-grey2;
      font-size: 16px;
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      font-family: $font-family-cera-regular;
      font-style: italic;
      color: $color-grey2;
      font-size: 16px;
    }

    &::placeholder {
      font-family: $font-family-cera-regular;
      font-style: italic;
      color: $color-grey2;
      font-size: 16px;
    }
  }

  .voucher-button {
    width: 173px;
    height: 50px;
    font-family: $font-family-cera-bold;
    border-radius: 3px;
    border: solid 2px $color-yellow;
    font-size: 14px;
    color: $color-yellow;
    background-color: $color-black;
    padding: 0;
    text-transform: uppercase;
    margin: 0;
  }

}

.voucher-applied {
  font-family: $font-family-cera-regular;
  font-size: 14px;
  color: $color-green-active;
  margin-left: 48px;
  margin-top: 13px;
  line-height: 0.71;
}

.voucher-balance {
  font-family: $font-family-cera-medium;
  font-size: 24px;
  color: $color-white;
  margin-left: 10px;
  padding: 10px 0 ;
}

.remove-voucher {
  font-family: $font-family-cera-bold;
  font-size: 14px;
  color: #e6b600;
  width: 130px;
  background-color: $color-grey-medium;
  padding: 0;
  text-transform: uppercase;
  margin-left: 45px;
  margin-top: 8px;

  &.credit-card-remove-button {
    background-color: #666ee8;
    width: 152px;
    margin-left: 40px;
  }
}

.check-symbol {
  margin-right: 10px;
}
