.community-modals {
  .base-modal__modal-content {
    width: 640px;
    position: relative;
  }

  .base-modal__left-area {
    width: 52px;
  }

  .base-modal__left-area-icon {
    color: $color-yellow;
    margin-left: 15px;
    margin-bottom: 16px;
    width: 20px;
    height: 13px;
  }

  .base-modal__right-area {
    padding:30px;
  }

  .base-modal__modal-header {
    position: relative;
  }

  .base-modal__modal-header-title {
    color: $color-grey;
    font-family: $font-family-cera-regular;
    font-size: 24px;
  }

  .base-modal__close {
    position: absolute;
    top:0;
    right: 0;
    color: #a0abb2;
  }

  .base-modal__modal-description {
    color: $color-grey-light;
    font-size: $font-size-default;
    padding:10px 0px;
    line-height: 1.43;
  }

  .base-modal__modal-body {
    padding-top: 10px;
  }

  .button-disabled {
    background-color: rgba(240, 179, 35, 0.3);
    border-color: rgba(240, 179, 35, 0.1);
    color: rgba(19, 30, 41, 0.3);
  }
}