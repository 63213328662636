.community-confirmation-modal__wrapper {
  padding-top: 10%;
  .base-modal__modal-content {
    width: 630px;
  }
  .base-modal__right-area {
    padding: 25px 18px 30px 30px;
  }
  .base-modal__modal-header-title {
    font-family: $font-family-cera-regular;
    font-size: 24px;
    color: $color-grey;
  }
  .base-modal__close {
    top: -5px;
    right: 10px;
  }
}

.community-confirmation-modal__container {
  margin-top: 50px;
}

.community-confirmation-modal__confirmation-message {
  @include text-typography($font-family-cera-medium, 16px);
  color: $color-black;
  margin-bottom: 85px;
  &--large {
    margin-bottom: 65px;
  }
}

.community-confirmation-modal__actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.community-confirmation-modal__cancel-button {
  font-family: $font-family-cera-bold;
  color: rgba($color-grey, 0.9);
  background-color: unset;
  padding: 0 18px;
  margin: 0;
  max-width: 150px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
}

.community-confirmation-modal__save-button {
  display: flex;
  width: auto;
  margin: 0 12px;
  font-family: $font-family-cera-bold;
  font-size: 14px;
  text-transform: uppercase;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  &--more-padding {
    padding-left: 33px;
    padding-right: 33px;
  }
}

.community-confirmation-modal__delete-button {
  display: flex;
  flex-grow: 1;
  width: auto;
  font-family: $font-family-cera-bold;
  font-size: 14px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  border: none;
  color: $color-red;
  background: none;
}

.community-form .community-confirmation-modal__loader {
    width: 103px;
}
